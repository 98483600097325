import "../../static/css/pageLayout.css";

import axios from "axios";
import { Accordion, AccordionTab } from "primereact/accordion";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { BASE_URL, ICONS, STRING_CONST, dialogFooterContent } from "../../common/globals";
import { FIELD_SIZE, isEmptyString, validateString, validateMinMaxText } from "../../common/validations";
import DialogConfigurationNotProcessed from "../../components/DialogConfigurationNotProcessed";
import DialogDataNotProcessed from "../../components/DialogDataNotProcessed";
import DialogDataProcessed from "../../components/DialogDataProcessed";
import DialogProcessingData from "../../components/DialogProcessingData";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import FormSelectMultiple from "../../components/FormSelectMultiple";
import ProcessFormErrors from "../../components/ProcessFormErrors";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function RegisterWorker() {
  const addWorkerURL = BASE_URL + "worker/add";
  const addWorkerConfigURL = BASE_URL + "worker/config/add";
  const townListURL = BASE_URL + "town/clientlistbycounty";
  const uploadWorkerCertificateURL = BASE_URL + "worker/certificate/upload";

  const [classification, setClassification] = useState([]);
  const [ddOptionsCountry, setDdOptionsCountry] = useState([]);
  const [ddOptionsCounty, setDdOptionsCounty] = useState([]);
  const [ddOptionsTown, setDdOptionsTown] = useState([]);
  const [ddOptionsGender, setDdOptionsGender] = useState([]);
  const [ddOptionsCompany, setDdOptionsCompany] = useState([]);
  const [ddOptionsWorkerTypes, setDdOptionsWorkerTypes] = useState([]);
  const [ddOptionsEquipmentClassifications, setDdOptionsEquipmentClassifications] = useState([]);
  const [ddOptionsEquipmentGroups, setDdOptionsEquipmentGroups] = useState([]);
  const [ddOptionsEquipmentTypes, setDdOptionsEquipmentTypes] = useState([]);
  const [fileCertificate, setFileCertificate] = useState(null);
  const [showConfigNotLoadedDialog, setShowConfigNotLoadedDialog] = useState(null);
  const [showProcessingDialog, setShowProcessingDialog] = useState(null);
  const [showProcessedDialog, setShowProcessedDialog] = useState(null);
  const [showNotProcessedDialog, setShowNotProcessedDialog] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);

  const { auth } = useAuth();

  const [values, setValues] = useState({
    lastName: "",
    firstName: "",
    identifier: "",
    genderUuid: "",
    idCardSerial: "",
    idCardNumber: "",
    idPassport: "",
    idIdentity: "",
    birthDate: "",
    countryUuid: "",
    countyUuid: "",
    townUuid: "",
    phone: "",
    email: "",
    workerTypeUuid: "",
    equipmentClassificationUuid: "",
    equipmentGroupUuid: [],
    equipmentTypeUuid: "",
    certificateNumber: "",
    certificateIssuedDate: "",
    companyUuid: "",
    maxMass: "",
    externalTown: "",
    countyNew: "",
    townNew: "",
  });

  const inputs = [
    {
      id: 0,
      name: "lastName",
      type: "text",
      placeholder: "Nume familie deservent auxiliar",
      label: "Nume familie deservent auxiliar",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 1,
      name: "firstName",
      type: "text",
      placeholder: "Prenume deservent auxiliar",
      label: "Prenume deservent auxiliar",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 2,
      name: "identifier",
      type: "text",
      placeholder: "CNP deservent auxiliar",
      label: "CNP deservent auxiliar",
      mandatory: STRING_CONST.MANDATORY_FIELD_IN_GROUP,
    },
    {
      id: 3,
      name: "genderUuid",
      type: "selectGender",
      placeholder: "Sex deservent auxiliar",
      label: "Sex deservent auxiliar",
    },
    {
      id: 4,
      name: "idCardSerial",
      type: "text",
      placeholder: "Serie CI deservent auxiliar",
      label: "Serie CI deservent auxiliar",
      mandatory: STRING_CONST.MANDATORY_FIELD_IN_GROUP,
    },
    {
      id: 5,
      name: "idCardNumber",
      type: "text",
      placeholder: "Număr CI deservent auxiliar",
      label: "Număr CI deservent auxiliar",
      mandatory: STRING_CONST.MANDATORY_FIELD_IN_GROUP,
    },
    {
      id: 6,
      name: "idPassport",
      type: "text",
      placeholder: "Număr pașaport deservent auxiliar (dacă nu are CI)",
      label: "Număr pașaport deservent auxiliar (dacă nu are CI)",
      mandatory: STRING_CONST.MANDATORY_FIELD_IN_GROUP,
    },
    {
      id: 7,
      name: "idIdentity",
      type: "text",
      placeholder: "Număr act identitate (dacă nu are CI sau pașaport)",
      label: "Număr act identitate (dacă nu are CI sau pașaport)",
      mandatory: STRING_CONST.MANDATORY_FIELD_IN_GROUP,
    },
    {
      id: 8,
      name: "birthDate",
      type: "date",
      label: "Dată naștere deservent auxiliar",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 9,
      name: "countryUuid",
      type: "selectCountry",
      placeholder: "Țară naștere deservent auxiliar (dacă nu este născut în România)",
      label: "Țară naștere deservent auxiliar (dacă nu este născut în România)",
    },
    {
      id: 10,
      name: "countyUuid",
      type: "selectCounty",
      placeholder: "Județ/sector naștere deservent auxiliar",
      label: "Județ/sector naștere deservent auxiliar",
    },
    {
      id: 11,
      name: "townUuid",
      type: "selectTown",
      placeholder: "Localitate naștere deservent auxiliar",
      label: "Localitate naștere deservent auxiliar",
    },
    {
      id: 12,
      name: "phone",
      type: "text",
      placeholder: "Număr telefon mobil deservent auxiliar",
      label: "Număr telefon mobil deservent auxiliar",
    },
    {
      id: 13,
      name: "email",
      type: "text",
      placeholder: "Adresă e-mail deservent auxiliar",
      label: "Adresă e-mail deservent auxiliar",
    },
    {
      id: 14,
      name: "workerTypeUuid",
      type: "selectClass",
      placeholder: "Tip deservent auxiliar",
      label: "Tip deservent auxiliar",
      mandatory: STRING_CONST.OPTIONAL_MANDATORY_ALL_GROUP,
    },
    {
      id: 15,
      name: "equipmentClassificationUuid",
      type: "selectClass",
      placeholder: "Clasificare echipament",
      label: "Clasificare echipament",
      mandatory: STRING_CONST.OPTIONAL_MANDATORY_ALL_GROUP,
    },
    {
      id: 16,
      name: "equipmentGroupUuid",
      type: "selectClass",
      placeholder: "Grupă echipament (dacă este cazul)",
      label: "Grupă echipament (dacă este cazul)",
      mandatory: STRING_CONST.OPTIONAL_MANDATORY_ALL_GROUP,
    },
    {
      id: 17,
      name: "equipmentTypeUuid",
      type: "selectClass",
      placeholder: "Tip echipament",
      label: "Tip echipament",
      mandatory: STRING_CONST.OPTIONAL_MANDATORY_ALL_GROUP,
    },
    {
      id: 18,
      name: "certificateNumber",
      type: "text",
      placeholder: "Număr adeverință",
      label: "Număr adeverință",
      mandatory: STRING_CONST.OPTIONAL_MANDATORY_ALL_GROUP,
    },
    {
      id: 19,
      name: "certificateIssuedDate",
      type: "date",
      placeholder: "Dată eliberare adeverință",
      label: "Dată eliberare adeverință",
      mandatory: STRING_CONST.OPTIONAL_MANDATORY_ALL_GROUP,
    },
    {
      id: 20,
      name: "companyUuid",
      type: "selectClass",
      placeholder: "Cod punct de lucru",
      label: "Cod punct de lucru",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 21,
      name: "maxMass",
      type: "text",
      placeholder: "Sarcină maximă",
      label: "Sarcină maximă",
      mandatory: STRING_CONST.OPTIONAL_MANDATORY_ALL_GROUP,
    },
    {
      id: 22,
      name: "externalTown",
      type: "text",
      placeholder: "Localitate naştere (în afara țării)",
      label: "Localitate naştere (în afara țării)",
    },
    {
      id: 23,
      name: "countyNew",
      type: "text",
      placeholder: "Județ/sector nume vechi",
      label: "Județ/sector nume vechi",
    },
    {
      id: 24,
      name: "townNew",
      type: "text",
      placeholder: "Localitate nume vechi",
      label: "Localitate nume vechi",
    },
  ];

  const FIELD = {
    lastName: 0,
    firstName: 1,
    identifier: 2,
    genderUuid: 3,
    idCardSerial: 4,
    idCardNumber: 5,
    idPassport: 6,
    idIdentity: 7,
    birthDate: 8,
    countryUuid: 9,
    countyUuid: 10,
    townUuid: 11,
    phone: 12,
    email: 13,
    workerTypeUuid: 14,
    equipmentClassificationUuid: 15,
    equipmentGroupUuid: 16,
    equipmentTypeUuid: 17,
    certificateNumber: 18,
    certificateIssuedDate: 19,
    companyUuid: 20,
    maxMass: 21,
    externalTown: 22,
    countyNew: 23,
    townNew: 24,
  };

  // load config
  useEffect(() => {
    axios
      .post(
        addWorkerConfigURL,
        {},
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        if (null !== response && null !== response.data) {
          setDdOptionsCompany(response.data.branches);
          setDdOptionsCountry(response.data.countries);
          setDdOptionsCounty(response.data.counties);
          setDdOptionsGender(response.data.genders);
          setClassification(response.data.classification);
          setValues({ ...values, countryUuid: "9398f013-d0c8-11ed-b3c8-902b346c1788" });
          for (const listItem of response.data.classification) {
            if ("0#0" === listItem.parentUuid) {
              setDdOptionsWorkerTypes(listItem.values);
              break;
            }
          }
        } else {
          setShowConfigNotLoadedDialog(true);
        }
      })
      .catch((error) => {
        setShowConfigNotLoadedDialog(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    e.preventDefault();
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onChangeCounty = async (e) => {
    e.preventDefault();
    setValues({ ...values, [e.target.name]: e.target.value, townUuid: "", countyNew: "" });
    setDdOptionsTown([]);
    // load towns when county is changed
    axios
      .post(
        townListURL,
        {
          uuid: e.target.value,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        if (null !== response && null !== response.data) {
          setDdOptionsTown(response.data);
        } else {
          setShowConfigNotLoadedDialog(true);
        }
      })
      .catch((error) => {
        setShowConfigNotLoadedDialog(true);
      });
  };

  const onChangeTown = (e) => {
    e.preventDefault();
    setValues({ ...values, [e.target.name]: e.target.value, townNew: "" });
  };

  const onChangeCountyNew = (e) => {
    e.preventDefault();
    setValues({ ...values, [e.target.name]: e.target.value, countyUuid: "" });
    setDdOptionsTown([]);
  };

  const onChangeTownNew = (e) => {
    e.preventDefault();
    setValues({ ...values, [e.target.name]: e.target.value, townUuid: "" });
  };

  const onChangeWorkerType = (e) => {
    e.preventDefault();
    setValues({ ...values, [e.target.name]: e.target.value, equipmentClassificationUuid: "", equipmentGroupUuid: [], equipmentTypeUuid: "" });
    setDdOptionsEquipmentClassifications([]);
    setDdOptionsEquipmentGroups([]);
    setDdOptionsEquipmentTypes([]);
    for (const listItem of classification) {
      if (e.target.value === listItem.parentUuid) {
        setDdOptionsEquipmentClassifications(listItem.values);
        break;
      }
    }
  };

  const onChangeEquipmentClassification = (e) => {
    e.preventDefault();
    setValues({ ...values, [e.target.name]: e.target.value, equipmentGroupUuid: [], equipmentTypeUuid: "" });
    setDdOptionsEquipmentGroups([]);
    setDdOptionsEquipmentTypes([]);
    for (const listItem of classification) {
      if (e.target.value === listItem.parentUuid) {
        if ("451617f2-57e1-11ee-b39a-902b346c1788" === listItem.typeUuid) {
          // if item type is group
          setDdOptionsEquipmentGroups(listItem.values);
          break;
        } else {
          setDdOptionsEquipmentTypes(listItem.values);
          break;
        }
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowProcessingDialog(true);
    if (!validateForm()) {
      setShowProcessingDialog(false);
      return;
    }
    axios
      .post(
        addWorkerURL,
        {
          companyUuid: values.companyUuid,
          lastName: values.lastName,
          firstName: values.firstName,
          identifier: values.identifier,
          genderUuid: values.genderUuid,
          idCardSerial: values.idCardSerial,
          idCardNumber: values.idCardNumber,
          idPassport: values.idPassport,
          idIdentity: values.idIdentity,
          birthDate: values.birthDate,
          countyUuid: values.countyUuid,
          townUuid: values.townUuid,
          countryUuid: values.countryUuid,
          externalTown: values.externalTown,
          phone: values.phone,
          email: values.email,
          workerTypeUuid: values.workerTypeUuid,
          equipmentClassificationUuid: values.equipmentClassificationUuid,
          equipmentGroupUuid: values.equipmentGroupUuid,
          equipmentTypeUuid: values.equipmentTypeUuid,
          certificateNumber: values.certificateNumber,
          certificateIssuedDate: values.certificateIssuedDate,
          maxMass: values.maxMass,
          countyNew: values.countyNew,
          townNew: values.townNew,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        if (null !== response && null !== response.data) {
          uploadCertificate(response.data);
          let file = document.querySelector(".fileC");
          if (file !== null) {
            file.value = "";
          }
        } else {
          setShowProcessingDialog(false);
          setShowNotProcessedDialog(true);
        }
      })
      .catch((error) => {
        setShowProcessingDialog(false);
        setShowNotProcessedDialog(true);
      });
  };

  const uploadCertificate = (workerCertificateUuid) => {
    if (fileCertificate === null) {
      setShowProcessingDialog(false);
      setShowProcessedDialog(true);
      return;
    }
    const formData = new FormData();
    formData.append("workerCertificateUuid", workerCertificateUuid);
    formData.append("fileCertificate", fileCertificate);
    axios
      .post(uploadWorkerCertificateURL, formData, {
        "content-type": "multipart/form-data",
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        setShowProcessingDialog(false);
        if (null !== response) {
          setShowProcessedDialog(true);
        } else {
          setShowNotProcessedDialog(true);
        }
      })
      .catch((error) => {
        setShowProcessingDialog(false);
        setShowNotProcessedDialog(true);
      });
  };

  const validateForm = () => {
    let result = true;
    let data = [];
    if (isEmptyString(values.lastName)) {
      data.push({ id: inputs[FIELD.lastName].id, value: inputs[FIELD.lastName].label + STRING_CONST.VALIDATION_IS_MANDATORY });
      result = false;
    } else if (!validateString(values.lastName, FIELD_SIZE.LastNameMin, FIELD_SIZE.LastNameMax)) {
      data.push({
        id: inputs[FIELD.lastName].id,
        value: inputs[FIELD.lastName].label + validateMinMaxText(FIELD_SIZE.LastNameMin, FIELD_SIZE.LastNameMax),
      });
      result = false;
    }
    if (isEmptyString(values.firstName)) {
      data.push({ id: inputs[FIELD.firstName].id, value: inputs[FIELD.firstName].label + STRING_CONST.VALIDATION_IS_MANDATORY });
      result = false;
    } else if (!validateString(values.firstName, FIELD_SIZE.FirstNameMin, FIELD_SIZE.FirstNameMax)) {
      data.push({
        id: inputs[FIELD.firstName].id,
        value: inputs[FIELD.firstName].label + validateMinMaxText(FIELD_SIZE.FirstNameMin, FIELD_SIZE.FirstNameMax),
      });
      result = false;
    }
    if (isEmptyString(values.companyUuid)) {
      data.push({ id: inputs[FIELD.companyUuid].id, value: inputs[FIELD.companyUuid].label + STRING_CONST.VALIDATION_IS_MANDATORY });
      result = false;
    }
    if (
      isEmptyString(values.identifier) &&
      isEmptyString(values.idCardSerial) &&
      isEmptyString(values.idCardNumber) &&
      isEmptyString(values.idPassport) &&
      isEmptyString(values.idIdentity)
    ) {
      data.push({
        id: inputs[FIELD.identifier].id,
        value:
          "Minim unul din urmatoarele acte este obligatoriu: " +
          inputs[FIELD.identifier].label +
          ", (" +
          inputs[FIELD.idCardSerial].label +
          ", " +
          inputs[FIELD.idCardNumber].label +
          "), " +
          inputs[FIELD.idPassport].label +
          ", " +
          inputs[FIELD.idIdentity].label,
      });
      result = false;
    }
    if (!validateString(values.identifier, FIELD_SIZE.Zero, FIELD_SIZE.IdentifierMax)) {
      data.push({
        id: inputs[FIELD.identifier].id,
        value: inputs[FIELD.identifier].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.IdentifierMax),
      });
      result = false;
    }
    if (!validateString(values.idCardSerial, FIELD_SIZE.Zero, FIELD_SIZE.IdCardSerialMax)) {
      data.push({
        id: inputs[FIELD.idCardSerial].id,
        value: inputs[FIELD.idCardSerial].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.IdCardSerialMax),
      });
      result = false;
    }
    if (!validateString(values.idCardNumber, FIELD_SIZE.Zero, FIELD_SIZE.IdCardNumberMax)) {
      data.push({
        id: inputs[FIELD.idCardNumber].id,
        value: inputs[FIELD.idCardNumber].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.IdCardNumberMax),
      });
      result = false;
    }
    if (!validateString(values.idPassport, FIELD_SIZE.Zero, FIELD_SIZE.IdPassportMax)) {
      data.push({
        id: inputs[FIELD.idPassport].id,
        value: inputs[FIELD.idPassport].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.IdPassportMax),
      });
      result = false;
    }
    if (!validateString(values.idIdentity, FIELD_SIZE.Zero, FIELD_SIZE.IdIdentityMax)) {
      data.push({
        id: inputs[FIELD.idIdentity].id,
        value: inputs[FIELD.idIdentity].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.IdIdentityMax),
      });
      result = false;
    }
    if (isEmptyString(values.birthDate)) {
      data.push({ id: inputs[FIELD.birthDate].id, value: inputs[FIELD.birthDate].label + STRING_CONST.VALIDATION_IS_MANDATORY });
      result = false;
    }
    if (!isEmptyString(values.countryUuid) && "9398f013-d0c8-11ed-b3c8-902b346c1788" === values.countryUuid) {
      if (isEmptyString(values.countyUuid) && isEmptyString(values.countyNew)) {
        data.push({ id: inputs[FIELD.countyUuid].id, value: "Județ/sector naștere este obligatoriu!" });
        result = false;
      }
      if (!validateString(values.countyNew, FIELD_SIZE.Zero, FIELD_SIZE.CountyMax)) {
        data.push({
          id: inputs[FIELD.countyNew].id,
          value: inputs[FIELD.countyNew].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.CountyMax),
        });
        result = false;
      }
      if (isEmptyString(values.townUuid) && isEmptyString(values.townNew)) {
        data.push({ id: inputs[FIELD.townUuid].id, value: "Localitate naștere este obligatoriu!" });
        result = false;
      }
      if (!validateString(values.townNew, FIELD_SIZE.Zero, FIELD_SIZE.TownMax)) {
        data.push({ id: inputs[FIELD.townNew].id, value: inputs[FIELD.townNew].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.TownMax) });
        result = false;
      }
    }
    if (!isEmptyString(values.countryUuid) && "9398f013-d0c8-11ed-b3c8-902b346c1788" !== values.countryUuid) {
      if (isEmptyString(values.externalTown)) {
        data.push({ id: inputs[FIELD.externalTown].id, value: inputs[FIELD.externalTown].label + STRING_CONST.VALIDATION_IS_MANDATORY });
        result = false;
      }
      if (!validateString(values.externalTown, FIELD_SIZE.Zero, FIELD_SIZE.ExternalTownMax)) {
        data.push({
          id: inputs[FIELD.externalTown].id,
          value: inputs[FIELD.externalTown].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.ExternalTownMax),
        });
        result = false;
      }
    }
    if (!validateString(values.phone, FIELD_SIZE.Zero, FIELD_SIZE.PhoneMax)) {
      data.push({ id: inputs[FIELD.phone].id, value: inputs[FIELD.phone].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.PhoneMax) });
      result = false;
    }
    if (!validateString(values.email, FIELD_SIZE.Zero, FIELD_SIZE.EmailMax)) {
      data.push({ id: inputs[FIELD.email].id, value: inputs[FIELD.email].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.EmailMax) });
      result = false;
    }
    if (
      !isEmptyString(values.workerTypeUuid) ||
      !isEmptyString(values.equipmentClassificationUuid) ||
      !isEmptyString(values.equipmentGroupUuid) ||
      !isEmptyString(values.equipmentTypeUuid) ||
      !isEmptyString(values.certificateNumber) ||
      !isEmptyString(values.certificateIssuedDate)
    ) {
      if (isEmptyString(values.workerTypeUuid)) {
        data.push({
          id: inputs[FIELD.workerTypeUuid].id,
          value: inputs[FIELD.workerTypeUuid].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_WORKER_CERTIFICATE,
        });
        result = false;
      }
      if (isEmptyString(values.equipmentClassificationUuid)) {
        data.push({
          id: inputs[FIELD.equipmentClassificationUuid].id,
          value: inputs[FIELD.equipmentClassificationUuid].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_WORKER_CERTIFICATE,
        });
        result = false;
      }
      if (isEmptyString(values.equipmentGroupUuid) && null !== ddOptionsEquipmentGroups && ddOptionsEquipmentGroups.length > 0) {
        data.push({
          id: inputs[FIELD.equipmentGroupUuid].id,
          value: inputs[FIELD.equipmentGroupUuid].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_WORKER_CERTIFICATE,
        });
        result = false;
      }
      if (isEmptyString(values.equipmentTypeUuid) && null !== ddOptionsEquipmentTypes && ddOptionsEquipmentTypes.length > 0) {
        data.push({
          id: inputs[FIELD.equipmentTypeUuid].id,
          value: inputs[FIELD.equipmentTypeUuid].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_WORKER_CERTIFICATE,
        });
        result = false;
      }
      if (isEmptyString(values.certificateNumber)) {
        data.push({
          id: inputs[FIELD.certificateNumber].id,
          value: inputs[FIELD.certificateNumber].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_WORKER_CERTIFICATE,
        });
        result = false;
      }
      if (isEmptyString(values.certificateIssuedDate)) {
        data.push({
          id: inputs[FIELD.certificateIssuedDate].id,
          value: inputs[FIELD.certificateIssuedDate].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_WORKER_CERTIFICATE,
        });
        result = false;
      }
    }
    if (!validateString(values.certificateNumber, FIELD_SIZE.Zero, FIELD_SIZE.WorkerCertificateSeriesMax + FIELD_SIZE.WorkerCertificateNumberMax)) {
      data.push({
        id: inputs[FIELD.certificateNumber].id,
        value:
          inputs[FIELD.certificateNumber].label +
          validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.WorkerCertificateSeriesMax + FIELD_SIZE.WorkerCertificateNumberMax),
      });
      result = false;
    }
    setValidationErrors(data);
    return result;
  };

  return (
    <div className="clientArea">
      <Sidebar />
      <button class="buttonClassGreen">Adaugă deservent auxiliar</button>
      <div class="spaceDiv" />
      <Link to={"/worker/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.WorkerList)} alt="Listă deservenți auxiliari" title="Listă deservenți auxiliari" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        src={require("../../static/images/buttons/" + ICONS.WorkerAddDisabled)}
        alt="Adaugă deservent auxiliar"
        title="Adaugă deservent auxiliar"
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/worker/import"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.WorkerImport)}
          alt="Importă deservenți auxiliari"
          title="Importă deservenți auxiliari"
        />
      </Link>
      <form class="formArea">
        <div class="rowdouble">
          <Accordion multiple activeIndex={[0]}>
            <AccordionTab header="Detalii înregistrare date personale deservent auxiliar">
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.lastName].id}>
                  <FormInput
                    key={inputs[FIELD.lastName].id}
                    {...inputs[FIELD.lastName]}
                    value={values[inputs[FIELD.lastName].name]}
                    onChange={onChange}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.firstName].id}>
                  <FormInput
                    key={inputs[FIELD.firstName].id}
                    {...inputs[FIELD.firstName]}
                    value={values[inputs[FIELD.firstName].name]}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.companyUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.companyUuid].id}
                    name={inputs[FIELD.companyUuid].name}
                    label={inputs[FIELD.companyUuid].label}
                    value={values[inputs[FIELD.companyUuid].name]}
                    mandatory={inputs[FIELD.companyUuid].mandatory}
                    ddOptions={ddOptionsCompany}
                    onChange={onChange}
                  />
                </div>
                <div class="column">
                  <p>&nbsp;</p>
                </div>
              </div>

              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.identifier].id}>
                  <FormInput
                    key={inputs[FIELD.identifier].id}
                    {...inputs[FIELD.identifier]}
                    value={values[inputs[FIELD.identifier].name]}
                    onChange={onChange}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.genderUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.genderUuid].id}
                    name={inputs[FIELD.genderUuid].name}
                    label={inputs[FIELD.genderUuid].label}
                    value={values[inputs[FIELD.genderUuid].name]}
                    ddOptions={ddOptionsGender}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.idCardSerial].id}>
                  <FormInput
                    key={inputs[FIELD.idCardSerial].id}
                    {...inputs[FIELD.idCardSerial]}
                    value={values[inputs[FIELD.idCardSerial].name]}
                    onChange={onChange}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.idCardNumber].id}>
                  <FormInput
                    key={inputs[FIELD.idCardNumber].id}
                    {...inputs[FIELD.idCardNumber]}
                    value={values[inputs[FIELD.idCardNumber].name]}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.idPassport].id}>
                  <FormInput
                    key={inputs[FIELD.idPassport].id}
                    {...inputs[FIELD.idPassport]}
                    value={values[inputs[FIELD.idPassport].name]}
                    onChange={onChange}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.idIdentity].id}>
                  <FormInput
                    key={inputs[FIELD.idIdentity].id}
                    {...inputs[FIELD.idIdentity]}
                    value={values[inputs[FIELD.idIdentity].name]}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.birthDate].id}>
                  <FormInput
                    key={inputs[FIELD.birthDate].id}
                    {...inputs[FIELD.birthDate]}
                    value={values[inputs[FIELD.birthDate].name]}
                    onChange={onChange}
                  />
                </div>
                <div class="column">
                  <p>&nbsp;</p>
                </div>
              </div>

              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.countyUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.countyUuid].id}
                    name={inputs[FIELD.countyUuid].name}
                    label={inputs[FIELD.countyUuid].label}
                    value={values[inputs[FIELD.countyUuid].name]}
                    ddOptions={ddOptionsCounty}
                    onChange={onChangeCounty}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.townUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.townUuid].id}
                    name={inputs[FIELD.townUuid].name}
                    label={inputs[FIELD.townUuid].label}
                    value={values[inputs[FIELD.townUuid].name]}
                    ddOptions={ddOptionsTown}
                    onChange={onChangeTown}
                  />
                </div>
              </div>

              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.countyNew].id}>
                  <FormInput
                    key={inputs[FIELD.countyNew].id}
                    {...inputs[FIELD.countyNew]}
                    value={values[inputs[FIELD.countyNew].name]}
                    onChange={onChangeCountyNew}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.townNew].id}>
                  <FormInput
                    key={inputs[FIELD.townNew].id}
                    {...inputs[FIELD.townNew]}
                    value={values[inputs[FIELD.townNew].name]}
                    onChange={onChangeTownNew}
                  />
                </div>
              </div>

              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.countryUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.countryUuid].id}
                    name={inputs[FIELD.countryUuid].name}
                    label={inputs[FIELD.countryUuid].label}
                    value={values[inputs[FIELD.countryUuid].name]}
                    ddOptions={ddOptionsCountry}
                    onChange={onChange}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.externalTown].id}>
                  <FormInput
                    key={inputs[FIELD.externalTown].id}
                    {...inputs[FIELD.externalTown]}
                    value={values[inputs[FIELD.externalTown].name]}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.phone].id}>
                  <FormInput key={inputs[FIELD.phone].id} {...inputs[FIELD.phone]} value={values[inputs[FIELD.phone].name]} onChange={onChange} />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.email].id}>
                  <FormInput key={inputs[FIELD.email].id} {...inputs[FIELD.email]} value={values[inputs[FIELD.email].name]} onChange={onChange} />
                </div>
              </div>
            </AccordionTab>
            <AccordionTab header="Detalii tip autorizare deservent auxiliar">
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.workerTypeUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.workerTypeUuid].id}
                    name={inputs[FIELD.workerTypeUuid].name}
                    label={inputs[FIELD.workerTypeUuid].label}
                    value={values[inputs[FIELD.workerTypeUuid].name]}
                    mandatory={inputs[FIELD.workerTypeUuid].mandatory}
                    ddOptions={ddOptionsWorkerTypes}
                    onChange={onChangeWorkerType}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.equipmentClassificationUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.equipmentClassificationUuid].id}
                    name={inputs[FIELD.equipmentClassificationUuid].name}
                    label={inputs[FIELD.equipmentClassificationUuid].label}
                    value={values[inputs[FIELD.equipmentClassificationUuid].name]}
                    mandatory={inputs[FIELD.equipmentClassificationUuid].mandatory}
                    ddOptions={ddOptionsEquipmentClassifications}
                    onChange={onChangeEquipmentClassification}
                  />
                </div>
              </div>

              {values.workerTypeUuid === "044d903c-6abe-11ef-bfb9-74563c1b28c3#28b087a4-5a10-11ee-8699-902b346c1788" ||
              values.equipmentClassificationUuid === "08e34c61-6ac3-11ef-bfb9-74563c1b28c3#ae063ef5-5a11-11ee-8699-902b346c1788" ? (
                <div class="row">
                  <div class="column" id={"internalLink" + inputs[FIELD.equipmentGroupUuid].id}>
                    {values.workerTypeUuid === "044d903c-6abe-11ef-bfb9-74563c1b28c3#28b087a4-5a10-11ee-8699-902b346c1788" ? (
                      <FormSelectMultiple
                        key={inputs[FIELD.equipmentGroupUuid].id}
                        name={inputs[FIELD.equipmentGroupUuid].name}
                        label={inputs[FIELD.equipmentGroupUuid].label}
                        value={values[inputs[FIELD.equipmentGroupUuid].name]}
                        mandatory={inputs[FIELD.equipmentGroupUuid].mandatory}
                        ddOptions={ddOptionsEquipmentGroups}
                        onChange={onChange}
                      />
                    ) : (
                      <p>&nbsp;</p>
                    )}
                  </div>
                  <div class="column" id={"internalLink" + inputs[FIELD.equipmentTypeUuid].id}>
                    {values.equipmentClassificationUuid === "08e34c61-6ac3-11ef-bfb9-74563c1b28c3#ae063ef5-5a11-11ee-8699-902b346c1788" ? (
                      <FormSelect
                        key={inputs[FIELD.equipmentTypeUuid].id}
                        name={inputs[FIELD.equipmentTypeUuid].name}
                        label={inputs[FIELD.equipmentTypeUuid].label}
                        value={values[inputs[FIELD.equipmentTypeUuid].name]}
                        mandatory={inputs[FIELD.equipmentTypeUuid].mandatory}
                        ddOptions={ddOptionsEquipmentTypes}
                        onChange={onChange}
                      />
                    ) : (
                      <p>&nbsp;</p>
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.maxMass].id}>
                  <FormInput
                    key={inputs[FIELD.maxMass].id}
                    {...inputs[FIELD.maxMass]}
                    value={values[inputs[FIELD.maxMass].name]}
                    onChange={onChange}
                  />
                </div>
                <div class="column">
                  <p>&nbsp;</p>
                </div>
              </div>

              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.certificateNumber].id}>
                  <FormInput
                    key={inputs[FIELD.certificateNumber].id}
                    {...inputs[FIELD.certificateNumber]}
                    value={values[inputs[FIELD.certificateNumber].name]}
                    onChange={onChange}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.certificateIssuedDate].id}>
                  <FormInput
                    key={inputs[FIELD.certificateIssuedDate].id}
                    {...inputs[FIELD.certificateIssuedDate]}
                    value={values[inputs[FIELD.certificateIssuedDate].name]}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div class="row">
                <div class="column">
                  Adaugă poză/pdf adeverinţă deservent auxiliar:{" "}
                  <input type="file" class="fileC" onChange={(e) => setFileCertificate(e.target.files[0])} />
                </div>
                <div class="column">&nbsp;</div>
              </div>
            </AccordionTab>
          </Accordion>
        </div>
        {validationErrors.length > 0 ? <ProcessFormErrors validationErrors={validationErrors} /> : null}
        {showProcessingDialog !== true ? (
          <div class="rowdoublecentered">
            <div class="columnbutton">
              <img
                class="imgtoolbar"
                src={require("../../static/images/buttons/" + ICONS.WorkerSave)}
                alt="Salvează deservent auxiliar"
                title="Salvează deservent auxiliar"
                onClick={handleSubmit}
              />
            </div>
          </div>
        ) : null}
      </form>
      <div class="spaceDiv" />
      <Link to={"/worker/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.WorkerList)} alt="Listă deservenți auxiliari" title="Listă deservenți auxiliari" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        src={require("../../static/images/buttons/" + ICONS.WorkerAddDisabled)}
        alt="Adaugă deservent auxiliar"
        title="Adaugă deservent auxiliar"
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/worker/import"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.WorkerImport)}
          alt="Importă deservenți auxiliari"
          title="Importă deservenți auxiliari"
        />
      </Link>
      <div class="spaceDiv" />
      <DialogProcessingData isVisible={showProcessingDialog} />
      <DialogDataProcessed
        isVisible={showProcessedDialog}
        footer={dialogFooterContent("OK", () => setShowProcessedDialog(false))}
        onHide={() => setShowProcessedDialog(false)}
      />
      <DialogDataNotProcessed
        isVisible={showNotProcessedDialog}
        footer={dialogFooterContent("OK", () => setShowNotProcessedDialog(false))}
        onHide={() => setShowNotProcessedDialog(false)}
      />
      <DialogConfigurationNotProcessed
        isVisible={showConfigNotLoadedDialog}
        footer={dialogFooterContent("OK", () => setShowConfigNotLoadedDialog(false))}
        onHide={() => setShowConfigNotLoadedDialog(false)}
      />
    </div>
  );
}
