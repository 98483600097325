import axios from "axios";
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { BASE_URL, ICONS, dialogHeaderElement, dialogFooterContent, dialogFooterContentTwoButtons } from "../../common/globals";
import DialogConfigurationNotProcessed from "../../components/DialogConfigurationNotProcessed";
import DialogDataProcessed from "../../components/DialogDataProcessed";
import DialogDataNotProcessed from "../../components/DialogDataNotProcessed";
import DialogListNotLoaded from "../../components/DialogListNotLoaded";
import DialogProcessingData from "../../components/DialogProcessingData";
import DialogProcessingDocuments from "../../components/DialogProcessingDocuments";
import FormSelect from "../../components/FormSelect";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function ListWorkers() {
  const listWorkerURL = BASE_URL + "worker/list";
  const deleteURL = BASE_URL + "worker/delete";
  const deactivateURL = BASE_URL + "worker/inactivate";
  const companyListURL = BASE_URL + "company/listsimplecompanies";
  const downloadIscirWorkersURL = BASE_URL + "worker/list/iscir/selected";
  const downloadDocumentURL = BASE_URL + "worker/certificate/download";
  const downloadWorkersExcelURL = BASE_URL + "workerdocuments/export/excel";

  const mainCompanySessionUuid = sessionStorage.getItem("mainCompanySessionUuid");

  const { auth } = useAuth();

  const navigate = useNavigate();

  const [list, setList] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRows, setSelectedRows] = useState(null);
  const [ddOptionsCompany, setDdOptionsCompany] = useState([]);
  const [showViewRowDialog, setShowViewRowDialog] = useState(null);
  const [showDeleteRowDialog, setShowDeleteRowDialog] = useState(null);
  const [showSelectedRowsWarningDialog, setShowSelectedRowsWarningDialog] = useState(false);
  const [showConfigNotLoadedDialog, setShowConfigNotLoadedDialog] = useState(null);
  const [showListNotLoadedDialog, setShowListNotLoadedDialog] = useState(null);
  const [showProcessingDialog, setShowProcessingDialog] = useState(null);
  const [showProcessedDialog, setShowProcessedDialog] = useState(null);
  const [showNotProcessedDialog, setShowNotProcessedDialog] = useState(null);
  const [showProcessingDocumentsDialog, setShowProcessingDocumentsDialog] = useState(null);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    fullName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    identifier: { value: null, matchMode: FilterMatchMode.CONTAINS },
    companyCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
    workerType: { value: null, matchMode: FilterMatchMode.CONTAINS },
    certificateNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [values, setValues] = useState({
    companyUuid: "",
  });

  const inputs = [
    {
      id: 0,
      name: "companyUuid",
      type: "selectClass",
      placeholder: "Companie",
      label: "Companie",
    },
  ];

  // load companies
  useEffect(() => {
    axios
      .get(companyListURL, {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        if (null !== response && null !== response.data) {
          setDdOptionsCompany(response.data);
          if (null !== mainCompanySessionUuid) {
            setValues({ ...values, companyUuid: mainCompanySessionUuid });
            loadWorkers(mainCompanySessionUuid);
          }
        } else {
          setShowConfigNotLoadedDialog(true);
        }
      })
      .catch((error) => {
        setShowConfigNotLoadedDialog(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    e.preventDefault();
    setValues({ ...values, [e.target.name]: e.target.value });
    setSelectedRows([]);
    loadWorkers(e.target.value);
  };

  // load workers
  const loadWorkers = (uuid) => {
    axios
      .post(
        listWorkerURL,
        {
          uuid: uuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        if (null !== response && null !== response.data) {
          setList(response.data);
        } else {
          setShowListNotLoadedDialog(true);
        }
      })
      .catch((error) => {
        setShowListNotLoadedDialog(true);
      });
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <table>
          <tr>
            <td>
              <img
                class="imgtoolbar"
                src={require("../../static/images/buttons/" + ICONS.Info)}
                alt="Detalii"
                title="Detalii"
                onClick={() => viewRow(rowData)}
              />
            </td>
            <td>&nbsp;&nbsp;</td>
            <td>
              <img
                class="imgtoolbar"
                src={require("../../static/images/buttons/" + ICONS.Delete)}
                alt="Şterge"
                title="Şterge"
                onClick={() => deleteRow(rowData)}
              />
            </td>
          </tr>
        </table>
      </React.Fragment>
    );
  };

  const viewRow = (rowData) => {
    setSelectedRow({ ...rowData });
    setShowViewRowDialog(true);
  };

  const deleteRow = (rowData) => {
    setSelectedRow({ ...rowData });
    setShowDeleteRowDialog(true);
  };

  const deleteListItem = async (e) => {
    e.preventDefault();
    setShowDeleteRowDialog(false);
    setShowProcessingDialog(true);
    axios
      .post(
        deleteURL,
        {
          uuid: selectedRow.uuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        if (null !== response) {
          const newList = list.filter(function (rowData) {
            return rowData.uuid !== selectedRow.uuid;
          });
          setList(newList);
          setShowProcessingDialog(false);
          setShowProcessedDialog(true);
        } else {
          setShowProcessingDialog(false);
          setShowNotProcessedDialog(true);
        }
      })
      .catch((error) => {
        setShowProcessingDialog(false);
        setShowNotProcessedDialog(true);
      });
  };

  const inactivate = async (e) => {
    e.preventDefault();
    if (selectedRows === null || selectedRows.length === 0) {
      setShowSelectedRowsWarningDialog(true);
      return;
    }
    setShowProcessingDialog(true);
    let uuids = selectedRows.map((row) => {
      return row.uuid;
    });
    axios
      .post(
        deactivateURL,
        {
          uuids: uuids,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setShowProcessingDialog(false);
        if (null !== response) {
          navigate("/worker/listonhold");
        } else {
          setShowNotProcessedDialog(true);
        }
      })
      .catch((error) => {
        setShowProcessingDialog(false);
        setShowNotProcessedDialog(true);
      });
  };

  // start worker certification workflow
  const startGenerateDocs = (e) => {
    e.preventDefault();
    if (selectedRows === null || selectedRows.length === 0) {
      setShowSelectedRowsWarningDialog(true);
      return;
    }
    let uuids = selectedRows.map((row) => {
      return row.uuid;
    });
    sessionStorage.setItem("workerUuids", JSON.stringify(uuids));
    navigate("/worker/generatedocstypeselection");
  };

  const handleIscirWorkers = async (e) => {
    e.preventDefault();
    if (selectedRows === null || selectedRows.length === 0) {
      setShowSelectedRowsWarningDialog(true);
      return;
    }
    setShowProcessingDocumentsDialog(true);
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "Listă evidenţă personal ISCIR - deservenţi auxiliari.docx";
    let uuids = selectedRows.map((row) => {
      return row.uuid;
    });
    axios
      .post(
        downloadIscirWorkersURL,
        {
          uuids: uuids,
        },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setShowProcessingDocumentsDialog(false);
        if (null !== response && null !== response.data) {
          link.href = URL.createObjectURL(
            new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" })
          );
          link.click();
        } else {
          setShowNotProcessedDialog(true);
        }
      })
      .catch((error) => {
        setShowProcessingDocumentsDialog(false);
        setShowNotProcessedDialog(true);
      });
  };

  const handleGetDocument = async (certificateDocumentUuid, certificateDocumentFileName, e) => {
    e.preventDefault();
    setShowProcessingDocumentsDialog(true);
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = certificateDocumentFileName;
    axios
      .post(
        downloadDocumentURL,
        {
          uuid: certificateDocumentUuid,
        },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setShowProcessingDocumentsDialog(false);
        if (null !== response && null !== response.data) {
          link.href = URL.createObjectURL(new Blob([response.data], {}));
          link.click();
        } else {
          setShowNotProcessedDialog(true);
        }
      })
      .catch((error) => {
        setShowProcessingDocumentsDialog(false);
        setShowNotProcessedDialog(true);
      });
  };

  const handleExcelExport = async (e) => {
    e.preventDefault();
    if (selectedRows === null || selectedRows.length === 0) {
      setShowSelectedRowsWarningDialog(true);
      return;
    }
    setShowProcessingDocumentsDialog(true);
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "Listă deservenţi auxiliari.xlsx";
    let uuids = selectedRows.map((row) => {
      return row.uuid;
    });
    axios
      .post(
        downloadWorkersExcelURL,
        {
          uuids: uuids,
        },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setShowProcessingDocumentsDialog(false);
        if (null !== response && null !== response.data) {
          link.href = URL.createObjectURL(new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }));
          link.click();
        } else {
          setShowNotProcessedDialog(true);
        }
      })
      .catch((error) => {
        setShowProcessingDocumentsDialog(false);
        setShowNotProcessedDialog(true);
      });
  };

  const goToEditWorker = (e) => {
    e.preventDefault();
    sessionStorage.setItem("editWorkerUuid", selectedRow.uuid);
    navigate("/worker/edit");
  };

  return (
    <div className="clientArea">
      <Sidebar />
      <button class="buttonClassGreen">Listă deservenți auxiliari</button>
      <div class="spaceDiv" />
      <img
        src={require("../../static/images/buttons/" + ICONS.WorkerListDisabled)}
        alt="Listă deservenți auxiliari"
        title="Listă deservenți auxiliari"
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/worker/register"}>
        <img src={require("../../static/images/buttons/" + ICONS.WorkerAdd)} alt="Adaugă deservent auxiliar" title="Adaugă deservent auxiliar" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/worker/import"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.WorkerImport)}
          alt="Importă deservenți auxiliari"
          title="Importă deservenți auxiliari"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.WorkerExport)}
        alt="Exportă deservenți auxiliari"
        title="Exportă deservenți auxiliari"
        onClick={handleExcelExport}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.WorkerRegistration)}
        alt="Listă evidenţă personal ISCIR - deservenţi auxiliari"
        title="Listă evidenţă personal ISCIR - deservenţi auxiliari"
        onClick={handleIscirWorkers}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.WorkerGenerateDocuments)}
        alt="Documente autorizare deservenți auxiliari"
        title="Documente autorizare deservenți auxiliari"
        onClick={startGenerateDocs}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.WorkerInactivate)}
        alt="Dezactivează deservenți auxiliari"
        title="Dezactivează deservenți auxiliari"
        onClick={inactivate}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/worker/listonhold"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.WorkerOnHoldList)}
          alt="Listă deservenți auxiliari inactivi"
          title="Listă deservenți auxiliari inactivi"
        />
      </Link>
      <div class="spaceDiv" />
      <div class="cardDropDown">
        <div class="column">
          <FormSelect
            key={inputs[0].id}
            name={inputs[0].name}
            label={inputs[0].label}
            value={values[inputs[0].name]}
            ddOptions={ddOptionsCompany}
            onChange={onChange}
          />
        </div>
      </div>
      <div class="spaceDiv" />
      <div class="card">
        <DataTable
          value={list}
          selectionMode={null}
          selection={selectedRows}
          stripedRows
          paginator
          rows={25}
          rowsPerPageOptions={[10, 25, 50]}
          filters={filters}
          filterDisplay="row"
          onSelectionChange={(e) => setSelectedRows(e.value)}
          dataKey="uuid"
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
          <Column field="fullName" filter filterPlaceholder="Caută" sortable header="Nume angajat"></Column>
          <Column field="identifier" filter filterPlaceholder="Caută" sortable header="CNP"></Column>
          <Column field="companyCode" filter filterPlaceholder="Caută" sortable header="Cod punct de lucru angajat"></Column>
          <Column field="workerType" filter filterPlaceholder="Caută" sortable header="Tip deservent auxiliar"></Column>
          <Column field="certificateNumber" filter filterPlaceholder="Caută" sortable header="Număr adeverință"></Column>
          <Column field="certificateIssuedDate" sortable dataType="date" header="Dată eliberare adeverință"></Column>
          <Column body={actionBodyTemplate}></Column>
        </DataTable>
      </div>
      <div class="spaceDiv" />
      <img
        src={require("../../static/images/buttons/" + ICONS.WorkerListDisabled)}
        alt="Listă deservenți auxiliari"
        title="Listă deservenți auxiliari"
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/worker/register"}>
        <img src={require("../../static/images/buttons/" + ICONS.WorkerAdd)} alt="Adaugă deservent auxiliar" title="Adaugă deservent auxiliar" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/worker/import"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.WorkerImport)}
          alt="Importă deservenți auxiliari"
          title="Importă deservenți auxiliari"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.WorkerExport)}
        alt="Exportă deservenți auxiliari"
        title="Exportă deservenți auxiliari"
        onClick={handleExcelExport}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.WorkerRegistration)}
        alt="Listă evidenţă personal ISCIR - deservenţi auxiliari"
        title="Listă evidenţă personal ISCIR - deservenţi auxiliari"
        onClick={handleIscirWorkers}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.WorkerGenerateDocuments)}
        alt="Documente autorizare deservenți auxiliari"
        title="Documente autorizare deservenți auxiliari"
        onClick={startGenerateDocs}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.WorkerInactivate)}
        alt="Dezactivează deservenți auxiliari"
        title="Dezactivează deservenți auxiliari"
        onClick={inactivate}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/worker/listonhold"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.WorkerOnHoldList)}
          alt="Listă deservenți auxiliari inactivi"
          title="Listă deservenți auxiliari inactivi"
        />
      </Link>
      <div class="spaceDiv" />
      <Dialog
        visible={showViewRowDialog}
        modal
        header={dialogHeaderElement("Detalii deservent auxiliar")}
        footer={dialogFooterContent("OK", () => setShowViewRowDialog(false))}
        style={{ width: "50rem" }}
        onHide={() => setShowViewRowDialog(false)}
      >
        <table>
          <tbody>
            <tr>
              <td class="tdleft"></td>
              <td class="tdrigth">
                <img
                  class="imgtoolbar"
                  src={require("../../static/images/buttons/" + ICONS.WorkerEdit)}
                  alt="Modifică deservent auxiliar"
                  title="Modifică deservent auxiliar"
                  onClick={(e) => goToEditWorker(e)}
                />
              </td>
            </tr>
            <tr>
              <td class="tdleft">Nume angajat:</td>
              <td class="tdrigth">{null != selectedRow ? selectedRow.fullName : ""}</td>
            </tr>
            <tr>
              <td class="tdleft">CNP:</td>
              <td class="tdrigth">{null != selectedRow ? selectedRow.identifier : ""}</td>
            </tr>
            <tr>
              <td class="tdleft">Cod punct de lucru angajat:</td>
              <td class="tdrigth">{null != selectedRow ? selectedRow.companyCode : ""}</td>
            </tr>
            <tr>
              <td class="tdleft">Tip deservent auxiliar:</td>
              <td class="tdrigth">{null != selectedRow ? selectedRow.workerType : ""}</td>
            </tr>
            <tr>
              <td class="tdleft">Număr adeverinţă:</td>
              <td class="tdrigth">{null != selectedRow ? selectedRow.certificateNumber : ""}</td>
            </tr>
            <tr>
              <td class="tdleft">Dată eliberare adeverinţă:</td>
              <td class="tdrigth">{null != selectedRow ? selectedRow.certificateIssuedDate : ""}</td>
            </tr>
            <tr>
              <td class="tdleft"></td>
              <td class="tdrigth">
                {null != selectedRow && selectedRow.certificateDocumentUuid !== null && selectedRow.certificateDocumentUuid !== "" ? (
                  <>
                    <img
                      class="imgtoolbar"
                      src={require("../../static/images/buttons/" + ICONS.WorkerExport)}
                      alt={"Descarca " + selectedRow.certificateDocumentFileName}
                      title={"Descarca " + selectedRow.certificateDocumentFileName}
                      onClick={(e) => handleGetDocument(selectedRow.certificateDocumentUuid, selectedRow.certificateDocumentFileName, e)}
                    />
                    &nbsp;&nbsp;{"Descarca " + selectedRow.certificateDocumentFileName}
                  </>
                ) : null}
              </td>
            </tr>
          </tbody>
        </table>
      </Dialog>
      <Dialog
        visible={showDeleteRowDialog}
        modal
        header={dialogHeaderElement("Atenţie")}
        footer={dialogFooterContentTwoButtons("Da", deleteListItem, "Nu", () => setShowDeleteRowDialog(false))}
        style={{ width: "450px" }}
        onHide={() => setShowDeleteRowDialog(false)}
      >
        <p className="m-0">Doreşti să ştergi adeverinţa: {null != selectedRow ? selectedRow.certificateNumber : ""} ?</p>
      </Dialog>
      <Dialog
        visible={showSelectedRowsWarningDialog}
        modal
        header={dialogHeaderElement("Atenţie")}
        footer={dialogFooterContent("OK", () => setShowSelectedRowsWarningDialog(false))}
        style={{ width: "450px" }}
        onHide={() => setShowSelectedRowsWarningDialog(false)}
      >
        <p className="m-0">Selectează minim un deservent auxiliar!</p>
      </Dialog>
      <DialogProcessingDocuments isVisible={showProcessingDocumentsDialog} />
      <DialogProcessingData isVisible={showProcessingDialog} />
      <DialogDataProcessed
        isVisible={showProcessedDialog}
        footer={dialogFooterContent("OK", () => setShowProcessedDialog(false))}
        onHide={() => setShowProcessedDialog(false)}
      />
      <DialogDataNotProcessed
        isVisible={showNotProcessedDialog}
        footer={dialogFooterContent("OK", () => setShowNotProcessedDialog(false))}
        onHide={() => setShowNotProcessedDialog(false)}
      />
      <DialogConfigurationNotProcessed
        isVisible={showConfigNotLoadedDialog}
        footer={dialogFooterContent("OK", () => setShowConfigNotLoadedDialog(false))}
        onHide={() => setShowConfigNotLoadedDialog(false)}
      />
      <DialogListNotLoaded
        isVisible={showListNotLoadedDialog}
        footer={dialogFooterContent("OK", () => setShowListNotLoadedDialog(false))}
        onHide={() => setShowListNotLoadedDialog(false)}
      />
    </div>
  );
}
