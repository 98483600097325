import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import React from "react";

const DialogProcessingDocuments = (props) => {
  const { isVisible, single } = props;

  return (
    <Dialog modal header="Generare documente" visible={isVisible} closable={false} style={{ width: "250px" }}>
      {null === single || undefined === single ? <p className="m-0">Se generează documentele!</p> : <p className="m-0">Se generează documentul!</p>}
      <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
    </Dialog>
  );
};

export default DialogProcessingDocuments;
