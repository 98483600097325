import "../../static/css/pageLayout.css";

import { Accordion, AccordionTab } from "primereact/accordion";
import axios from "axios";
import { Checkbox } from "primereact/checkbox";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { BASE_URL, STRING_CONST, dialogFooterContent, ICONS } from "../../common/globals";
import { FIELD_SIZE, isEmptyString, validateString, validateMinMaxText } from "../../common/validations";
import DialogConfigurationNotProcessed from "../../components/DialogConfigurationNotProcessed";
import DialogProcessingDocuments from "../../components/DialogProcessingDocuments";
import DialogDataProcessed from "../../components/DialogDataProcessed";
import DialogDataNotProcessed from "../../components/DialogDataNotProcessed";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import ProcessFormErrors from "../../components/ProcessFormErrors";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function WorkerGenerateDocsTests() {
  const generateTestsURL = BASE_URL + "workerdocuments/generate/tests";
  const signingPersonsURL = BASE_URL + "person/signing";
  const configURL = BASE_URL + "workerdocuments/get/config";
  const typesURL = BASE_URL + "workerdocuments/get/session/type";

  const uuid = sessionStorage.getItem("workerSessionUuid");
  const companyUuid = sessionStorage.getItem("companyUuid");

  const [config, setConfig] = useState([]);

  const [iscirPersons, setIscirPersons] = useState([]);
  const [member2Persons, setMember2Persons] = useState([]);
  const [member3Persons, setMember3Persons] = useState([]);
  const [signingPersons, setSigningPersons] = useState([]);
  const [types, setTypes] = useState([]);

  const [processingDialog, setProcessingDialog] = useState(null);
  const [processedDialog, setProcessedDialog] = useState(null);
  const [notProcessedDialog, setNotProcessedDialog] = useState(null);
  const [configNotProcessedDialog, setConfigNotProcessedDialog] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);

  const navigate = useNavigate();

  const { auth } = useAuth();

  const [values, setValues] = useState({
    iscirOwner: "",
    member2: "",
    member3: "",
    signingOwner: "",
    signingOwnerRole: "",
    watcherLastName: "",
    watcherFirstName: "",
    instructionsIssuedDate: "",
    theoreticalInstructionsNumber: "",
    theoreticalInstructionsIssuedDate: "",
    practicalInstructionsNumber: "",
    practicalInstructionsIssuedDate: "",
    theoreticalTestingDate: "",
    practicalTestingDate: "",
    startTheoreticalDate: "",
    endTheoreticalDate: "",
    startPracticalDate: "",
    endPracticalDate: "",
    includeInstructionsCertificates: "",
  });

  const inputs = [
    {
      id: 0,
      name: "iscirOwner",
      type: "text",
      placeholder: "Operator RSVTI",
      label: "Operator RSVTI",
    },
    {
      id: 1,
      name: "member2",
      type: "text",
      placeholder: "Membru comisie 2",
      label: "Membru comisie 2",
    },
    {
      id: 2,
      name: "member3",
      type: "text",
      placeholder: "Membru comisie 3",
      label: "Membru comisie 3",
    },
    {
      id: 3,
      name: "signingOwner",
      type: "text",
      placeholder: "Nume responsabil semnare acte",
      label: "Nume responsabil semnare acte",
    },
    {
      id: 4,
      name: "signingOwnerRole",
      type: "text",
      placeholder: "Funcție responsabil semnare acte",
      label: "Funcție responsabil semnare acte",
    },
    {
      id: 5,
      name: "watcherLastName",
      type: "text",
      placeholder: "Nume familie supraveghetor practică",
      label: "Nume familie supraveghetor practică",
    },
    {
      id: 6,
      name: "watcherFirstName",
      type: "text",
      placeholder: "Prenume supraveghetor practică",
      label: "Prenume supraveghetor practică",
    },
    {
      id: 7,
      name: "instructionsIssuedDate",
      type: "date",
      placeholder: "Alege data emitere listă personal instruire",
      label: "Alege data emitere listă personal instruire",
    },
    {
      id: 8,
      name: "theoreticalInstructionsNumber",
      type: "text",
      placeholder: "Alege număr adeverință instruire teoretică - format: IT001",
      label: "Alege număr adeverință instruire teoretică",
    },
    {
      id: 9,
      name: "theoreticalInstructionsIssuedDate",
      type: "date",
      placeholder: "Alege data emitere adeverință instruire teoretică",
      label: "Alege data emitere adeverință instruire teoretică",
    },
    {
      id: 10,
      name: "practicalInstructionsNumber",
      type: "text",
      placeholder: "Alege număr adeverință instruire practică - format: IP001",
      label: "Alege număr adeverință instruire practică",
    },
    {
      id: 11,
      name: "practicalInstructionsIssuedDate",
      type: "date",
      placeholder: "Alege data emitere adeverință instruire practică",
      label: "Alege data emitere adeverință instruire practică",
    },
    {
      id: 12,
      name: "theoreticalTestingDate",
      type: "date",
      placeholder: "Alege data testare teoretică",
      label: "Alege data testare teoretică",
    },
    {
      id: 13,
      name: "practicalTestingDate",
      type: "date",
      placeholder: "Alege data testare practică",
      label: "Alege data testare practică",
    },
    {
      id: 14,
      name: "maxMass",
      type: "text",
      placeholder: "Sarcina maximă",
      label: "Sarcina maximă",
    },
    {
      id: 15,
      name: "startTheoreticalDate",
      type: "date",
      placeholder: "Alege dată început perioadă efectuare instruire teoretică",
      label: "Alege dată început perioadă efectuare instruire teoretică",
    },
    {
      id: 16,
      name: "endTheoreticalDate",
      type: "date",
      placeholder: "Alege dată sfârșit perioadă efectuare instruire teoretică",
      label: "Alege dată sfârșit perioadă efectuare instruire teoretică",
    },
    {
      id: 17,
      name: "startPracticalDate",
      type: "date",
      placeholder: "Alege dată început perioadă efectuare instruire practică",
      label: "Alege dată început perioadă efectuare instruire practică",
    },
    {
      id: 18,
      name: "endPracticalDate",
      type: "date",
      placeholder: "Alege dată sfârșit perioadă efectuare instruire practică",
      label: "Alege dată sfârșit perioadă efectuare instruire practică",
    },
  ];

  const FIELD = {
    iscirOwner: 0,
    member2: 1,
    member3: 2,
    signingOwner: 3,
    signingOwnerRole: 4,
    watcherLastName: 5,
    watcherFirstName: 6,
    instructionsIssuedDate: 7,
    theoreticalInstructionsNumber: 8,
    theoreticalInstructionsIssuedDate: 9,
    practicalInstructionsNumber: 10,
    practicalInstructionsIssuedDate: 11,
    theoreticalTestingDate: 12,
    practicalTestingDate: 13,
    maxMass: 14,
    startTheoreticalDate: 15,
    endTheoreticalDate: 16,
    startPracticalDate: 17,
    endPracticalDate: 18,
  };

  useEffect(() => {
    axios
      .post(
        configURL,
        {
          uuid,
          companyUuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setConfig(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
    axios
      .post(
        signingPersonsURL,
        {
          uuid: companyUuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setIscirPersons(response.data.iscirOwners);
        setMember2Persons(response.data.secondMembers);
        setMember3Persons(response.data.thirdMembers);
        setSigningPersons(response.data.signingOwners);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
    axios
      .post(
        typesURL,
        {
          uuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setTypes(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let data = [];
    data = { ...values };
    if (iscirPersons.length === 1) {
      data = { ...data, iscirOwner: iscirPersons[0].uuid };
    }
    if (signingPersons.length === 1) {
      data = { ...data, signingOwner: signingPersons[0].uuid, signingOwnerRole: signingPersons[0].role };
    }
    if (member2Persons.length === 1) {
      data = { ...data, member2: member2Persons[0].uuid };
    }
    if (member3Persons.length === 1) {
      data = { ...data, member3: member3Persons[0].uuid };
    }
    setValues({ ...values, ...data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iscirPersons, signingPersons, member2Persons, member3Persons]);

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onChangeCheckBox = (e) => {
    if (e.target.checked) {
      setValues({ ...values, [e.target.name]: e.target.value });
    } else {
      setValues({ ...values, [e.target.name]: "" });
    }
  };

  const onChangeSigningPersons = (e) => {
    let role = "";
    for (var i = 0; i < signingPersons.length; i++) {
      if (e.target.value === signingPersons[i].uuid) {
        role = signingPersons[i].role;
      }
    }
    setValues({ ...values, [e.target.name]: e.target.value, signingOwnerRole: role });
  };

  const handleGenerate = async (e) => {
    e.preventDefault();
    setProcessingDialog(true);
    if (!validateForm()) {
      setProcessingDialog(false);
      return;
    }
    axios
      .post(
        generateTestsURL,
        {
          uuid: uuid,
          companyUuid: companyUuid,
          iscirOwner: values.iscirOwner,
          member2: values.member2,
          member3: values.member3,
          signingOwner: values.signingOwner,
          signingOwnerRole: values.signingOwnerRole,
          watcherLastName: values.watcherLastName,
          watcherFirstName: values.watcherFirstName,
          instructionsIssuedDate: values.instructionsIssuedDate,
          theoreticalInstructionsNumber: values.theoreticalInstructionsNumber,
          theoreticalInstructionsIssuedDate: values.theoreticalInstructionsIssuedDate,
          practicalInstructionsNumber: values.practicalInstructionsNumber,
          practicalInstructionsIssuedDate: values.practicalInstructionsIssuedDate,
          theoreticalTestingDate: values.theoreticalTestingDate,
          practicalTestingDate: values.practicalTestingDate,
          startTheoreticalDate: values.startTheoreticalDate,
          endTheoreticalDate: values.endTheoreticalDate,
          startPracticalDate: values.startPracticalDate,
          endPracticalDate: values.endPracticalDate,
          includeInstructionsCertificates: values.includeInstructionsCertificates,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setProcessingDialog(false);
        //setProcessedDialog(true);
        navigate("/worker/generatedocscertificates");
      })
      .catch((error) => {
        setProcessingDialog(false);
        setNotProcessedDialog(true);
      });
  };

  const validateForm = () => {
    let result = true;
    let data = [];
    if (isEmptyString(values.iscirOwner)) {
      data.push({
        id: inputs[FIELD.iscirOwner].id,
        value: inputs[FIELD.iscirOwner].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_WORKER_DOCUMENTS,
      });
      result = false;
    }
    if (isEmptyString(values.member2)) {
      data.push({
        id: inputs[FIELD.member2].id,
        value: inputs[FIELD.member2].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_WORKER_DOCUMENTS,
      });
      result = false;
    }
    if (isEmptyString(values.signingOwner)) {
      data.push({
        id: inputs[FIELD.signingOwner].id,
        value: inputs[FIELD.signingOwner].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_WORKER_DOCUMENTS,
      });
      result = false;
    }
    if (!validateString(values.signingOwnerRole, FIELD_SIZE.SignerOwnerRoleMin, FIELD_SIZE.SignerOwnerRoleMax)) {
      data.push({
        id: inputs[FIELD.signingOwnerRole].id,
        value: inputs[FIELD.signingOwnerRole].label + validateMinMaxText(FIELD_SIZE.SignerOwnerRoleMin, FIELD_SIZE.SignerOwnerRoleMax),
      });
      result = false;
    }
    if (isEmptyString(values.instructionsIssuedDate)) {
      data.push({
        id: inputs[FIELD.instructionsIssuedDate].id,
        value: inputs[FIELD.instructionsIssuedDate].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_WORKER_DOCUMENTS,
      });
      result = false;
    }
    if (isEmptyString(values.theoreticalTestingDate)) {
      data.push({
        id: inputs[FIELD.theoreticalTestingDate].id,
        value: inputs[FIELD.theoreticalTestingDate].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_WORKER_DOCUMENTS,
      });
      result = false;
    }
    if (isEmptyString(values.practicalTestingDate)) {
      data.push({
        id: inputs[FIELD.practicalTestingDate].id,
        value: inputs[FIELD.practicalTestingDate].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_WORKER_DOCUMENTS,
      });
      result = false;
    }
    if (values["includeInstructionsCertificates"] === "Yes") {
      if (!validateString(values.theoreticalInstructionsNumber, FIELD_SIZE.One, FIELD_SIZE.PVMax)) {
        data.push({
          id: inputs[FIELD.theoreticalInstructionsNumber].id,
          value: inputs[FIELD.theoreticalInstructionsNumber].label + validateMinMaxText(FIELD_SIZE.One, FIELD_SIZE.PVMax),
        });
        result = false;
      }
      if (isEmptyString(values.theoreticalInstructionsIssuedDate)) {
        data.push({
          id: inputs[FIELD.theoreticalInstructionsIssuedDate].id,
          value: inputs[FIELD.theoreticalInstructionsIssuedDate].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_WORKER_DOCUMENTS,
        });
        result = false;
      }
      if (isEmptyString(values.startTheoreticalDate)) {
        data.push({
          id: inputs[FIELD.startTheoreticalDate].id,
          value: inputs[FIELD.startTheoreticalDate].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_WORKER_DOCUMENTS,
        });
        result = false;
      }
      if (isEmptyString(values.endTheoreticalDate)) {
        data.push({
          id: inputs[FIELD.endTheoreticalDate].id,
          value: inputs[FIELD.endTheoreticalDate].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_WORKER_DOCUMENTS,
        });
        result = false;
      }
      if (!validateString(values.practicalInstructionsNumber, FIELD_SIZE.One, FIELD_SIZE.PVMax)) {
        data.push({
          id: inputs[FIELD.practicalInstructionsNumber].id,
          value: inputs[FIELD.practicalInstructionsNumber].label + validateMinMaxText(FIELD_SIZE.One, FIELD_SIZE.PVMax),
        });
        result = false;
      }
      if (isEmptyString(values.practicalInstructionsIssuedDate)) {
        data.push({
          id: inputs[FIELD.practicalInstructionsIssuedDate].id,
          value: inputs[FIELD.practicalInstructionsIssuedDate].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_WORKER_DOCUMENTS,
        });
        result = false;
      }
      if (isEmptyString(values.startPracticalDate)) {
        data.push({
          id: inputs[FIELD.startPracticalDate].id,
          value: inputs[FIELD.startPracticalDate].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_WORKER_DOCUMENTS,
        });
        result = false;
      }
      if (isEmptyString(values.endPracticalDate)) {
        data.push({
          id: inputs[FIELD.endPracticalDate].id,
          value: inputs[FIELD.endPracticalDate].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_WORKER_DOCUMENTS,
        });
        result = false;
      }
      if (!validateString(values.watcherLastName, FIELD_SIZE.Zero, FIELD_SIZE.LastNameMax)) {
        data.push({
          id: inputs[FIELD.watcherLastName].id,
          value: inputs[FIELD.watcherLastName].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.LastNameMax),
        });
        result = false;
      }
      if (!validateString(values.watcherFirstName, FIELD_SIZE.Zero, FIELD_SIZE.FirstNameMax)) {
        data.push({
          id: inputs[FIELD.watcherFirstName].id,
          value: inputs[FIELD.watcherFirstName].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.FirstNameMax),
        });
        result = false;
      }
    }
    setValidationErrors(data);
    return result;
  };

  const handleViewDocuments = async (sessionUuid, e) => {
    e.preventDefault();
    sessionStorage.setItem("sessionUuid", sessionUuid);
    navigate("/worker/generatedocsview");
  };

  return (
    <div className="clientArea">
      <Sidebar />

      <button class="buttonClassGreen">Generare documente des. auxiliar</button>
      <div class="spaceDiv" />

      <div class="rowdouble">
        <div class="column">
          <p class="pTitle">
            Punct de lucru : {config.code} - {config.county} - {config.town} - {config.addressDetails1}
          </p>
        </div>
      </div>

      <div class="rowdouble">
        <div class="column">
          <p class="pTitle">Personal semnatar documentații</p>
        </div>
      </div>

      <div class="rowdouble">
        <div class="column" id={"internalLink" + inputs[FIELD.iscirOwner].id}>
          <FormSelect
            key={inputs[FIELD.iscirOwner].id}
            name={inputs[FIELD.iscirOwner].name}
            label={inputs[FIELD.iscirOwner].label}
            value={values[inputs[FIELD.iscirOwner].name]}
            ddOptions={iscirPersons}
            onChange={onChange}
          />
        </div>
      </div>

      <div class="rowdouble">
        <div class="column">
          <p class="pTitle">Membrii comisie</p>
        </div>
      </div>

      <div class="rowdouble">
        <div class="column" id={"internalLink" + inputs[FIELD.member2].id}>
          <FormSelect
            key={inputs[FIELD.member2].id}
            name={inputs[FIELD.member2].name}
            label={inputs[FIELD.member2].label}
            value={values[inputs[FIELD.member2].name]}
            ddOptions={member2Persons}
            onChange={onChange}
          />
        </div>
      </div>

      <div class="rowdouble">
        <div class="column" id={"internalLink" + inputs[FIELD.member3].id}>
          <FormSelect
            key={inputs[FIELD.member3].id}
            name={inputs[FIELD.member3].name}
            label={inputs[FIELD.member3].label}
            value={values[inputs[FIELD.member3].name]}
            ddOptions={member3Persons}
            onChange={onChange}
          />
        </div>
      </div>

      <div class="rowdouble">
        <div class="column">
          <p class="pTitle">Responsabil semnare acte</p>
        </div>
      </div>

      <div class="rowdouble">
        <div class="column" id={"internalLink" + inputs[FIELD.signingOwner].id}>
          <FormSelect
            key={inputs[FIELD.signingOwner].id}
            name={inputs[FIELD.signingOwner].name}
            label={inputs[FIELD.signingOwner].label}
            value={values[inputs[FIELD.signingOwner].name]}
            ddOptions={signingPersons}
            onChange={onChangeSigningPersons}
          />
        </div>
      </div>

      <div class="rowdouble">
        <div class="column" id={"internalLink" + inputs[FIELD.signingOwnerRole].id}>
          <FormInput
            key={inputs[FIELD.signingOwnerRole].id}
            {...inputs[FIELD.signingOwnerRole]}
            value={values[inputs[FIELD.signingOwnerRole].name]}
            onChange={onChange}
          />
        </div>
      </div>

      <div class="rowdouble">
        <div class="column">
          <p class="pTitle">Documentație testare deservenți auxiliari</p>
        </div>
      </div>

      <div class="rowdouble">
        <div class="column">
          <p class="pTitle">
            Lista personal instruire {types.workerType} - {types.equipmentClassification}
          </p>
        </div>
      </div>

      <div class="rowdouble">
        <div class="column" id={"internalLink" + inputs[FIELD.instructionsIssuedDate].id}>
          <FormInput
            key={inputs[FIELD.instructionsIssuedDate].id}
            {...inputs[FIELD.instructionsIssuedDate]}
            value={values[inputs[FIELD.instructionsIssuedDate].name]}
            onChange={onChange}
          />
        </div>
      </div>

      <div class="rowdouble">
        <Accordion multiple>
          <AccordionTab header="Detalii adeverinţe instruire teoretică şi practică">
            <div class="rowdouble">
              <div class="column">
                <Checkbox
                  inputId="includeInstructionsCertificates"
                  name="includeInstructionsCertificates"
                  value="Yes"
                  onChange={onChangeCheckBox}
                  checked={values["includeInstructionsCertificates"] === "Yes"}
                />
                <label htmlFor="includeInstructionsCertificates" className="flex align-items-center">
                  &nbsp;&nbsp;Generează adeverinţe instruire teoretică şi practică
                </label>
              </div>
            </div>

            <div class="rowdouble">
              <div class="column">
                <p class="pTitle">
                  Adeverință instruire teoretică {types.workerType} - {types.equipmentClassification}
                </p>
              </div>
            </div>

            <div class="rowdouble">
              <div class="column" id={"internalLink" + inputs[FIELD.theoreticalInstructionsNumber].id}>
                <FormInput
                  key={inputs[FIELD.theoreticalInstructionsNumber].id}
                  {...inputs[FIELD.theoreticalInstructionsNumber]}
                  value={values[inputs[FIELD.theoreticalInstructionsNumber].name]}
                  onChange={onChange}
                />
              </div>
            </div>

            <div class="rowdouble">
              <div class="column" id={"internalLink" + inputs[FIELD.theoreticalInstructionsIssuedDate].id}>
                <FormInput
                  key={inputs[FIELD.theoreticalInstructionsIssuedDate].id}
                  {...inputs[FIELD.theoreticalInstructionsIssuedDate]}
                  value={values[inputs[FIELD.theoreticalInstructionsIssuedDate].name]}
                  onChange={onChange}
                />
              </div>
            </div>

            <div class="rowdouble">
              <div class="column" id={"internalLink" + inputs[FIELD.startTheoreticalDate].id}>
                <FormInput
                  key={inputs[FIELD.startTheoreticalDate].id}
                  {...inputs[FIELD.startTheoreticalDate]}
                  value={values[inputs[FIELD.startTheoreticalDate].name]}
                  onChange={onChange}
                />
              </div>
            </div>

            <div class="rowdouble">
              <div class="column" id={"internalLink" + inputs[FIELD.endTheoreticalDate].id}>
                <FormInput
                  key={inputs[FIELD.endTheoreticalDate].id}
                  {...inputs[FIELD.endTheoreticalDate]}
                  value={values[inputs[FIELD.endTheoreticalDate].name]}
                  onChange={onChange}
                />
              </div>
            </div>

            <div class="rowdouble">
              <div class="column">
                <p class="pTitle">
                  Adeverință instruire practică {types.workerType} - {types.equipmentClassification}
                </p>
              </div>
            </div>

            <div class="rowdouble">
              <div class="column" id={"internalLink" + inputs[FIELD.practicalInstructionsNumber].id}>
                <FormInput
                  key={inputs[FIELD.practicalInstructionsNumber].id}
                  {...inputs[FIELD.practicalInstructionsNumber]}
                  value={values[inputs[FIELD.practicalInstructionsNumber].name]}
                  onChange={onChange}
                />
              </div>
            </div>

            <div class="rowdouble">
              <div class="column" id={"internalLink" + inputs[FIELD.practicalInstructionsIssuedDate].id}>
                <FormInput
                  key={inputs[FIELD.practicalInstructionsIssuedDate].id}
                  {...inputs[FIELD.practicalInstructionsIssuedDate]}
                  value={values[inputs[FIELD.practicalInstructionsIssuedDate].name]}
                  onChange={onChange}
                />
              </div>
            </div>

            <div class="rowdouble">
              <div class="column" id={"internalLink" + inputs[FIELD.startPracticalDate].id}>
                <FormInput
                  key={inputs[FIELD.startPracticalDate].id}
                  {...inputs[FIELD.startPracticalDate]}
                  value={values[inputs[FIELD.startPracticalDate].name]}
                  onChange={onChange}
                />
              </div>
            </div>

            <div class="rowdouble">
              <div class="column" id={"internalLink" + inputs[FIELD.endPracticalDate].id}>
                <FormInput
                  key={inputs[FIELD.endPracticalDate].id}
                  {...inputs[FIELD.endPracticalDate]}
                  value={values[inputs[FIELD.endPracticalDate].name]}
                  onChange={onChange}
                />
              </div>
            </div>

            <div class="rowdouble">
              <div class="column">
                <p class="pTitle">Optional (supraveghetor practica)</p>
              </div>
            </div>

            <div class="rowdouble">
              <div class="column" id={"internalLink" + inputs[FIELD.watcherLastName].id}>
                <FormInput
                  key={inputs[FIELD.watcherLastName].id}
                  {...inputs[FIELD.watcherLastName]}
                  value={values[inputs[FIELD.watcherLastName].name]}
                  onChange={onChange}
                />
              </div>
            </div>

            <div class="rowdouble">
              <div class="column" id={"internalLink" + inputs[FIELD.watcherFirstName].id}>
                <FormInput
                  key={inputs[FIELD.watcherFirstName].id}
                  {...inputs[FIELD.watcherFirstName]}
                  value={values[inputs[FIELD.watcherFirstName].name]}
                  onChange={onChange}
                />
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      </div>

      <div class="rowdouble">
        <div class="column">
          <p class="pTitle">
            Testare teoretică {types.workerType} - {types.equipmentClassification}
          </p>
        </div>
      </div>

      <div class="rowdouble">
        <div class="column" id={"internalLink" + inputs[FIELD.theoreticalTestingDate].id}>
          <FormInput
            key={inputs[FIELD.theoreticalTestingDate].id}
            {...inputs[FIELD.theoreticalTestingDate]}
            value={values[inputs[FIELD.theoreticalTestingDate].name]}
            onChange={onChange}
          />
        </div>
      </div>

      <div class="rowdouble">
        <div class="column">
          <p class="pTitle">
            Tabel testare practică {types.workerType} - {types.equipmentClassification}
          </p>
        </div>
      </div>

      <div class="rowdouble">
        <div class="column">
          <p class="pTitle">
            Proba practică {types.workerType} - {types.equipmentClassification}
          </p>
        </div>
      </div>

      <div class="rowdouble">
        <div class="column" id={"internalLink" + inputs[FIELD.practicalTestingDate].id}>
          <FormInput
            key={inputs[FIELD.practicalTestingDate].id}
            {...inputs[FIELD.practicalTestingDate]}
            value={values[inputs[FIELD.practicalTestingDate].name]}
            onChange={onChange}
          />
        </div>
      </div>
      {validationErrors.length > 0 ? <ProcessFormErrors validationErrors={validationErrors} /> : null}
      {processingDialog !== true ? (
        <div class="rowdoublecentered">
          <div class="columnbutton">
            <img
              class="imgtoolbar"
              src={require("../../static/images/buttons/" + ICONS.WorkerGenerateWordDocuments)}
              alt={"Generare documente testare " + types.workerType + " - " + types.equipmentClassification}
              title={"Generare documente testare " + types.workerType + " - " + types.equipmentClassification}
              onClick={handleGenerate}
            />
          </div>
        </div>
      ) : null}
      <div class="rowdoublecentered">
        <div class="columnbutton">
          <img
            class="imgtoolbar"
            src={require("../../static/images/buttons/" + ICONS.WorkerGenerateDocuments)}
            alt="Vezi documentele generate până la acest pas"
            title="Vezi documentele generate până la acest pas"
            onClick={(e) => handleViewDocuments(uuid, e)}
          />
        </div>
      </div>
      <DialogProcessingDocuments isVisible={processingDialog} />
      <DialogDataProcessed isVisible={processedDialog} footer={dialogFooterContent("OK", () => setProcessedDialog(false))} />
      <DialogDataNotProcessed isVisible={notProcessedDialog} footer={dialogFooterContent("OK", () => setNotProcessedDialog(false))} />
      <DialogConfigurationNotProcessed
        isVisible={configNotProcessedDialog}
        footer={dialogFooterContent("OK", () => setConfigNotProcessedDialog(false))}
      />
    </div>
  );
}
