import "../static/css/footer.css";
import "../static/css/header.css";
import "../static/css/style.css";

import React from "react";
import { Link } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";

export default function MainPage() {
  return (
    <div className="MainPage">
      <Header />

      <main>
        <div>
          <br />
          <br />
          <div>
            <img alt="" className="img-all" src={require("../static/images/1920x400/bckg_1920x400_001.jpg")} />
          </div>
          <br />
        </div>
        <br />

        <div className="surface-0 text-700 text-center pmarg">
          <div className="text-900 font-bold text-5xl mb-3">Aplicația MyRSVTI 1.0</div>
          <div className="text-blue-600 font-bold mb-3">
            <i className="pi pi-discord"></i>&nbsp;Dezvoltată de Asisco Tehnic SRL
          </div>
          <div className="text-blue-600 font-bold mb-3">
            <i className="pi pi-discord"></i>&nbsp;Dezvoltată de Cristian Lupu & Cornel Lupu
          </div>
          <div className="text-700 text-2xl mb-5">
            Aplicația a fost concepută pentru a facilita gestionarea evidenței echipamentelor/instalațiilor din domeniul ISCIR, precum și a
            personalului deservent și deservent auxiliar.
          </div>
          <div className="text-700 text-2xl mb-5">Aplicația se poate utiliza de orice firmă care deține echipamente/instalații ISCIR.</div>
          <div className="text-700 text-2xl mb-5">
            Cu această aplicație se pot emite documente solicitate de către ISCIR, atât deținătorilor/utilizatorilor de echipamente/instalații ISCIR,
            cât și operatorilor RSVTI, cum ar fi, de exemplu, listă evidență echipamente/instalații ISCIR, procese verbale RSVTI, comenzi CNCIR,
            registre de supraveghere pentru echipamentele/instalațiile deținute, precum și procese verbale de instruire și reautorizare deservenți,
            adeverințe și procese verbale de instruire și acceptare a personalului deservent auxiliar.
          </div>

          <p className="btn-lnk-blue-center">
            <Link to={"/public/prices"}>
              <span className="p-3 w-full mt-auto txt-ctr">Tarife</span>
            </Link>
          </p>
        </div>
        <br />
        <br />

        <div className="grid grid-nogutter surface-0 text-700 pmarg">
          <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
            <section>
              <span className="block text-6xl font-bold mb-1">Echipamente / instalații</span>
              <div className="text-6xl text-primary font-bold mb-3">ISCIR</div>
              <div>
                <span className="line-height-3 text-2xl">Documente generate din aplicația MyRSVTI:</span>
                <br />
                <span className="line-height-3 text-2xl">- listă evidență echipamente/instalații ISCIR</span>
                <br />
                <span className="line-height-3 text-2xl">- listă evidență echipamente/instalații interne</span>
                <br />
                <span className="line-height-3 text-2xl">- registru de evidență a supravegherii</span>
                <br />
                <span className="line-height-3 text-2xl">- comandă CNCIR</span>
                <br />
                <span className="line-height-3 text-2xl">- procese verbale emise de RSVTI</span>
                <br />
                <span className="line-height-3 text-2xl">- raport scadență autorizații echipamente/instalații</span>
                <br />
                <span className="line-height-3 text-2xl">- etc.</span>
              </div>

              <br />

              {/* <Button label="Tarife" type="button" className="mr-3 p-button-raised" link onClick={() =>  window.open('/public/prices', '_self')} /> */}
              <p className="btn-lnk-blue-left">
                <Link to={"/public/prices"}>
                  <span className="p-3 w-full mt-auto txt-ctr">Tarife</span>
                </Link>
              </p>
            </section>
          </div>
          <div className="col-12 md:col-6 overflow-hidden">
            <img
              src={require("../static/images/main/equip01_716x630.jpg")}
              alt="echipamente ISCIR"
              className="md:ml-auto block md:h-full"
              style={{ clipPath: "polygon(8% 0, 100% 0%, 100% 100%, 0 100%)" }}
            />
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />

        <div className="grid grid-nogutter surface-0 text-700 pmarg">
          <div className="col-12 md:col-6 overflow-hidden">
            <img
              src={require("../static/images/main/person02_716x630.jpg")}
              alt="personal ISCIR"
              className="md:ml-auto block md:h-full"
              style={{ clipPath: "polygon(0% 0%, 95% 0%, 87% 100%, 0% 100%)" }}
            />
          </div>

          <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
            <section>
              <span className="block text-6xl font-bold mb-1">Personal deservent / </span>
              <span className="block text-6xl font-bold mb-1">deservent auxiliar</span>

              <div className="text-6xl text-primary font-bold mb-3">ISCIR</div>
              <div>
                <span className="line-height-3 text-2xl">- listă evidență personal</span>
                <br />
                <span className="line-height-3 text-2xl">- proces verbal instruire personal</span>
                <br />
                <span className="line-height-3 text-2xl">- teste teoretice și practice verificare personal</span>
                <br />
                <span className="line-height-3 text-2xl">- proces verbal acceptare deservenți auxiliari</span>
                <br />
                <span className="line-height-3 text-2xl">- raport scadență taloane deservenți</span>
                <br />
                <span className="line-height-3 text-2xl">- raport scadență adeverințe deservenți auxiliari</span>
                <br />
                <span className="line-height-3 text-2xl">- etc.</span>
              </div>

              <br />
              <p className="btn-lnk-blue-left">
                <Link to={"/public/prices"}>
                  <span className="p-3 w-full mt-auto txt-ctr">Tarife</span>
                </Link>
              </p>
            </section>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />

        <br />
      </main>

      <div>
        <br />
        <br />
      </div>

      <Footer />
    </div>
  );
}
