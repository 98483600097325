import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import React from "react";

const DialogProcessingData = (props) => {
  const { isVisible } = props;

  return (
    <Dialog modal header="Prelucrare informaţii" visible={isVisible} closable={false} style={{ width: "250px" }}>
      <p className="m-0">Se prelucrează informaţiile!</p>
      <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
    </Dialog>
  );
};

export default DialogProcessingData;
