import "../static/css/tutorial.css";

import React from "react";
import { Link } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";

export default function Gdpr() {
  return (
    <div className="Gdpr">
      <Header />

      <main>
        <div className="surface-0">
          <br />
          <br />
          <div>
            <img alt="" className="img-all" src={require("../static/images/1920x400/bckg_1920x400_008.jpg")} />
          </div>
          <br />
          <br />

          <div className="text-900 font-bold text-6xl mb-4 text-center">Politica de confidențialitate</div>
          <div className="font-bold text-2xl text-900 text-justify line-height-3 pmarg">
            TERMENI ȘI CONDIȚII DE UTILIZARE A DATELOR CU CARACTER PERSONAL
            <br /><br />
          </div>
          <div className="font-bold text-2xl text-900 text-justify line-height-3 pmarg">
            1. PRECIZĂRI GENERALE
            <br />
          </div>
          <div className="text-700 text-xl mb-6 text-justify line-height-3 pmarg">
            Asisco Tehnic SRL este Operator de date cu caracter personal și își exercită această calitate în conformitate cu legea 677/2001 și cu Regulamentul (UE) 679/2016 al Parlamentului European și al Consiliului din 27 aprilie 2016 privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter personal și privind libera circulație a acestor date (GDPR). Această declarație de confidențialitate explică modalitatea în care Asisco Tehnic SRL procesează datele cu caracter personal ale cetățeanului, cum o efectuează și în ce scopuri. Asisco Tehnic SRL se angajează să respecte confidențialitatea datelor dumneavoastră cu caracter personal. Vom trata toate datele personale furnizate de dumneavoastră ca fiind confidențiale și vom prelucra numai informațiile permise de legislația aplicabilă. Această notă de confidențialitate descrie politicile și practicile instituției privind colectarea și utilizarea datelor dumneavoastră personale și vă stabilește drepturile de confidențialitate. Confidențialitatea informațiilor este o responsabilitate permanentă și, prin urmare, vom actualiza această notificare de confidențialitate de îndată și pe măsură ce vom întreprinde noi practici privind datele cu caracter personal sau vom adopta noi politici de confidențialitate. În ceea ce privește prelucrarea datelor bazate pe consimțământ (dupa cum este descris mai jos), vă vom notifica orice modificare și vom solicita consimțământul dumneavoastră suplimentar.
            <br />
          </div>

          <div className="font-bold text-2xl text-900 text-justify line-height-3 pmarg">
            2. CONTEXTUL LEGISLATIV
            <br />
          </div>
          <div className="text-700 text-xl mb-6 text-justify line-height-3 pmarg">
            Conform cerințelor Regulamentului (UE) 679/2016 privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter personal și privind libera circulație a acestor date (denumit în continuare „Regulamentul GDPR”) aplicabil din data de 25 mai 2018, avem obligația de a administra în condiții de siguranță și numai pentru scopurile specificate, datele personale pe care ni le furnizați prin utilizarea site-ului nostru în acord cu prevederile Regulamentului GDPR.
            <br />
            Pentru mai multe detalii privind conținutul acestei norme juridice, vă rugăm să accesați următorul link:
            <br />
            http://www.dataprotection.ro/?page=Regulamentul_nr_679_2016
            <br />
          </div>

          <div className="font-bold text-2xl text-900 text-justify line-height-3 pmarg">
            3. RESPONSABILUL CU PROTECȚIA DATELOR CU CARACTER PERSONAL
            <br />
          </div>
          <div className="text-700 text-xl mb-6 text-justify line-height-3 pmarg">
            În cadrul Asisco Tehnic SRL a fost desemnat un responsabil cu protecția datelor (DPO) care va duce la îndeplinire obligațiile stabilite de legislația aplicabilă în domeniul protecției datelor cu caracter personal, iar dumneavoastră puteți contacta responsabilul cu protecția datelor cu privire la toate chestiunile legate de prelucrarea datelor dumneavoastră și la exercitarea drepturilor dumneavoastră potrivit dispozițiilor legale aplicabile, în special dacă aveți întrebări sau nelămuriri cu privire la modul în care noi prelucrăm datele dumneavoastră cu caracter personal. Adresa de e-mail la care puteți contacta responsabilul cu protecția datelor este: office@asisco.ro.
            <br />
          </div>

          <div className="font-bold text-2xl text-900 text-justify line-height-3 pmarg">
            4. CUM COLECTĂM ȘI FOLOSIM INFORMAȚIILE DUMNEAVOASTRĂ PERSONALE
            <br />
          </div>
          <div className="font-bold text-2xl text-900 text-justify line-height-3 pmarg">
            4.1. Informații personale pe care ni le oferiți
            <br />
          </div>
          <div className="text-700 text-xl mb-6 text-justify line-height-3 pmarg">
            Putem colecta, stoca și utiliza următoarele tipuri de date personale:
            <br />
            <ul className="text-700 text-xl mb-6 blt-marg black-bullet">
              <li>informații despre computerul dumneavoastră și despre vizitele și utilizarea acestui site web (inclusiv adresa dumneavoastră IP, locația geografică, tipul și versiunea browserului, sistemul de operare, sursa de recomandare, durata vizitei, vizualizările de pagină și căile de navigare ale site-ului);</li>
              <li>informațiile conținute sau legate de orice comunicare pe care ne-o trimiteți prin acest site (inclusiv corespondența cu noi, conținutul comunicării și datele asociate cu comunicarea);</li>
              <li>orice alte date personale pe care ați ales să ni le trimiteți.</li>
            </ul>
          </div>

          <div className="font-bold text-2xl text-900 text-justify line-height-3 pmarg">
            4.2. Scopurile utilizării datelor dumneavoastră cu caracter personal
            <br />
          </div>
          <div className="text-700 text-xl mb-6 text-justify line-height-3 pmarg">
            Putem prelucra datele dumneavoastră cu caracter personal în următoarele cazuri:
            <br />
            <ul className="text-700 text-xl mb-6 blt-marg black-bullet">
              <li>atunci când trebuie să întreprindem măsurile necesare pentru a încheia un contract cu dumneavoastră;</li>
              <li>atunci când trebuie să executam un contract pe care l-am încheiat cu dumneavoastră;</li>
              <li>atunci când solicitați un serviciu din partea noastră prin orice mijloc de contact;</li>
              <li>atunci când trebuie să respectăm o obligație legală (de exemplu, legile aplicabile în relațiile de muncă, contabilitate, audit);</li>
              <li>atunci când este necesar pentru interesele noastre legitime (sau pentru cele ale unei terțe părți) și pentru interesele dumneavoastră (de exemplu, pentru detectarea și prevenirea fraudei sau pentru asigurarea securității IT și a rețelei informatice), cu excepția cazului în care drepturile dumneavoastră fundamentale prevalează față de aceste interese;</li>
              <li>în cazul în care este necesar pentru interesul public sau în scopuri oficiale.</li>
            </ul>
          </div>
          <div className="text-700 text-xl mb-6 text-justify line-height-3 pmarg">
            Prelucrăm datele dumneavoastră cu caracter personal pentru diverse motive tehnice, administrative și operaționale, cum ar fi:            <br />
            <ul className="text-700 text-xl mb-6 blt-marg black-bullet">
              <li>pentru a ne asigura de prezentarea conținutului în modul cel mai eficient pentru dumneavoastră;</li>
              <li>pentru a îmbunătăți site-ul, inclusiv funcționalitatea acestuia;</li>
              <li>pentru administrarea site-ului;</li>
              <li>pentru operațiunile interne, inclusiv în scopuri de depanare, analiză a datelor, testare, cercetare, statistică și de cercetare;</li>
              <li>pentru a păstra site-ul în condiții de siguranță;</li>
              <li>pentru publicitate și marketing, inclusiv pentru scopuri de marketing specific, astfel încât să oferim informații personalizate care ar putea fi de interes mai mare pentru dumneavoastră.</li>
            </ul>
          </div>

          <div className="text-700 text-xl mb-6 text-justify line-height-3 pmarg">
            În unele cazuri, vom prelucra datele dumneavoastră cu caracter personal doar cu consimțământul dumneavoastră (de exemplu, în cazuri de publicitate și marketing). În aceste cazuri, vă vom solicita separat consimțământul dumneavoastră, în mod transparent, atunci când furnizați datele dumneavoastră cu caracter personal. Ulterior, vă puteți retrage consimțământul, în orice moment, trimițând o solicitare la adresa de e-mail office@asisco.ro. Retragerea consimțământului nu va afecta legalitatea prelucrării care a avut loc înainte de retragerea acestuia.
            <br />
            Atunci când solicităm date cu caracter personal pentru a respecta obligații legale sau contractuale, furnizarea acestor date cu caracter personal de către dumneavoastră este obligatorie. Acest lucru înseamnă că, dacă astfel de date cu caracter personal nu sunt furnizate, nu vom putea să gestionăm relațiile contractuale sau să respectăm obligațiile legale ce ne sunt impuse. În toate celelalte cazuri, furnizarea de date cu caracter personal este opțională și nu aveți obligația de a le furniza.
            <br />
            Putem prelucra datele dumneavoastră cu caracter personal, precum datele de identificare, datele de contact și adresa de reședință, în scopul posibilei exercitări a drepturilor sau a reclamațiilor noastre împotriva dumneavoastră în viitor. Această prelucrare se bazează pe interesul nostru legitim, fiind necesar să ne exercităm drepturile în cazul unor eventuale litigii.
            <br />
          </div>

          <div className="font-bold text-2xl text-900 text-justify line-height-3 pmarg">
            5. LEGALITATEA PRELUCRĂRII DATELOR CU CARACTER PERSONAL
            <br />
          </div>
          <div className="text-700 text-xl mb-6 text-justify line-height-3 pmarg">
            Prelucrarea este legală, conform prevederilor art. 6 din Regulamentul nr. 679/2016, numai dacă și în măsura în care se aplică cel puțin una dintre următoarele condiții:
            <ul className="text-700 text-xl mb-6 blt-marg black-bullet">
              <li>persoana vizată și-a dat consimțământul pentru prelucrarea datelor sale cu caracter personal pentru unul sau mai multe scopuri specifice;</li>
              <li>prelucrarea este necesară pentru executarea unui contract la care persoana vizată este parte sau pentru a face demersuri la cererea persoanei vizate înainte de încheierea unui contract;</li>
              <li>prelucrarea este necesară în vederea îndeplinirii unei obligații legale care îi revine operatorului;</li>
              <li>prelucrarea este necesară pentru a proteja interesele vitale ale persoanei vizate sau ale altei persoane fizice;</li>
              <li>prelucrarea este necesară pentru îndeplinirea unei sarcini care servește unui interes public sau care rezultă din exercitarea autorității publice cu care este investit operatorul.</li>
            </ul>
          </div>

          <div className="font-bold text-2xl text-900 text-justify line-height-3 pmarg">
            6. DEZVĂLUIREA ȘI TRANSFERUL DATELOR CU CARACTER PERSONAL
            <br />
          </div>
          <div className="text-700 text-xl mb-6 text-justify line-height-3 pmarg">
            Asisco Tehnic SRL se străduiește să aplice garanții adecvate pentru a proteja confidențialitatea și securitatea datelor dumneavoastră personale și pentru a le utiliza numai în conformitate cu relația dumneavoastră cu instituția noastră și cu practicile descrise în această Politică de Confidențialitate.
            <br />
            În măsura permisă de legile aplicabile în materie de protecție a datelor, putem divulga datele dumneavoastră personale unor parteneri, auditori, agenții, autorități de supraveghere sau alți furnizori de servicii externe pentru a ne îndeplini obligațiile contractuale. Vom indica în mod clar pentru dumneavoastră fiecare destinatar de date dacă este bine cunoscut în momentul inițierii procesării.
            <br />
            Putem, de asemenea, dezvălui datele dumneavoastră personale:
            <ul className="text-700 text-xl mb-6 blt-marg black-bullet">
              <li>în măsura în care suntem obligați să facem acest lucru prin lege;</li>
              <li>în legătură cu orice procedură judiciară în desfășurare sau potențială;</li>
              <li>pentru a stabili, exercita sau apăra drepturile legale (inclusiv furnizarea de informații altor persoane în scopul prevenirii fraudei).</li>
            </ul>
          </div>

          <div className="font-bold text-2xl text-900 text-justify line-height-3 pmarg">
            7. DREPTURILE DUMNEAVOASTRĂ
            <br />
          </div>
          <div className="text-700 text-xl mb-6 text-justify line-height-3 pmarg">
            În legătură cu prelucrarea datelor cu caracter personal și în baza prevederilor Regulamentului nr. 679/2016 privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter personal și privind libera circulație a acestor date vă puteți exercita oricare dintre următoarele drepturi:
            <br />
            <ul className="text-700 text-xl mb-6 blt-marg black-bullet">
              <li><span className="bd-it-font">dreptul la informare</span>: poți solicita informații privind activitățile de prelucrare a datelor tale personale;
              </li>
              <li><span className="bd-it-font">dreptul la rectificare</span>: poți rectifica datele personale inexacte sau le poți completa;
              </li>
              <li><span className="bd-it-font">dreptul la ștergerea datelor (dreptul de a fi uitat)</span>: poți obține ștergerea datelor, în cazul în care prelucrarea acestora nu a fost legală sau în alte cazuri prevăzute de lege;
              </li>
              <li><span className="bd-it-font">dreptul la restricționarea prelucrării</span>: poți solicita restricționarea prelucrării în cazul în care contești exactitatea datelor, precum și în alte cazuri prevăzute de lege;
              </li>
              <li><span className="bd-it-font">dreptul la portabilitatea datelor</span>: poți primi, în anumite condiții, datele personale pe care ni le-ai furnizat, într-un format care poate fi citit automat sau poți solicita ca respectivele date să fie transmise altui operator;
              </li>
              <li><span className="bd-it-font">dreptul de a depune plângere</span>: poți depune plângere față de modalitatea de prelucrare a datelor personale la Autoritatea Națională de Supraveghere a Prelucrării Datelor cu Caracter Personal.
              </li>
            </ul>
            Vă rugăm să aveți în vedere faptul că drepturile dumneavoastră descrise mai sus pot fi limitate în anumite situații și fac obiectul legilor și reglementărilor aplicabile privind protecția datelor cu caracter personal. De exemplu, dreptul de a vă opune prelucrării datelor dumneavoastră cu caracter personal poate fi limitat în cazul în care putem demonstra că avem motive legitime convingătoare pentru a prelucra datele dumneavoastră cu caracter personal care prevalează față de interesele dumneavoastră. Va trebui să vă dovediți identitatea și să ne oferiți alte detalii care să ne ajute să răspundem cererii dumneavoastră.
            <br />
            Pentru exercitarea acestor drepturi, vă puteți adresa cu o cerere scrisă, datată și semnată, responsabilului cu protecția datelor cu caracter personal. Cererea se poate depune personal la sediul social al Asisco Tehnic SRL, poate fi transmisă la adresa de e-mail office@asisco.ro sau poate fi trimisă prin poștă la sediul social din localitatea Brașov, st. Zizinului nr. 10, bloc 38, etaj 7, apt. 30, cod poștal 500414, județ Brașov.
            <br />
            De asemenea, vă este recunoscut dreptul de a depune plângere la Autoritatea Națională de Supraveghere a Prelucrării Datelor cu Caracter Personal (A.N.S.P.D.C.P.) precum și dreptul la o cale de atac eficientă.
            <br /><br />
            Adresa de contact Autoritatea Națională de Supraveghere a Prelucrării Datelor cu Caracter Personal (A.N.S.P.D.C.P.):
            <br />
            București, b-dul G-ral. Gheorghe Magheru nr. 28-30, sector 1, cod poștal 010336
            <br />
            Pagina de internet: http://www.dataprotection.ro
            <br />
            Adresa de e-mail: anspdcp@dataprotection.ro
            <br />
            Telefon:+40 318 059 211; +40 318 059 212
            <br />
            Fax: +40 318 059 602
            <br />
          </div>

          <div className="font-bold text-2xl text-900 text-justify line-height-3 pmarg">
            8. PĂSTRAREA DATELOR CU CARACTER PERSONAL
            <br />
          </div>
          <div className="text-700 text-xl mb-6 text-justify line-height-3 pmarg">
            Vom păstra datele dumneavoastră cu caracter personal pentru perioada necesară pentru a îndeplini scopurile enumerate în prezenta Politică sau pentru perioada impusă de legislația națională aplicabilă, în conformitate cu perioadele minime legale de păstrare aplicabile și/sau atât cât este necesar pentru a ne exercita drepturile legitime (și drepturile legitime ale altor persoane).
            <br />
            În cazul în care sunteți Client sau Partener Comercial, vă vom păstra datele dumneavoastră cu caracter personal pe perioada exercitării relației contractuale cu dumneavoastră.
            <br />
            În cazul în care avem o relație comercială continuă cu dumneavoastră în calitate de Partener comercial vom continua să păstram aceste date cu caracter personal până la încetarea relației noastre comerciale și pentru perioada minimă de păstrare impusă de lege.
            <br />
            În cazul în care prelucrăm datele dumneavoastră cu caracter personal în temeiul consimțământului dumneavoastră, aceste date cu caracter personal vor fi prelucrate numai pentru perioada prevăzută prin consimțământul dumneavoastră, cu excepția cazului în care vă retrageți sau limitați consimțământul înainte de expirarea acestei perioade. În astfel de cazuri, vom înceta prelucrarea datelor cu caracter personal respective în scopurile relevante, sub rezerva oricărei obligații legale de a prelucra aceste date cu caracter personal și/sau a nevoii noastre de a prelucra aceste date cu caracter personal în scopul exercitării drepturilor noastre legitime (inclusiv a drepturilor legitime ale altor persoane).
            <br />
            Fără a aduce atingere celorlalte prevederi ale acestei secțiuni, vom păstra informații și documente (inclusiv documente electronice) care conțin date cu caracter personal:
            <ul className="text-700 text-xl mb-6 blt-marg black-bullet">
              <li>în măsura în care suntem obligați să facem acest lucru prin lege;</li>
              <li>în cazul în care informațiile / documentele sunt relevante pentru orice procedură judiciară în curs sau potențială;</li>
              <li>pentru a stabili, exercita sau apăra drepturile noaste legale (inclusiv furnizarea de informații altor persoane în scopul prevenirii fraudei).</li>
            </ul>
          </div>

          <div className="font-bold text-2xl text-900 text-justify line-height-3 pmarg">
            9. SECURITATEA DATELOR PERSONALE
            <br />
          </div>
          <div className="text-700 text-xl mb-6 text-justify line-height-3 pmarg">
            Pentru a vă proteja confidențialitatea datelor și a informațiilor personale pe care le transmiteți prin utilizarea acestui site web, vom menține garanții fizice, tehnice și administrative. Actualizăm și testăm tehnologia noastră de securitate în mod continuu. Restricționăm accesul la datele dumneavoastră personale doar acelor angajați care trebuie să știe acele informații pentru a vă oferi beneficii sau servicii. În plus, instruim angajații noștri cu privire la importanța confidențialității și menținerea confidențialității și securității informațiilor dumneavoastră. Dacă apare o scurgere de date cu caracter personal, vom face totul pentru a o elimina și pentru a evalua un nivel de risc legat de scurgere în conformitate cu politica noastră privind încălcarea datelor cu caracter personal. În cazul în care se constată că scurgerea poate duce la vătămări fizice, materiale sau nemateriale pentru dumneavoastră (de exemplu, discriminare, furt de identitate, fraudă sau pierdere financiară), vă vom contacta fără întârzieri nejustificate, cu excepția cazului în care legea prevede altfel. Toate demersurile noastre vor fi luate în deplină cooperare cu autoritatea competentă de supraveghere.
            <br />
          </div>

          <div className="font-bold text-2xl text-900 text-justify line-height-3 pmarg">
            10. MODIFICĂRI ALE POLITICII PRIVIND CONFIDENȚIALITATEA
            <br />
          </div>
          <div className="text-700 text-xl mb-6 text-justify line-height-3 pmarg">
            Este posibil să actualizăm din când în când acest document de informare prin publicarea unei noi versiuni pe acest site web.
            <br />
            Având în vedere că legile privind protecția datelor cu caracter personal, interpretările organelor de stat, recomandările autorităților de supraveghere se schimbă și se îmbunătățesc din când în când, se așteaptă ca această notificare privind confidențialitatea și condițiile de utilizare să se schimbe și ea. Ne rezervăm dreptul de a modifica în orice moment, din orice motiv și fără notificare, acest document de informare. Orice modificare a politicii noastre de confidențialitate va fi afișată pe această pagină, astfel încât să fiți conștienți de politicile noastre. Dispozițiile conținute în prezentul document înlocuiesc toate anunțurile sau declarațiile anterioare privind practicile noastre de confidențialitate și termenii și condițiile care guvernează utilizarea acestui site web.
            <br />
          </div>

          <div className="font-bold text-2xl text-900 text-justify line-height-3 pmarg">
            11. SITE-URILE TERȚE PĂRȚI
            <br />
          </div>
          <div className="text-700 text-xl mb-6 text-justify line-height-3 pmarg">
            Acest site poate include hyperlinkuri și detalii despre site-urile web ale unor terțe părți. Nu avem niciun control și nu suntem responsabili pentru politicile și practicile de confidențialitate ale unor terțe părți.
            <br />
          </div>

          <div className="font-bold text-2xl text-900 text-justify line-height-3 pmarg">
            12. COLECTAREA ȘI STOCAREA ANUMITOR INFORMAȚII PE SITE
            <br />
          </div>
          <div className="text-700 text-xl mb-6 text-justify line-height-3 pmarg">
            Așa cum este valabil pentru cele mai multe dintre site-urile web, acest site colectează automat anumite informații și le stochează în fișierele jurnal. Acestea includ adresele Protocolului Internet (IP), locația geografică a computerului sau a dispozitivului, tipul de browser, sistemul de operare și alte informații despre vizitarea acestui site, de exemplu, paginile vizualizate. Aceste informații sunt utilizate pentru a îmbunătăți acest site web și, datorită acestei îmbunătățiri constante, pentru a servi mai bine utilizatorilor noștri. Adresa dumneavoastră IP poate fi, de asemenea, utilizată pentru a diagnostica problemele cu serverul nostru, pentru a administra acest site, pentru a analiza tendințele, pentru a urmări navigarea unui utilizator pe site și pentru a colecta informații demografice, pentru a ne ajuta să înțelegem preferințele vizitatorilor și nevoile acestora.
            <br />
          </div>

          <div className="font-bold text-2xl text-900 text-justify line-height-3 pmarg">
            13. ÎNTREBĂRI, NELĂMURIRI, RECLAMAȚII
            <br />
          </div>
          <div className="text-700 text-xl mb-6 text-justify line-height-3 pmarg">
            Dacă aveți întrebări sau aveți nelămuriri cu privire la confidențialitatea datelor și a informațiilor personale sau dacă doriți să vă executați drepturile sau să faceți o sesizare cu privire la o posibilă încălcare a normelor privind confidențialitatea, vă rugăm să ne contactați prin trimiterea unui e-mail către responsabilul pentru protecția datelor la office@asisco.ro.
            <br />
          </div>

          <div className="text-700 text-xl mb-6 text-justify line-height-3 it-font pmarg">
            Ultima actualizare: 01 septembrie 2024.
            <br /><br /><br />
          </div>


        </div>



      </main>

      <Footer />
    </div>
  );
}