const onlyDigits = (string) => [...string].every((c) => "0123456789".includes(c));

const isEmptyString = (value) => {
  if (null === value || undefined === value || 0 === value.length) {
    return true;
  }
  return false;
};

const validateNumber = (value, minValue, maxValue) => {
  if (value.length >= minValue && value.length <= maxValue && onlyDigits(value)) {
    return true;
  }
  return false;
};

const validateString = (value, minValue, maxValue) => {
  if (value.length >= minValue && value.length <= maxValue) {
    return true;
  }
  return false;
};

const validateMinMaxText = (min, max) => {
  if (0 === min) {
    return " poate avea maxim " + max + " caractere!";
  } else if (1 === min) {
    return " poate avea minim " + min + " caracter şi maxim " + max + " caractere!";
  } else {
    return " poate avea minim " + min + " caractere şi maxim " + max + " caractere!";
  }
};

const validateMinMaxTextForNumber = (min, max) => {
  if (0 === min) {
    return " poate conţine doar cifre şi poate avea maxim " + max + " caractere!";
  } else if (1 === min) {
    return " poate conţine doar cifre şi poate avea minim " + min + " caracter şi maxim " + max + " caractere!";
  } else {
    return " poate conţine doar cifre şi poate avea minim " + min + " caractere şi maxim " + max + " caractere!";
  }
};

const FIELD_SIZE = {
  AddressDetails1Max: 300,
  AddressDetails2Max: 150,
  BankAccountMax: 30,
  BankLocationMax: 100,
  BankNameMax: 100,
  CncirContractNumberMax: 50,
  CompanyCodeMin: 2,
  CompanyCodeMax: 10,
  CompanyFiscalCodeMax: 20,
  CompanyNameMin: 1,
  CompanyNameMax: 200,
  CompanyRegistrationNumberMax: 50,
  CompanyShortNameMax: 45,
  CountyMax: 100,
  EmailMax: 200,
  EquipmentDescriptionMax: 200,
  EquipmentManufacturingSeriesMax: 50,
  EquipmentManufacturingYearMax: 5,
  ExternalTownMax: 100,
  FaxMax: 20,
  FirstNameMin: 1,
  FirstNameMax: 150,
  IdCardNumberMax: 6,
  IdCardSerialMax: 2,
  IdentifierMax: 13,
  IdIdentityMax: 50,
  IdPassportMax: 50,
  InspectionDateMax: 25,
  LastNameMin: 1,
  LastNameMax: 300,
  MaxMassMin: 1,
  MaxMassMax: 10,
  One: 1,
  OperatorPermitNumberMax: 50,
  OperatorRegistrationNumberMax: 50,
  OtherAddressDetailsMax: 100,
  PersonRoleMax: 100,
  PhoneMax: 20,
  PostalCodeMax: 25,
  PVMax: 50,
  RsvtiOperatorCompanyNameMax: 200,
  RsvtiOperatorCompanyPermitNumberMax: 100,
  RsvtiOperatorPermitNumberMax: 100,
  SignerOwnerRoleMin: 1,
  SignerOwnerRoleMax: 100,
  TownMax: 100,
  WorkerCertificateNumberMax: 25,
  WorkerCertificateSeriesMax: 25,
  Zero: 0,
};

export { FIELD_SIZE, isEmptyString, validateString, validateMinMaxText, validateNumber, validateMinMaxTextForNumber };
