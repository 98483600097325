import "../../static/css/pageLayout.css";

import axios from "axios";
import { Dialog } from "primereact/dialog";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { BASE_URL, ICONS, STRING_CONST, dialogHeaderElement, dialogFooterContent } from "../../common/globals";
import { FIELD_SIZE, isEmptyString, validateNumber, validateMinMaxTextForNumber } from "../../common/validations";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import ProcessFormErrors from "../../components/ProcessFormErrors";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function WorkerGenerateDocsWorkerTypeSelection() {
  const workerCreateSessionURL = BASE_URL + "workerdocuments/createsession";
  const workerCreateSessionConfigURL = BASE_URL + "workerdocuments/config/createsession";
  const checkIfAllowedURL = BASE_URL + "workerdocuments/classification/allowed";

  const [classification, setClassification] = useState([]);
  const [ddOptionsWorkerTypes, setDdOptionsWorkerTypes] = useState([]);
  const [ddOptionsEquipmentClassifications, setDdOptionsEquipmentClassifications] = useState([]);
  const [ddOptionsEquipmentGroups, setDdOptionsEquipmentGroups] = useState([]);
  const [ddOptionsEquipmentTypes, setDdOptionsEquipmentTypes] = useState([]);
  const [showSecurityDialog, setShowSecurityDialog] = useState(null);
  const [securityMesage, setSecurityMesage] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);

  const navigate = useNavigate();

  const { auth } = useAuth();

  const uuids = JSON.parse(sessionStorage.getItem("workerUuids"));

  const [values, setValues] = useState({
    workerType: "",
    equipmentClassification: "",
    equipmentGroup: "",
    equipmentType: "",
    maxMass: "",
  });

  const inputs = [
    {
      id: 0,
      name: "workerType",
      type: "select",
      placeholder: "Tip deservent auxiliar",
      label: "Tip deservent auxiliar",
    },
    {
      id: 1,
      name: "equipmentClassification",
      type: "select",
      placeholder: "Clasificare echipament",
      label: "Clasificare echipament",
    },
    {
      id: 2,
      name: "equipmentGroup",
      type: "select",
      placeholder: "Grupă echipament",
      label: "Grupă echipament",
    },
    {
      id: 3,
      name: "equipmentType",
      type: "select",
      placeholder: "Tip echipament",
      label: "Tip echipament",
    },
    {
      id: 4,
      name: "maxMass",
      type: "text",
      placeholder: "Sarcina maximă",
      label: "Sarcina maximă",
    },
  ];

  const FIELD = {
    workerType: 0,
    equipmentClassification: 1,
    equipmentGroup: 2,
    equipmentType: 3,
    maxMass: 4,
  };

  useEffect(() => {
    axios
      .post(
        workerCreateSessionConfigURL,
        {},
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setClassification(response.data);
        for (const listItem of response.data) {
          if ("0#0" === listItem.parentUuid) {
            setDdOptionsWorkerTypes(listItem.values);
            break;
          }
        }
      })
      .catch((error) => {
        console.log(error);
        alert("Tipurile de deservenţi auxiliari nu au putut fi citite!");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeWorkerType = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value, equipmentClassification: "", equipmentGroup: "", equipmentType: "" });
    setDdOptionsEquipmentClassifications([]);
    setDdOptionsEquipmentGroups([]);
    setDdOptionsEquipmentTypes([]);
    for (const listItem of classification) {
      if (e.target.value === listItem.parentUuid) {
        setDdOptionsEquipmentClassifications(listItem.values);
        break;
      }
    }
  };

  const onChangeEquipmentClassification = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value, equipmentGroup: "", equipmentType: "" });
    setDdOptionsEquipmentGroups([]);
    setDdOptionsEquipmentTypes([]);
    for (const listItem of classification) {
      if (e.target.value === listItem.parentUuid) {
        if ("451617f2-57e1-11ee-b39a-902b346c1788" === listItem.typeUuid) {
          // if item type is group
          setDdOptionsEquipmentGroups(listItem.values);
          break;
        } else {
          setDdOptionsEquipmentTypes(listItem.values);
          break;
        }
      }
    }
  };

  const onChangeEquipmentGroup = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value, equipmentType: "" });
    setDdOptionsEquipmentTypes([]);
    for (const listItem of classification) {
      if (e.target.value === listItem.parentUuid) {
        setDdOptionsEquipmentTypes(listItem.values);
        break;
      }
    }
  };

  const onChangeEquipmentType = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleGenerate = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    axios
      .post(
        checkIfAllowedURL,
        {
          typeUuid: values.workerType,
          equipmentClassificationUuid: values.equipmentClassification,
          equipmentGroupUuid: values.equipmentGroup,
          equipmentTypeUuid: values.equipmentType,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        if (null !== response && null !== response.data) {
          if ("OK" === response.data) {
            createSession();
          } else {
            setSecurityMesage(response.data);
            setShowSecurityDialog(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        alert("Certificarea deservenţilor auxiliari nu a putut fi pornită!");
      });
  };

  const createSession = async () => {
    axios
      .post(
        workerCreateSessionURL,
        {
          uuids: uuids,
          typeUuid: values.workerType,
          equipmentClassificationUuid: values.equipmentClassification,
          equipmentGroupUuid: values.equipmentGroup,
          equipmentTypeUuid: values.equipmentType,
          maxMass: values.maxMass,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        sessionStorage.setItem("workerSessionUuid", response.data);
        navigate("/worker/generatedocsinstructions");
      })
      .catch((error) => {
        console.log(error);
        alert("Certificarea deservenţilor auxiliari nu a putut fi pornită!");
      });
  };

  const validateForm = () => {
    let result = true;
    let data = [];
    if (isEmptyString(values.workerType)) {
      data.push({
        id: inputs[FIELD.workerType].id,
        value: inputs[FIELD.workerType].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_WORKER_DOCUMENTS,
      });
      result = false;
    }
    if (isEmptyString(values.equipmentClassification) && null !== ddOptionsEquipmentClassifications && ddOptionsEquipmentClassifications.length > 0) {
      data.push({
        id: inputs[FIELD.equipmentClassification].id,
        value: inputs[FIELD.equipmentClassification].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_WORKER_DOCUMENTS,
      });
      result = false;
    }
    if (isEmptyString(values.equipmentGroup) && null !== ddOptionsEquipmentGroups && ddOptionsEquipmentGroups.length > 0) {
      data.push({
        id: inputs[FIELD.equipmentGroup].id,
        value: inputs[FIELD.equipmentGroup].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_WORKER_DOCUMENTS,
      });
      result = false;
    }
    if (isEmptyString(values.equipmentType) && null !== ddOptionsEquipmentTypes && ddOptionsEquipmentTypes.length > 0) {
      data.push({
        id: inputs[FIELD.equipmentType].id,
        value: inputs[FIELD.equipmentType].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_WORKER_DOCUMENTS,
      });
      result = false;
    }
    if (!validateNumber(values.maxMass, FIELD_SIZE.MaxMassMin, FIELD_SIZE.MaxMassMax)) {
      data.push({
        id: inputs[FIELD.maxMass].id,
        value: inputs[FIELD.maxMass].label + validateMinMaxTextForNumber(FIELD_SIZE.MaxMassMin, FIELD_SIZE.MaxMassMax),
      });
      result = false;
    }
    setValidationErrors(data);
    return result;
  };

  return (
    <div className="clientArea">
      <Sidebar />
      <form class="formArea">
        <button class="buttonClassGreen">Generare documente des. auxiliar</button>
        <div class="spaceDiv" />

        <div class="rowdouble">
          <div class="column" id={"internalLink" + inputs[FIELD.workerType].id}>
            <FormSelect
              key={inputs[FIELD.workerType].id}
              name={inputs[FIELD.workerType].name}
              label={inputs[FIELD.workerType].label}
              value={values[inputs[FIELD.workerType].name]}
              ddOptions={ddOptionsWorkerTypes}
              onChange={onChangeWorkerType}
            />
          </div>
        </div>
        <div class="rowdouble">
          <div class="column" id={"internalLink" + inputs[FIELD.equipmentClassification].id}>
            <FormSelect
              key={inputs[FIELD.equipmentClassification].id}
              name={inputs[FIELD.equipmentClassification].name}
              label={inputs[FIELD.equipmentClassification].label}
              value={values[inputs[FIELD.equipmentClassification].name]}
              ddOptions={ddOptionsEquipmentClassifications}
              onChange={onChangeEquipmentClassification}
            />
          </div>
        </div>

        {values.workerType === "044d903c-6abe-11ef-bfb9-74563c1b28c3#28b087a4-5a10-11ee-8699-902b346c1788" ||
        values.equipmentClassification === "08e34c61-6ac3-11ef-bfb9-74563c1b28c3#ae063ef5-5a11-11ee-8699-902b346c1788" ? (
          <>
            {values.workerType === "044d903c-6abe-11ef-bfb9-74563c1b28c3#28b087a4-5a10-11ee-8699-902b346c1788" ? (
              <div class="rowdouble">
                <div class="column" id={"internalLink" + inputs[FIELD.equipmentGroup].id}>
                  <FormSelect
                    key={inputs[FIELD.equipmentGroup].id}
                    name={inputs[FIELD.equipmentGroup].name}
                    label={inputs[FIELD.equipmentGroup].label}
                    value={values[inputs[FIELD.equipmentGroup].name]}
                    ddOptions={ddOptionsEquipmentGroups}
                    onChange={onChangeEquipmentGroup}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            {values.equipmentClassification === "08e34c61-6ac3-11ef-bfb9-74563c1b28c3#ae063ef5-5a11-11ee-8699-902b346c1788" ? (
              <div class="rowdouble">
                <div class="column" id={"internalLink" + inputs[FIELD.equipmentType].id}>
                  <FormSelect
                    key={inputs[FIELD.equipmentType].id}
                    name={inputs[FIELD.equipmentType].name}
                    label={inputs[FIELD.equipmentType].label}
                    value={values[inputs[FIELD.equipmentType].name]}
                    ddOptions={ddOptionsEquipmentTypes}
                    onChange={onChangeEquipmentType}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
        <div class="rowdouble">
          <div class="column" id={"internalLink" + inputs[FIELD.maxMass].id}>
            <FormInput key={inputs[FIELD.maxMass].id} {...inputs[FIELD.maxMass]} value={values[inputs[FIELD.maxMass].name]} onChange={onChange} />
          </div>
        </div>
        {validationErrors.length > 0 ? <ProcessFormErrors validationErrors={validationErrors} /> : null}
        <div class="rowdoublecentered">
          <div class="columnbutton">
            <img
              class="imgtoolbar"
              src={require("../../static/images/buttons/" + ICONS.WorkerGenerateWordDocuments)}
              alt="Generare documentație instruire"
              title="Generare documentație instruire"
              onClick={handleGenerate}
            />
          </div>
        </div>
      </form>
      <Dialog
        visible={showSecurityDialog}
        modal
        header={dialogHeaderElement("Atenţie")}
        footer={dialogFooterContent("OK", () => setShowSecurityDialog(false))}
        style={{ width: "450px" }}
        onHide={() => setShowSecurityDialog(false)}
      >
        <p className="m-0">{securityMesage}</p>
      </Dialog>
    </div>
  );
}
