import "../static/css/priceheader.css";
import "../static/css/price.css";
import "../static/css/pricefooter.css";

import "primeflex/primeflex.css";

import React from "react";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

export default function IpOperators() {
    return (
        <div className="IpOperators">
            <Header />

            <main>
                <div className="surface-0">
                    <br />
                    <br />
                    <div>
                        <img alt="" className="img-all" src={require("../static/images/1920x400/bckg_1920x400_017.jpg")} />
                    </div>
                    <br />
                </div>

                <div>
                    <br />
                    <br />
                </div>

                <div className="surface-0">

                    <div className="text-900 font-bold text-6xl mb-4 text-left pmarg">Deservenți instalații sub presiune</div>

                    <div className="pmarg">
                        <div className="card">
                            <Accordion activeIndex={0}>
                                <AccordionTab header="Fochist">
                                    <p className="m-0">

                                        <div className="surface-0">
                                            <div className="grid">
                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Clasificare cazane</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/hPaO_t38-go?si=gosGjwYmyyeZYBaA"
                                                                    title="Clasificare cazane"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Obligații fochist (PT C1-2010)</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/nj-F4gigfmk?si=egkjgGqgHc9LKHoi"
                                                                    title="Obligații fochist (PT C1-2010)"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Obligații fochist (PT C9-2010)</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/X87S6kZIXCQ?si=E6vUDmegss9fbrEg"
                                                                    title="Obligații fochist (PT C9-2010)"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>

                                        <div className="surface-0">
                                            <div className="grid">
                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Legislație cazane</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/Fbxh9Jc8iW4?si=P3Yuahm5e2GRpO9S"
                                                                    title="Legislație cazane"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>


                                    </p>
                                </AccordionTab>
                                <AccordionTab header="Automatist pentru supraveghere și întreținere cazane">
                                    <p className="m-0">



                                        <div>
                                            <br />
                                            <br />
                                        </div>


                                    </p>
                                </AccordionTab>
                                <AccordionTab header="Îmbuteliator fluide sub presiune">
                                    <p className="m-0">



                                        <div>
                                            <br />
                                            <br />
                                        </div>


                                    </p>
                                </AccordionTab>
                                <AccordionTab header="Laborant operator centrale termice">
                                    <p className="m-0">


                                        <div>
                                            <br />
                                            <br />
                                        </div>

                                    </p>
                                </AccordionTab>
                                <AccordionTab header="Operator umplere recipiente GPL">
                                    <p className="m-0">


                                        <div>
                                            <br />
                                            <br />
                                        </div>

                                    </p>
                                </AccordionTab>
                            </Accordion>
                        </div>
                    </div>


                    <div>
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>


                </div>




            </main>
            <div>
                <br />
                <br />
            </div>

            <Footer />
        </div>
    );
}
