import "../static/css/priceheader.css";
import "../static/css/price.css";
import "../static/css/pricefooter.css";

import "primeflex/primeflex.css";

import React from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

export default function Prices() {
  return (
    <div className="Prices">
      <Header />

      <main>
        <div className="surface-0">
          <br />
          <br />
          <div>
            <img alt="" className="img-all" src={require("../static/images/1920x400/bckg_1920x400_002.jpg")} />
          </div>
          <br />

          <div className="text-900 font-bold text-6xl mb-4 text-center">Prețuri pachete de bază</div>
          <div className="text-700 text-xl mb-6 text-center line-height-3">
            Aceste prețuri sunt valabile pentru utilizatorii care dețin echipamente care se încadrează doar la anumite prescripții tehnice ISCIR <br />
            (PT instalații de ridicat: R1-2010; R2-2010; R8-2010; R13-2003; R15-2003; R17-2003) <br />
            (PT instalații sub presiune: A1-2010; C1-2010; C4-2010; C6-2010; C9-2010) <br />
            <div className="font-note">
              <span className="bd-it-font">
                Notă!
              </span>
              <span>
                &nbsp; Pentru pachetul gratuit se vor putea utiliza doar echipamentele care se încadrează la următoarele prescripții tehnice: A1-2010; C4-2010; C9-2010; R1-2010; R2-2010. <br />
              </span>
            </div>
          </div>

          <div className="grid">
            <div className="col-12 lg:col-4">
              <div className="p-3 h-full">
                <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                  <div className="text-900 font-medium text-xl mb-2">Gratuit</div>
                  <div className="text-600">Descriere pachet</div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">0 lei / </span>
                    <span className="ml-2 font-medium text-600">an</span>
                  </div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />

                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">0 lei / </span>
                    <span className="ml-2 font-medium text-600">lună</span>
                  </div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <ul className="list-none p-0 m-0 flex-grow-1">
                    <li className="flex align-items-center mb-3">
                      <div class="ft-icon left">
                        <img alt="" src={require("../static/images/24x24/company_24x24.png")} />
                      </div>
                      <span>puncte de lucru: maxim 1</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <div class="ft-icon left">
                        <img alt="" src={require("../static/images/24x24/equipment_24x24.png")} />
                      </div>
                      <span>echipamente: maxim 5</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <div class="ft-icon left">
                        <img alt="" src={require("../static/images/24x24/worker_24x24.png")} />
                      </div>
                      <span>personal ISCIR: maxim 5</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <div class="ft-icon left">
                        <img alt="" src={require("../static/images/24x24/data_24x24.png")} />
                      </div>
                      <span>spațiu: maxim 50 MB </span>
                    </li>
                  </ul>
                  <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />
                  <div className="btn-lnk-blue">
                    <Link to={"/public/prices"}>
                      <span className=" p-3 w-full mt-auto txt-ctr">Aplică acum</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 lg:col-4">
              <div className="p-3 h-full">
                <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                  <div className="text-900 font-medium text-xl mb-2">Bază 150</div>
                  <div className="text-600">Descriere pachet</div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">... euro + TVA / </span>
                    <span className="ml-2 font-medium text-600">an</span>
                  </div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">... euro + TVA / </span>
                    <span className="ml-2 font-medium text-600">lună</span>
                  </div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <ul className="list-none p-0 m-0 flex-grow-1">
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/company_24x24.png")} />
                        </div>
                      </i>
                      <span>puncte de lucru: maxim 100</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/equipment_24x24.png")} />
                        </div>
                      </i>
                      <span>echipamente: maxim 100</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/worker_24x24.png")} />
                        </div>
                      </i>
                      <span>personal ISCIR: maxim 200</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/data_24x24.png")} />
                        </div>
                      </i>
                      <span>spațiu: maxim 2.5 GB</span>
                    </li>
                  </ul>
                  <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="btn-lnk-blue">
                    <Link to={"/public/prices"}>
                      <span className=" p-3 w-full mt-auto txt-ctr">Aplică acum</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 lg:col-4">
              <div className="p-3 h-full">
                <div className="shadow-2 p-3 flex flex-column" style={{ borderRadius: "6px" }}>
                  <div className="text-900 font-medium text-xl mb-2">Bază 250</div>
                  <div className="text-600">Descriere pachet</div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">... euro + TVA / </span>
                    <span className="ml-2 font-medium text-600">an</span>
                  </div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">... euro + TVA / </span>
                    <span className="ml-2 font-medium text-600">lună</span>
                  </div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <ul className="list-none p-0 m-0 flex-grow-1">
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/company_24x24.png")} />
                        </div>
                      </i>
                      <span>puncte de lucru: maxim 200</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/equipment_24x24.png")} />
                        </div>
                      </i>
                      <span>echipamente: maxim 200</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/worker_24x24.png")} />
                        </div>
                      </i>
                      <span>personal ISCIR: maxim 400</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/data_24x24.png")} />
                        </div>
                      </i>
                      <span>spațiu: maxim 5 GB</span>
                    </li>
                  </ul>
                  <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="btn-lnk-blue">
                    <Link to={"/public/prices"}>
                      <span className=" p-3 w-full mt-auto txt-ctr">Aplică acum</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <br />
          <br />
          <br />
          <br />
        </div>

        <div className="surface-0">
          <div className="text-900 font-bold text-6xl mb-4 text-center">Prețuri pachete complete</div>
          <div className="text-700 text-xl mb-6 text-center line-height-3">
            Aceste prețuri sunt valabile pentru utilizatorii care folosesc toate prescripțiile tehnice ISCIR <br />
            (toate prescripțiile tehnice ISCIR pentru instalațiile de ridicat și toate prescripțiile tehnice ISCIR pentru instalațiile sub presiune).
          </div>

          <div className="grid">
            <div className="col-12 lg:col-4">
              <div className="p-3 h-full">
                <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                  <div className="text-900 font-medium text-xl mb-2">Personalizat</div>
                  <div className="text-600">Descriere pachet</div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">... euro + TVA / </span>
                    <span className="ml-2 font-medium text-600">an</span>
                  </div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />

                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">... euro + TVA / </span>
                    <span className="ml-2 font-medium text-600">lună</span>
                  </div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <ul className="list-none p-0 m-0 flex-grow-1">
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/company_24x24.png")} />
                        </div>
                      </i>
                      <span>puncte de lucru: conform cerințe client</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/equipment_24x24.png")} />
                        </div>
                      </i>
                      <span>echipamente: conform cerințe client</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/worker_24x24.png")} />
                        </div>
                      </i>
                      <span>personal ISCIR: conform cerințe client</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/data_24x24.png")} />
                        </div>
                      </i>
                      <span>spațiu: conform cerințe client </span>
                    </li>
                  </ul>
                  <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />
                  <div className="btn-lnk-blue">
                    <Link to={"/public/prices"}>
                      <span className=" p-3 w-full mt-auto txt-ctr">Aplică acum</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 lg:col-4">
              <div className="p-3 h-full">
                <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                  <div className="text-900 font-medium text-xl mb-2">Complet 200</div>
                  <div className="text-600">Descriere pachet</div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">... euro + TVA / </span>
                    <span className="ml-2 font-medium text-600">an</span>
                  </div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">... euro + TVA / </span>
                    <span className="ml-2 font-medium text-600">lună</span>
                  </div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <ul className="list-none p-0 m-0 flex-grow-1">
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/company_24x24.png")} />
                        </div>
                      </i>
                      <span>puncte de lucru: maxim 100</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/equipment_24x24.png")} />
                        </div>
                      </i>
                      <span>echipamente: maxim 100</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/worker_24x24.png")} />
                        </div>
                      </i>
                      <span>personal ISCIR: maxim 200</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/data_24x24.png")} />
                        </div>
                      </i>
                      <span>spațiu: maxim 2.5 GB</span>
                    </li>
                  </ul>
                  <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="btn-lnk-blue">
                    <Link to={"/public/prices"}>
                      <span className=" p-3 w-full mt-auto txt-ctr">Aplică acum</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 lg:col-4">
              <div className="p-3 h-full">
                <div className="shadow-2 p-3 flex flex-column" style={{ borderRadius: "6px" }}>
                  <div className="text-900 font-medium text-xl mb-2">Complet 300</div>
                  <div className="text-600">Descriere pachet</div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">... euro + TVA / </span>
                    <span className="ml-2 font-medium text-600">an</span>
                  </div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">... euro + TVA / </span>
                    <span className="ml-2 font-medium text-600">lună</span>
                  </div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <ul className="list-none p-0 m-0 flex-grow-1">
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/company_24x24.png")} />
                        </div>
                      </i>
                      <span>puncte de lucru: maxim 200</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/equipment_24x24.png")} />
                        </div>
                      </i>
                      <span>echipamente: maxim 200</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/worker_24x24.png")} />
                        </div>
                      </i>
                      <span>personal ISCIR: maxim 400</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/data_24x24.png")} />
                        </div>
                      </i>
                      <span>spațiu: maxim 5 GB</span>
                    </li>
                  </ul>
                  <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="btn-lnk-blue">
                    <Link to={"/public/prices"}>
                      <span className=" p-3 w-full mt-auto txt-ctr">Aplică acum</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="surface-0 it-font text-700">
          <br />
          <div className="text-xl mb-6 text-justify line-height-3 pmarg">
            <span className="bd-it-font">
              Observații:
            </span>
            <br />
            <ul className="text-700 text-xl mb-6 blt-marg black-bullet">
              <li>Un punct de lucru se consideră a fi sediul social sau orice alt punct de lucru al unei companii. În cazul în care la sediul social nu există echipamente/instalații ISCIR, acesta nu se va considera ca fiind un punct de lucru.</li>
              <li>Pentru fiecare punct de lucru se pot înregistra operatori RSVTI diferiți. Pentru pachetul gratuit se va permite introducerea unui singur operator RSVTI în contul respectiv.</li>
              <li>La pachetele de bază se va permite adăugarea sau înregistrarea de echipamente/instalații ISCIR și adăugarea sau înregistrarea de deservenți/deservenți auxiliari care nu se regăsesc în lista cu prescripții tehnice admise pentru aceste tipuri de pachete dar nu se vor putea genera documente din aplicație decât pentru echipamentele/instalațiile și personalul care se regăsesc în pachetul achiziționat.</li>
              <li>Prescripțiile tehnice care se referă la supape de siguranță, membrane de rupere, cabluri, cârlige, lanţuri, benzi textile, funii, etc., sunt disponibile pentru toate tipurile de abonamente, inclusiv pentru cel gratuit.</li>
              <li>Prin echipamente se înțeleg atât echipamentele/instalațiile care intră sub incidența ISCIR, precum și echipamentele interne.</li>
              <li>Prin personal ISCIR se înțelege personalul deservent și/sau personalul deservent auxiliar.</li>
              <li>Spațiul oferit în cadrul aplicației se referă la spațiul ocupat cu toate fișierele atașate contului deținut, indiferent de tipul acestora (pdf, jpg, png, etc.). Toate documentele generate din aplicația MyRSVTI nu vor fi contorizate la acest spațiu.</li>
            </ul>
          </div>
        </div>

      </main>
      <div>
        <br />
        <br />
      </div>

      <Footer />
    </div>
  );
}
