import "../../static/css/pageLayout.css";

import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { BASE_URL, STRING_CONST, dialogFooterContent, ICONS } from "../../common/globals";
import { FIELD_SIZE, isEmptyString, validateString, validateMinMaxText } from "../../common/validations";
import DialogConfigurationNotProcessed from "../../components/DialogConfigurationNotProcessed";
import DialogProcessingDocuments from "../../components/DialogProcessingDocuments";
import DialogDataProcessed from "../../components/DialogDataProcessed";
import DialogDataNotProcessed from "../../components/DialogDataNotProcessed";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import ProcessFormErrors from "../../components/ProcessFormErrors";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function OperatorGenerateDocsTests() {
  const generateTestsURL = BASE_URL + "operatordocuments/generate/tests";
  const signingPersonsURL = BASE_URL + "person/signing";
  const configURL = BASE_URL + "operatordocuments/get/config";

  const uuid = sessionStorage.getItem("operatorSessionUuid");
  const companyUuid = sessionStorage.getItem("companyUuid");

  const [config, setConfig] = useState([]);

  const [iscirPersons, setIscirPersons] = useState([]);
  const [member2Persons, setMember2Persons] = useState([]);
  const [member3Persons, setMember3Persons] = useState([]);
  const [signingPersons, setSigningPersons] = useState([]);

  const [processingDialog, setProcessingDialog] = useState(null);
  const [processedDialog, setProcessedDialog] = useState(null);
  const [notProcessedDialog, setNotProcessedDialog] = useState(null);
  const [configNotProcessedDialog, setConfigNotProcessedDialog] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);

  const navigate = useNavigate();

  const { auth } = useAuth();

  const [values, setValues] = useState({
    iscirOwner: "",
    member2: "",
    member3: "",
    signingOwner: "",
    signingOwnerRole: "",
    theoreticalTestingDate: "",
    practicalTestingDate: "",
  });

  const inputs = [
    {
      id: 0,
      name: "iscirOwner",
      type: "text",
      placeholder: "Operator RSVTI",
      label: "Operator RSVTI",
    },
    {
      id: 1,
      name: "member2",
      type: "text",
      placeholder: "Membru comisie 2",
      label: "Membru comisie 2",
    },
    {
      id: 2,
      name: "member3",
      type: "text",
      placeholder: "Membru comisie 3",
      label: "Membru comisie 3",
    },
    {
      id: 3,
      name: "signingOwner",
      type: "text",
      placeholder: "Nume responsabil semnare acte",
      label: "Nume responsabil semnare acte",
    },
    {
      id: 4,
      name: "signingOwnerRole",
      type: "text",
      placeholder: "Funcție responsabil semnare acte",
      label: "Funcție responsabil semnare acte",
    },
    {
      id: 5,
      name: "theoreticalTestingDate",
      type: "date",
      placeholder: "Alege data testare teoretică",
      label: "Alege data testare teoretică",
    },
    {
      id: 6,
      name: "practicalTestingDate",
      type: "date",
      placeholder: "Alege data testare practică",
      label: "Alege data testare practică",
    },
  ];

  const FIELD = {
    iscirOwner: 0,
    member2: 1,
    member3: 2,
    signingOwner: 3,
    signingOwnerRole: 4,
    theoreticalTestingDate: 5,
    practicalTestingDate: 6,
  };

  useEffect(() => {
    axios
      .post(
        configURL,
        {
          uuid,
          companyUuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setConfig(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
    axios
      .post(
        signingPersonsURL,
        {
          uuid: companyUuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setIscirPersons(response.data.iscirOwners);
        setMember2Persons(response.data.secondMembers);
        setMember3Persons(response.data.thirdMembers);
        setSigningPersons(response.data.signingOwners);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let data = [];
    data = { ...values };
    if (iscirPersons.length === 1) {
      data = { ...data, iscirOwner: iscirPersons[0].uuid };
    }
    if (signingPersons.length === 1) {
      data = { ...data, signingOwner: signingPersons[0].uuid, signingOwnerRole: signingPersons[0].role };
    }
    if (member2Persons.length === 1) {
      data = { ...data, member2: member2Persons[0].uuid };
    }
    if (member3Persons.length === 1) {
      data = { ...data, member3: member3Persons[0].uuid };
    }
    setValues({ ...values, ...data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iscirPersons, signingPersons, member2Persons, member3Persons]);

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onChangeSigningPersons = (e) => {
    let role = "";
    for (var i = 0; i < signingPersons.length; i++) {
      if (e.target.value === signingPersons[i].uuid) {
        role = signingPersons[i].role;
      }
    }
    setValues({ ...values, [e.target.name]: e.target.value, signingOwnerRole: role });
  };

  const handleGenerate = async (e) => {
    e.preventDefault();
    setProcessingDialog(true);
    if (!validateForm()) {
      setProcessingDialog(false);
      return;
    }
    axios
      .post(
        generateTestsURL,
        {
          uuid: uuid,
          companyUuid: companyUuid,
          iscirOwner: values.iscirOwner,
          member2: values.member2,
          member3: values.member3,
          signingOwner: values.signingOwner,
          signingOwnerRole: values.signingOwnerRole,
          theoreticalTestingDate: values.theoreticalTestingDate,
          practicalTestingDate: values.practicalTestingDate,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setProcessingDialog(false);
        //setProcessedDialog(true);
        navigate("/operator/generatedocscertificates");
      })
      .catch((error) => {
        setProcessingDialog(false);
        setNotProcessedDialog(true);
      });
  };

  const validateForm = () => {
    let result = true;
    let data = [];
    if (isEmptyString(values.iscirOwner)) {
      data.push({
        id: inputs[FIELD.iscirOwner].id,
        value: inputs[FIELD.iscirOwner].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_OPERATOR_DOCUMENTS,
      });
      result = false;
    }
    if (isEmptyString(values.member2)) {
      data.push({
        id: inputs[FIELD.member2].id,
        value: inputs[FIELD.member2].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_OPERATOR_DOCUMENTS,
      });
      result = false;
    }
    if (isEmptyString(values.signingOwner)) {
      data.push({
        id: inputs[FIELD.signingOwner].id,
        value: inputs[FIELD.signingOwner].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_OPERATOR_DOCUMENTS,
      });
      result = false;
    }
    if (!validateString(values.signingOwnerRole, FIELD_SIZE.SignerOwnerRoleMin, FIELD_SIZE.SignerOwnerRoleMax)) {
      data.push({
        id: inputs[FIELD.signingOwnerRole].id,
        value: inputs[FIELD.signingOwnerRole].label + validateMinMaxText(FIELD_SIZE.SignerOwnerRoleMin, FIELD_SIZE.SignerOwnerRoleMax),
      });
      result = false;
    }
    if (isEmptyString(values.theoreticalTestingDate)) {
      data.push({
        id: inputs[FIELD.theoreticalTestingDate].id,
        value: inputs[FIELD.theoreticalTestingDate].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_OPERATOR_DOCUMENTS,
      });
      result = false;
    }
    if (isEmptyString(values.practicalTestingDate)) {
      data.push({
        id: inputs[FIELD.practicalTestingDate].id,
        value: inputs[FIELD.practicalTestingDate].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_OPERATOR_DOCUMENTS,
      });
      result = false;
    }
    setValidationErrors(data);
    return result;
  };

  const handleViewDocuments = async (sessionUuid, e) => {
    e.preventDefault();
    sessionStorage.setItem("sessionUuid", sessionUuid);
    navigate("/operator/generatedocsview");
  };

  return (
    <div className="clientArea">
      <Sidebar />

      <button class="buttonClassGreen">Generare documente deservent</button>
      <div class="spaceDiv" />

      <div class="rowdouble">
        <div class="column">
          <p class="pTitle">
            Punct de lucru : {config.code} - {config.county} - {config.town} - {config.addressDetails1}
          </p>
        </div>
      </div>

      <div class="rowdouble">
        <div class="column">
          <p class="pTitle">Personal semnatar documentații</p>
        </div>
      </div>

      <div class="rowdouble">
        <div class="column" id={"internalLink" + inputs[FIELD.iscirOwner].id}>
          <FormSelect
            key={inputs[FIELD.iscirOwner].id}
            name={inputs[FIELD.iscirOwner].name}
            label={inputs[FIELD.iscirOwner].label}
            value={values[inputs[FIELD.iscirOwner].name]}
            ddOptions={iscirPersons}
            onChange={onChange}
          />
        </div>
      </div>

      <div class="rowdouble">
        <div class="column">
          <p class="pTitle">Membrii comisie</p>
        </div>
      </div>

      <div class="rowdouble">
        <div class="column" id={"internalLink" + inputs[FIELD.member2].id}>
          <FormSelect
            key={inputs[FIELD.member2].id}
            name={inputs[FIELD.member2].name}
            label={inputs[FIELD.member2].label}
            value={values[inputs[FIELD.member2].name]}
            ddOptions={member2Persons}
            onChange={onChange}
          />
        </div>
      </div>

      <div class="rowdouble">
        <div class="column" id={"internalLink" + inputs[FIELD.member3].id}>
          <FormSelect
            key={inputs[FIELD.member3].id}
            name={inputs[FIELD.member3].name}
            label={inputs[FIELD.member3].label}
            value={values[inputs[FIELD.member3].name]}
            ddOptions={member3Persons}
            onChange={onChange}
          />
        </div>
      </div>

      <div class="rowdouble">
        <div class="column">
          <p class="pTitle">Responsabil semnare acte</p>
        </div>
      </div>

      <div class="rowdouble">
        <div class="column" id={"internalLink" + inputs[FIELD.signingOwner].id}>
          <FormSelect
            key={inputs[FIELD.signingOwner].id}
            name={inputs[FIELD.signingOwner].name}
            label={inputs[FIELD.signingOwner].label}
            value={values[inputs[FIELD.signingOwner].name]}
            ddOptions={signingPersons}
            onChange={onChangeSigningPersons}
          />
        </div>
      </div>

      <div class="rowdouble">
        <div class="column" id={"internalLink" + inputs[FIELD.signingOwnerRole].id}>
          <FormInput
            key={inputs[FIELD.signingOwnerRole].id}
            {...inputs[FIELD.signingOwnerRole]}
            value={values[inputs[FIELD.signingOwnerRole].name]}
            onChange={onChange}
          />
        </div>
      </div>

      <div class="rowdouble">
        <div class="column">
          <p class="pTitle">Testare teoretică</p>
        </div>
      </div>

      <div class="rowdouble">
        <div class="column" id={"internalLink" + inputs[FIELD.theoreticalTestingDate].id}>
          <FormInput
            key={inputs[FIELD.theoreticalTestingDate].id}
            {...inputs[FIELD.theoreticalTestingDate]}
            value={values[inputs[FIELD.theoreticalTestingDate].name]}
            onChange={onChange}
          />
        </div>
      </div>

      <div class="rowdouble">
        <div class="column">
          <p class="pTitle">Testare practică</p>
        </div>
      </div>

      <div class="rowdouble">
        <div class="column" id={"internalLink" + inputs[FIELD.practicalTestingDate].id}>
          <FormInput
            key={inputs[FIELD.practicalTestingDate].id}
            {...inputs[FIELD.practicalTestingDate]}
            value={values[inputs[FIELD.practicalTestingDate].name]}
            onChange={onChange}
          />
        </div>
      </div>
      {validationErrors.length > 0 ? <ProcessFormErrors validationErrors={validationErrors} /> : null}
      {processingDialog !== true ? (
        <div class="rowdoublecentered">
          <div class="columnbutton">
            <img
              class="imgtoolbar"
              src={require("../../static/images/buttons/" + ICONS.OperatorGenerateWordDocuments)}
              alt="Generare documente testare deservenți"
              title="Generare documente testare deservenți"
              onClick={handleGenerate}
            />
          </div>
        </div>
      ) : null}
      <div class="rowdoublecentered">
        <div class="columnbutton">
          <img
            class="imgtoolbar"
            src={require("../../static/images/buttons/" + ICONS.OperatorGenerateDocuments)}
            alt="Vezi documentele generate până la acest pas"
            title="Vezi documentele generate până la acest pas"
            onClick={(e) => handleViewDocuments(uuid, e)}
          />
        </div>
      </div>

      <DialogProcessingDocuments isVisible={processingDialog} />
      <DialogDataProcessed isVisible={processedDialog} footer={dialogFooterContent("OK", () => setProcessedDialog(false))} />
      <DialogDataNotProcessed isVisible={notProcessedDialog} footer={dialogFooterContent("OK", () => setNotProcessedDialog(false))} />
      <DialogConfigurationNotProcessed
        isVisible={configNotProcessedDialog}
        footer={dialogFooterContent("OK", () => setConfigNotProcessedDialog(false))}
      />
    </div>
  );
}
