import { Dialog } from "primereact/dialog";
import React from "react";

const DialogDataProcessed = (props) => {
  const { isVisible, onHide, footer } = props;

  return (
    <Dialog modal header="Notificare" visible={isVisible} onHide={onHide} footer={footer} style={{ width: "350px" }}>
      <p className="m-0">Informaţiile au fost prelucrate cu succes!</p>
    </Dialog>
  );
};

export default DialogDataProcessed;
