import "../static/css/priceheader.css";
import "../static/css/price.css";
import "../static/css/pricefooter.css";

import "primeflex/primeflex.css";

import React from "react";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

export default function IrWorkers() {
    return (
        <div className="IrWorkers">
            <Header />

            <main>
                <div className="surface-0">
                    <br />
                    <br />
                    <div>
                        <img alt="" className="img-all" src={require("../static/images/1920x400/bckg_1920x400_015.jpg")} />
                    </div>
                    <br />
                </div>

                <div>
                    <br />
                    <br />
                </div>

                <div className="surface-0">

                    <div className="text-900 font-bold text-6xl mb-4 text-left pmarg">Deservenți auxiliari instalații de ridicat</div>

                    <div className="pmarg">
                        <div className="card">
                            <Accordion activeIndex={0}>
                                <AccordionTab header="Legător de sarcină">
                                    <p className="m-0">

                                        <div className="surface-0">
                                            <div className="grid">
                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Clasificare macarale</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div class=" align-items-center ">
                                                                <img alt="" class="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/TMOQfeau344?si=G5WNJNyEw8VlVKVJ"
                                                                    title="Clasificare macarale"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Clasificare elemente fixare sarcină</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div class=" align-items-center ">
                                                                <img alt="" class="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/0gp-XXNwubA?si=w403QvaVdqNBFohN"
                                                                    title="Clasificare elemente de legare și prindere sarcină"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Legislație macarale</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div class=" align-items-center ">
                                                                <img alt="" class="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/f3qGASV4_VE?si=3-tle9uramg6gXX9"
                                                                    title="Legislație macarale"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>

                                        <div className="surface-0">
                                            <div className="grid">
                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Codul de semnalizare</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div class=" align-items-center ">
                                                                <img alt="" class="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/nr1u8jo9pR0?si=Sr72F9mQP5aJ0s2v"
                                                                    title="Codul de semnalizare, conform R1-2010"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Codul de semnalizare</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div class=" align-items-center ">
                                                                <img alt="" class="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/EhxZ7uRp1YE?si=ech2sfdembrD_RtS"
                                                                    title="Codul de semnalizare, conform HG 971"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Accidente macarale</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div class=" align-items-center ">
                                                                <img alt="" class="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/V2QFpeELNQY?si=krRB9BcKBhREXt4j"
                                                                    title="Accidente macarale"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>


                                    </p>
                                </AccordionTab>
                                <AccordionTab header="Manevrant platformă autoridicătoare">
                                    <p className="m-0">

                                        <div className="surface-0">
                                            <div className="grid">
                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Clasificare platforme autoridicătoare</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div class=" align-items-center ">
                                                                <img alt="" class="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/TBuNXgi5u-Q?si=qPMLCdjBAz--pD_w"
                                                                    title="Clasificare platforme autoridicătoare"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Legislație platforme autoridicătoare</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div class=" align-items-center ">
                                                                <img alt="" class="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/E6cFux1gyYo?si=rq-yAQGLIE-azPeY"
                                                                    title="Legislație platforme autoridicătoare"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>
                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>



                                    </p>
                                </AccordionTab>

                                <AccordionTab header="Manevrant elevator pentru vehicule">
                                    <p className="m-0">

                                        <div className="surface-0">
                                        <div className="grid">
                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Clasificare elevatoare auto</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div class=" align-items-center ">
                                                                <img alt="" class="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/Q1MrmDElmxo?si=weejEVUD-RN-25-R"
                                                                    title="Clasificare elevatoare auto"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Legislație elevatoare auto</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div class=" align-items-center ">
                                                                <img alt="" class="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/MaO4JbN3I7g?si=CmGUsGSryKkBU-8y"
                                                                    title="Legislație elevatoare auto"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>





                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>

                                    </p>
                                </AccordionTab>

                                <AccordionTab header="Manevrant mecanism de ridicat - transpaletă">
                                    <p className="m-0">

                                        <div className="surface-0">
                                        <div className="grid">
                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Clasificare transpalete</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div class=" align-items-center ">
                                                                <img alt="" class="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/OEEvpnWxyTk?si=75mD2X-qfWiCl3o6"
                                                                    title="Clasificare transpalete"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Legislație transpalete</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div class=" align-items-center ">
                                                                <img alt="" class="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/uczObuOLS1k?si=c2_Hp-rcqwp89_0v"
                                                                    title="Legislație transpalete"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>





                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>

                                    </p>
                                </AccordionTab>



                                <AccordionTab header="Manevrant mecanism de ridicat - cric">
                                    <p className="m-0">

                                        <div className="surface-0">
                                            <div className="grid">
                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">




                                                    </div>
                                                </div>






                                            </div>

                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>

                                    </p>
                                </AccordionTab>
                            </Accordion>
                        </div>
                    </div>


                    <div>
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>


                </div>




            </main>
            <div>
                <br />
                <br />
            </div>

            <Footer />
        </div>
    );
}
