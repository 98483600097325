import { Dialog } from "primereact/dialog";
import React from "react";

const DialogListNotLoaded = (props) => {
  const { isVisible, onHide, footer } = props;

  return (
    <Dialog modal header="Eroare" visible={isVisible} onHide={onHide} footer={footer} style={{ width: "450px" }}>
      <p className="m-0">Lista NU este accesibilă! Încercaţi din nou, dacă eroarea persistă contactaţi echipa de suport: suport@myrsvti.ro.</p>
    </Dialog>
  );
};

export default DialogListNotLoaded;
