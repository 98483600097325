import "../../static/css/pageLayout.css";

import axios from "axios";
import { useState, useEffect } from "react";

import { BASE_URL, ICONS } from "../../common/globals";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function WorkerGenerateViewDocuments() {
  const allDocumentsURL = BASE_URL + "workerdocuments/get/documents";
  const downloadDocumentURL = BASE_URL + "workerdocuments/get/document";

  const uuid = sessionStorage.getItem("workerSessionUuid");

  const { auth } = useAuth();

  const [configs, setConfigs] = useState([]);

  useEffect(() => {
    axios
      .post(
        allDocumentsURL,
        {
          uuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setConfigs(response.data);
      })
      .catch((error) => {
        console.log(error);
        alert("Configuratia certificarii nu a putut fi citita!");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetDocument = async (companyUuid, fileName, fileUuid, e) => {
    e.preventDefault();
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = fileName;
    axios
      .post(
        downloadDocumentURL,
        {
          sessionUuid: uuid,
          companyUuid: companyUuid,
          fileUuid: fileUuid,
        },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((res) => {
        link.href = URL.createObjectURL(new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" }));
        link.click();
      });
  };

  return (
    <div className="clientArea">
      <Sidebar />

      <button class="buttonClassGreen">Documente deservent auxiliar</button>
      <div class="spaceDiv" />

      <div class="rowdouble">
        <div class="column">
          <p class="pTitle">Documente generate</p>
        </div>
      </div>

      {configs.map((configW, index) => (
        <div key={index * 100}>
          <div class="rowdouble">
            <div class="column">
              Punct de lucru {index + 1}: {configW.config.code} - {configW.config.county} - {configW.config.town}- {configW.config.addressDetails1}
            </div>
          </div>
          {configW.document.map((doc, index) => (
            <div class="rowdouble">
              <div class="column">
                <img
                  class="imgdownload"
                  src={require("../../static/images/buttons/" + ICONS.WorkerExport)}
                  alt={"Descarca " + doc.fileName}
                  title={"Descarca " + doc.fileName}
                  onClick={(e) => handleGetDocument(doc.companyUuid, doc.fileName, doc.uuid, e)}
                />
                &nbsp;{doc.fileName}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
