import React from "react";

import Header from "./Header";
import Footer from "./Footer";

export default function Terms() {
  return (
    <div className="Terms">
      <Header />

      <main>
        <div className="surface-0">
          <br />
          <br />
          <div>
            <img alt="" className="img-all" src={require("../static/images/1920x400/bckg_1920x400_009.jpg")} />
          </div>
          <br />
          <br />

          <div className="text-900 font-bold text-6xl mb-4 text-center">Termeni și condiții</div>
          <br /><br />
          <div className="text-700 text-xl mb-6 text-justify line-height-3 pmarg">
            Acest site, „www.myrsvti.ro”, a fost creat în scopul de a facilita accesul la informarea rapidă a tuturor clienților și/sau persoanelor interesate (fizice sau juridice), denumite în continuare „Utilizator”, cu privire la serviciile oferite de către Asisco Tehnic SRL. Informațiile conținute în acest site sunt generale, având un rol informativ. Asisco Tehnic SRL nu își asumă responsabilitatea pentru acuratețea tuturor informațiilor oferite, însă va efectua toate demersurile necesare pentru ca datele să fie cât mai corecte și actuale.
            <br />
            Utilizarea acestui site este condiționată de respectarea „Termenilor și condițiilor” prezentate în continuare. Condițiile de utilizare se referă exclusiv la site-ul „www.myrsvti.ro”.
            <br />
            Dacă nu sunteți de acord cu condițiile de utilizare, vă rugăm să nu utilizați prezentul site.
            <br />
          </div>

          <div className="font-bold text-2xl text-900 text-justify line-height-3 pmarg">
            ACCESAREA, VIZIONAREA ȘI UTILIZAREA ACESTUI SITE IMPLICĂ FAPTUL CĂ SUNTEȚI DE ACORD ȘI ACCEPTAȚI, IMPLICIT ȘI NECONDIȚIONAT, PREZENTELE CONDIȚII DE UTILIZARE.             <br />
            <br />
          </div>
          <div className="font-bold text-2xl text-900 text-justify line-height-3 pmarg">
            TERMENI LEGALI
          </div>
          <div className="text-700 text-xl mb-6 text-justify line-height-3 pmarg">
            Dacă vreo prevedere din acest set de „Termeni și condiții” este considerată invalidă de către forurile legale competente, invaliditatea unei asemenea prevederi nu va afecta valabilitatea celorlalte prevederi din „Termeni și condiții”, acestea rămânând în vigoare. Acești „Termeni și Condiții” constituie în întregime un acord încheiat între Dvs. și Asisco Tehnic SRL în privința utilizării site-ului „www.myrsvti.ro”.
            <br />
          </div>

          <div className="font-bold text-2xl text-900 text-justify line-height-3 pmarg">
            DREPTURI DE AUTOR
          </div>
          <div className="text-700 text-xl mb-6 text-justify line-height-3 pmarg">
            Asisco Tehnic SRL este deținătorul prezentului site. Fotografiile, textele, desenele, imaginile, precum și toate lucrările integrate în acest site sunt proprietatea Asisco Tehnic SRL cu excepția cazului în care este menționat expres un alt proprietar.
            <br />
            Reproducerea parțială sau totală, pe hârtie sau format digital, a materialelor care se găsesc pe site-ul „www.myrsvti.ro” este autorizată numai în scopul folosinței strict personale, excluzând orice utilizare în scopuri publicitare și/sau comerciale și/sau de informare, respectând astfel legislația în vigoare cu privire la proprietatea intelectuală.
            <br />
            Conținutul site-ului, integral și/sau partial, nu poate fi reprodus, duplicat, copiat, revândut, vizitat sau exploatat în scopuri comerciale fără acordul prealabil al Asisco Tehnic SRL.
            <br />
            Plasarea unei legături externe către site-ul „www.myrsvti.ro” necesită o autorizare prealabilă în scris transmisă de la Asisco Tehnic SRL. Dacă doriți să creați o legătura către site-ul nostru vă rugăm să ne contactați (a se vedea pagina „Contact”).
            <br />
          </div>

          <div className="font-bold text-2xl text-900 text-justify line-height-3 pmarg">
            LIMITAREA RĂSPUNDERII
          </div>
          <div className="text-700 text-xl mb-6 text-justify line-height-3 pmarg">
            Utilizatorul folosește site-ul pe riscul propriu. În nici un caz, Asisco Tehnic SRL nu va putea fi ținut responsabil pentru daune directe sau indirecte (inclusiv pierderea / alterarea / distrugerea unor date, informații sau programe stocate pe computerul Dvs.) și/sau pentru orice prejudicii materiale și/sau financiare, rezultate ca urmare a accesării sau utilizării acestui site, sau a oricăror site-uri care sunt legate de acesta. Ca urmare, Asisco Tehnic SRL nu este responsabilă de costurile și/sau pierderile materiale, rezultate din folosirea informațiilor din „www. myrsvti.ro”.
            <br />
            Asisco Tehnic SRL nu își asumă obligația și nu garantează în mod expres sau implicit, pentru conținutul acestui site, pentru conținutul informațiilor oferite de către partenerii site-ului.
            <br />
            Utilizatorii site-ului înțeleg și acceptă faptul că funcționarea site-ului „www.myrsvti.ro” poate fi afectată de anumite condiții obiective, precum și faptul că serviciile sunt oferite conform principiului „așa cum sunt” sau „așa cum sunt disponibile”.
            <br />
            Asisco Tehnic SRL nu este responsabilă pentru prejudiciile aduse prin folosirea neautorizată a informațiilor de pe „www.myrsvti.ro”.
            <br />
            Asisco Tehnic SRL nu își asumă responsabilitatea pentru veridicitatea infomațiilor furnizate de terțe părți pe „www. myrsvti.ro”.
            <br />
            Pentru cazurile de forță majoră, Asisco Tehnic SRL și/sau angajații săi, sunt exonerați total de răspundere. Cazurile de forță majoră includ, dar nu se limitează la, erori de funcționare ale echipamentului tehnic  Asisco Tehnic SRL sau al furnizorilor de servicii de găzduire, lipsa funcționării conexiunii la internet, lipsa funcționării conexiunilor, virușii informatici, accesul neautorizat la sistemele site-ului, erori de operare, etc.
            <br />
            Acest site conține legături (link-uri) către site-uri externe, care nu sunt sub controlul si responsabilitatea Asisco Tehnic SRL. O referință la un site legat de „www.myrsvti.ro” sau la orice nume de produs ori serviciu al unei terțe părți nu constituie și nu implică aprobarea sau susținerea sa de către noi, iar Dvs. vă asumați toate riscurile în ceea ce privește utilizarea sa. Odată cu accesarea acestor link-uri externe, implicit recunoașteți și sunteți de acord că nu avem nicio responsabilitate asupra conținutului acestor site-uri. Accesarea acestor link-uri este decizia Dvs. și veți face acest lucru pe propriul risc.
            <br />
          </div>

          <div className="font-bold text-2xl text-900 text-justify line-height-3 pmarg">
            MODIFICAREA ȘI ACTUALIZAREA SITE-ULUI
          </div>
          <div className="text-700 text-xl mb-6 text-justify line-height-3 pmarg">
            Asisco Tehnic SRL își rezervă dreptul de a modifica oricând și în orice mod accesul la site, conținutul acestuia, precum și conținutul prezentelor Condiții de Utilizare, integral și/sau parțial, fără a fi obligată să îndeplinească vreo formalitate față de Utilizatori și fără ca modificările să presupună responsabilitatea Asisco Tehnic SRL sau a angajaților săi.
            <br />
            Orice modificare este considerată ca fiind acceptată deplin și necondiționat de către Utilizatorii site-ului, prin simpla accesare și/sau utilizare a acestuia sau accesarea oricărei facilități oferite de site, intervenite oricând după operarea modificării.
            <br />
            Asisco Tehnic SRL vă recomandă să vizitați această pagină periodic pentru a citi „Termenii și Condițiile” deoarece acest document vă obligă legal. Accesarea și/sau utilizarea în continuare a site-ului „www.myrsvti.ro” sau a serviciilor oferite de către acesta reprezintă acceptarea necondiționată a modificărilor aduse prezentului document.
            <br />
          </div>

          <div className="font-bold text-2xl text-900 text-justify line-height-3 pmarg">
            DREPT APLICABIL
          </div>
          <div className="text-700 text-xl mb-6 text-justify line-height-3 pmarg">
            Prezentarea informațiilor din această pagină este în conformitate cu legislația română în vigoare.
            <br />
            Prezentele Condiții de Utilizare, precum și orice litigiu în legătură cu, sau decurgând din clauzele acestor Condiții sau din accesarea / funcționarea / disfuncționalitatea site-ului se supun dreptului intern român. Limba principală a Condițiilor de Utilizare și a site-ului este limba română.
            <br />
            Orice litigiu născut din, sau în legătură cu interpretarea și/sau aplicarea prezentelor Condiții de Utilizare va fi soluționat pe cale amiabilă. În caz de litigiu, numai instanțele românești vor fi considerate competente.
            <br />
          </div>

          <div className="font-bold text-2xl text-900 text-justify line-height-3 pmarg">
            PROBLEME, ÎNTREBĂRI ȘI SUGESTII
          </div>
          <div className="text-700 text-xl mb-6 text-justify line-height-3 pmarg">
            Vă rugăm să ne comunicați orice problemă, întrebare și/sau sugestie privind accesul și/sau utilizarea site-lui nostru utilizând informațiile din pagina de „Contact”.
            <br />
            Asisco Tehnic SRL își rezervă dreptul de a răspunde selectiv la mesajele Dvs., în funcție de natura, complexitatea, gravitatea și urgența aspectelor semnalate.
            <br />
          </div>

          <div className="text-700 text-xl mb-6 text-justify line-height-3 it-font pmarg">
            Ultima actualizare: 01 septembrie 2024.
            <br /><br /><br />
          </div>


        </div>



      </main>

      <Footer />
    </div>
  );
}
