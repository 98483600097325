import "../static/css/footer.css";
import "../static/css/header.css";
import "../static/css/style.css";

import "../static/css/contact.css";

import React from "react";

import Footer from "./Footer";
import Header from "./Header";

export default function Contact() {
  return (
    <div className="Contact">
      <Header />

      <main>
        <div className="surface-0">
          <br />
          <br />
          <div>
            <img alt="" className="img-all" src={require("../static/images/1920x400/bckg_1920x400_005.jpg")} />
          </div>
          <br />
        </div>

        <br />
        <br />

        <div className="surface-0">
          <div className="text-900 font-bold text-6xl mb-4 text-left txt-inf">Asisco Tehnic SRL</div>

          <div className="grid">
            <div className="col-12 lg:col-4">
              <div className="p-3 h-full">
                <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                  <div className="text-900 font-medium text-xl mb-2">Informații firmă</div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />

                  <ul className="list-none p-0 m-0 flex-grow-1">
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left"></div>
                      </i>
                      <span className="txt-it">Număr Registrul Comerțului:</span>
                      <br />
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left"></div>
                      </i>
                      <span className="txt-bd">J08/46/2010</span>
                    </li>
                    <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left"></div>
                      </i>
                      <span className="txt-it">Cod fiscal:</span>
                      <br />
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left"></div>
                      </i>
                      <span className="txt-bd">RO 22280483</span>
                    </li>
                    <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left"></div>
                      </i>
                      <span className="txt-it">Adresă web:</span>
                      <br />
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left"></div>
                      </i>
                      <span className="txt-bd">www.asisco.ro</span>
                    </li>
                  </ul>
                  <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />
                </div>
              </div>
            </div>

            <div className="col-12 lg:col-4">
              <div className="p-3 h-full">
                <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                  <div className="text-900 font-medium text-xl mb-2">Sediu social</div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <ul className="list-none p-0 m-0 flex-grow-1">
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/company_24x24.png")} />
                        </div>
                      </i>
                      <span className="txt-it">Adresă:</span>
                      <br />
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left"></div>
                      </i>
                      <span className="txt-inf">str. Zizinului nr. 10, bloc 38, apt. 30</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left"></div>
                      </i>
                      <span className="txt-inf">Brașov, cod poștal 500414, județ Brașov</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/phone_24x24.png")} />
                        </div>
                      </i>
                      <span className="txt-it">Telefon:</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left"></div>
                      </i>
                      <span className="txt-inf">0374-012324</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/email_24x24.png")} />
                        </div>
                      </i>
                      <span className="txt-it">E-mail:</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left"></div>
                      </i>
                      <span className="txt-inf">office@asisco.ro</span>
                    </li>
                  </ul>
                  <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300" />
                </div>
              </div>
            </div>

            <div className="col-12 lg:col-4">
              <div className="p-3 h-full">
                <div className="shadow-2 p-3 flex flex-column" style={{ borderRadius: "6px" }}>
                  <div className="text-900 font-medium text-xl mb-2">Punct de lucru</div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <ul className="list-none p-0 m-0 flex-grow-1">
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/company_24x24.png")} />
                        </div>
                      </i>
                      <span className="txt-it">Adresă:</span>
                      <br />
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left"></div>
                      </i>
                      <span className="txt-inf">str. Pietrele Doamnei nr. 25</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left"></div>
                      </i>
                      <span className="txt-inf">București, cod poștal 060317, sector 6</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/mobile_24x24.png")} />
                        </div>
                      </i>
                      <span className="txt-it">Telefon:</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left"></div>
                      </i>
                      <span className="txt-inf">0753-012324</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/email_24x24.png")} />
                        </div>
                      </i>
                      <span className="txt-it">E-mail:</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left"></div>
                      </i>
                      <span className="txt-inf">office@asisco.ro</span>
                    </li>
                  </ul>
                  <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />

        <div class="surface-0 ft-pc ft-pc-center">
          <div class="ft-child">
            <div class="">
              <a href="https://anpc.ro/ce-este-sal/" target="_blank" rel="noopener noreferrer" className=" p-3 w-full mt-auto txt-ctr">
                <img alt="" src={require("../static/images/protection_costumer/sal.png")} />
              </a>
            </div>
          </div>
          <div class="ft-child">
            <div class="">
              <a
                href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO"
                target="_blank"
                rel="noopener noreferrer"
                className=" p-3 w-full mt-auto txt-ctr"
              >
                <img alt="" src={require("../static/images/protection_costumer/sol.png")} />
              </a>
            </div>
          </div>
        </div>

        <br />
        <br />
        <br />
        <br />

        <div className="surface-0">
          <div className="p-4 h-full">
            <div className="shadow-2 border-round img-contact">
              <div className="text-3xl font-medium text-900 mb-3"></div>
              <div className="font-medium text-500 mb-3">str. Zizinului nr. 10, Brașov, județ Brașov</div>
              <div className="border-2 border-dashed border-300">
                <a href="https://maps.app.goo.gl/fwdFkDkVLCiG3gjy8" target="_blank" rel="noopener noreferrer" className=" p-3 w-full mt-auto txt-ctr">
                  <img alt="" className="img-all" src={require("../static/images/1280x720/mapbv_1280x720.jpg")} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
      </main>

      <div>
        <br />
        <br />
      </div>

      <Footer />
    </div>
  );
}
