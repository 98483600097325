import { Dialog } from "primereact/dialog";
import React from "react";

const DialogMissingImportFiles = (props) => {
  const { isVisible, onHide, footer, single } = props;

  return (
    <Dialog modal header="Eroare" visible={isVisible} onHide={onHide} footer={footer} style={{ width: "350px" }}>
      {null === single || undefined === single ? (
        <p className="m-0">Fişierele pentru import lipsesc!</p>
      ) : (
        <p className="m-0">Fişierul pentru import lipseşte!</p>
      )}
    </Dialog>
  );
};

export default DialogMissingImportFiles;
