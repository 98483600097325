import axios from "axios";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { BASE_URL, ICONS, dialogHeaderElement, dialogFooterContent, dialogFooterContentTwoButtons } from "../../common/globals";
import DialogDataProcessed from "../../components/DialogDataProcessed";
import DialogDataNotProcessed from "../../components/DialogDataNotProcessed";
import DialogListNotLoaded from "../../components/DialogListNotLoaded";
import DialogProcessingData from "../../components/DialogProcessingData";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function ListOperatorSessions() {
  const listOperatorSessionURL = BASE_URL + "operator/session/list";
  const deleteURL = BASE_URL + "operator/session/delete";

  const [list, setList] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showDeleteRowDialog, setShowDeleteRowDialog] = useState(null);
  const [showSelectedRowsWarningDialog, setShowSelectedRowsWarningDialog] = useState(false);
  const [showListNotLoadedDialog, setShowListNotLoadedDialog] = useState(null);
  const [showProcessingDialog, setShowProcessingDialog] = useState(null);
  const [showProcessedDialog, setShowProcessedDialog] = useState(null);
  const [showNotProcessedDialog, setShowNotProcessedDialog] = useState(null);

  const { auth } = useAuth();

  const navigate = useNavigate();

  // load operator generated documents sessions
  useEffect(() => {
    axios
      .post(
        listOperatorSessionURL,
        {},
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        if (null !== response && null !== response.data) {
          setList(response.data);
        } else {
          setShowListNotLoadedDialog(true);
        }
      })
      .catch((error) => {
        setShowListNotLoadedDialog(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <table>
          <tr>
            <td>
              <img
                class="imgtoolbar"
                src={require("../../static/images/buttons/" + ICONS.Delete)}
                alt="Şterge"
                title="Şterge"
                onClick={() => deleteRow(rowData)}
              />
            </td>
          </tr>
        </table>
      </React.Fragment>
    );
  };

  const deleteRow = (rowData) => {
    setSelectedRow({ ...rowData });
    setShowDeleteRowDialog(true);
  };

  const deleteListItem = async (e) => {
    e.preventDefault();
    setShowDeleteRowDialog(false);
    setShowProcessingDialog(true);
    axios
      .post(
        deleteURL,
        {
          uuid: selectedRow.uuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        if (null !== response) {
          const newList = list.filter(function (rowData) {
            return rowData.uuid !== selectedRow.uuid;
          });
          setList(newList);
          setShowProcessingDialog(false);
          setShowProcessedDialog(true);
        } else {
          setShowProcessingDialog(false);
          setShowNotProcessedDialog(true);
        }
      })
      .catch((error) => {
        setShowProcessingDialog(false);
        setShowNotProcessedDialog(true);
      });
  };

  // view operator generated documents status for selected session
  const viewDocuments = (e) => {
    e.preventDefault();
    if (selectedRow === null || selectedRow.length === 0) {
      setShowSelectedRowsWarningDialog(true);
      return;
    }
    sessionStorage.setItem("operatorSessionUuid", selectedRow.sessionUuid);
    navigate("/operator/generatedocsstatus");
  };

  return (
    <div className="clientArea">
      <Sidebar />
      <button class="buttonClassGreen">Reautorizări deservenți</button>
      <div class="spaceDiv" />
      <Link to={"/company/sessions"}>
        <img src={require("../../static/images/buttons/" + ICONS.CompanyGenerateDocuments)} alt="Documente firmă" title="Documente firmă" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        src={require("../../static/images/buttons/" + ICONS.OperatorGenerateDocumentsDisabled)}
        alt="Documente reautorizări deservenți"
        title="Documente reautorizări deservenți"
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/worker/sessions"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.WorkerGenerateDocuments)}
          alt="Documente certificări deservenți auxiliari"
          title="Documente certificări deservenți auxiliari"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/sessions"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingGenerateDocuments)}
          alt="Documente echipamente"
          title="Documente echipamente"
        />
      </Link>
      <div class="spaceDiv" />
      <div class="card">
        <DataTable
          value={list}
          selectionMode={null}
          selection={selectedRow}
          stripedRows
          paginator
          rows={25}
          rowsPerPageOptions={[10, 25, 50]}
          onSelectionChange={(e) => setSelectedRow(e.value)}
          dataKey="sessionUuid"
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column selectionMode="single" headerStyle={{ width: "3rem" }}></Column>
          <Column field="type" sortable header="Tip deservent"></Column>
          <Column field="date" sortable dataType="date" header="Data"></Column>
          <Column body={actionBodyTemplate}></Column>
        </DataTable>
      </div>
      <button class="longButtonClassGray" onClick={viewDocuments}>
        Vezi documente reautorizare deservenți
      </button>
      <Dialog
        visible={showSelectedRowsWarningDialog}
        modal
        header={dialogHeaderElement("Atenţie")}
        footer={dialogFooterContent("OK", () => setShowSelectedRowsWarningDialog(false))}
        style={{ width: "450px" }}
        onHide={() => setShowSelectedRowsWarningDialog(false)}
      >
        <p className="m-0">Selectează o reautorizare de deservenţi!</p>
      </Dialog>
      <Dialog
        visible={showDeleteRowDialog}
        modal
        header={dialogHeaderElement("Atenţie")}
        footer={dialogFooterContentTwoButtons("Da", deleteListItem, "Nu", () => setShowDeleteRowDialog(false))}
        style={{ width: "450px" }}
        onHide={() => setShowDeleteRowDialog(false)}
      >
        <p className="m-0">
          Doreşti să ştergi documentele pentru: {null != selectedRow ? selectedRow.type : ""} din data: {null != selectedRow ? selectedRow.date : ""}{" "}
          ?
        </p>
      </Dialog>
      <DialogProcessingData isVisible={showProcessingDialog} />
      <DialogDataProcessed
        isVisible={showProcessedDialog}
        footer={dialogFooterContent("OK", () => setShowProcessedDialog(false))}
        onHide={() => setShowProcessedDialog(false)}
      />
      <DialogDataNotProcessed
        isVisible={showNotProcessedDialog}
        footer={dialogFooterContent("OK", () => setShowNotProcessedDialog(false))}
        onHide={() => setShowNotProcessedDialog(false)}
      />
      <DialogListNotLoaded
        isVisible={showListNotLoadedDialog}
        footer={dialogFooterContent("OK", () => setShowListNotLoadedDialog(false))}
        onHide={() => setShowListNotLoadedDialog(false)}
      />
    </div>
  );
}
