import "../../static/css/pageLayout.css";

import { Accordion, AccordionTab } from "primereact/accordion";
import axios from "axios";
import { Checkbox } from "primereact/checkbox";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import DialogConfigurationNotProcessed from "../../components/DialogConfigurationNotProcessed";
import DialogProcessingData from "../../components/DialogProcessingData";
import DialogDataProcessed from "../../components/DialogDataProcessed";
import DialogDataNotProcessed from "../../components/DialogDataNotProcessed";
import { BASE_URL, ICONS, STRING_CONST, dialogFooterContent } from "../../common/globals";
import { FIELD_SIZE, isEmptyString, validateString, validateMinMaxText } from "../../common/validations";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";
import ProcessFormErrors from "../../components/ProcessFormErrors";

export default function AddCompanyBranch() {
  const baseURL = BASE_URL + "company/branch/add";
  const countyListURL = BASE_URL + "county/list";
  const townListURL = BASE_URL + "town/listbycounty";
  const companyListURL = BASE_URL + "company/listsimplecompanies";
  const uploadRsvtiAuthorisationURL = BASE_URL + "company/uploadsingleauthorisation";
  const uploadCompanyAndRsvtiAuthorisationURL = BASE_URL + "company/uploadbothauthorisations";

  const [ddOptionsCounty, setDdOptionsCounty] = useState([]);
  const [ddOptionsTown, setDdOptionsTown] = useState([]);
  const [ddOptionsCompany, setDdOptionsCompany] = useState([]);
  const [fileCompanyA, setFileCompanyA] = useState(null);
  const [fileRsvtiPersonA, setFileRsvtiPersonA] = useState(null);
  const [processingDialog, setProcessingDialog] = useState(null);
  const [processedDialog, setProcessedDialog] = useState(null);
  const [notProcessedDialog, setNotProcessedDialog] = useState(null);
  const [configNotProcessedDialog, setConfigNotProcessedDialog] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);

  const { auth } = useAuth();

  const [values, setValues] = useState({
    companyUuid: "",
    fiscalCode: "",
    code: "",
    phone: "",
    fax: "",
    email: "",
    companyMainContactLastName: "",
    companyMainContactFirstName: "",
    companyMainContactRole: "",
    countyUuid: "",
    townUuid: "",
    addressDetails1: "",
    addressDetails2: "",
    postalCode: "",
    otherAddressDetails: "",
    rsvtiOperatorCompanyName: "",
    rsvtiOperatorCompanyPermitNumber: "",
    rsvtiOperatorCompanyIssuedDate: "",
    rsvtiOperatorCompanyExpirationDate: "",
    rsvtiOperatorLastName: "",
    rsvtiOperatorFirstName: "",
    rsvtiOperatorPermitNumber: "",
    rsvtiOperatorPermitIssuedDate: "",
    rsvtiOperatorPermitExpirationDate: "",
    rsvtiOperatorPhone: "",
    rsvtiOperatorEmail: "",
    companyBranchRSVTIBoardMainPersonLastName: "",
    companyBranchRSVTIBoardMainPersonFirstName: "",
    companyBranchRSVTIBoardMainPersonRole: "",
    companyBranchRSVTIBoardMainPersonPhone: "",
    companyBranchRSVTIBoardMainPersonEmail: "",
    companyBranchRSVTIBoardSecondPersonLastName: "",
    companyBranchRSVTIBoardSecondPersonFirstName: "",
    companyBranchRSVTIBoardThirdPersonLastName: "",
    companyBranchRSVTIBoardThirdPersonFirstName: "",
    generateRsvtiDoc: "",
    generateCommissionDoc: "",
  });

  const inputs = [
    {
      id: 0,
      name: "companyUuid",
      type: "selectClass",
      placeholder: "Companie",
      label: "Companie",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 1,
      name: "fiscalCode",
      type: "text",
      placeholder: "Cod TVA",
      label: "Cod TVA",
    },
    {
      id: 2,
      name: "code",
      type: "text",
      placeholder: "Cod punct de lucru",
      label: "Cod punct de lucru",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 3,
      name: "phone",
      type: "text",
      placeholder: "Număr telefon fix",
      label: "Număr telefon fix",
    },
    {
      id: 4,
      name: "fax",
      type: "text",
      placeholder: "Număr fax",
      label: "Număr fax",
    },
    {
      id: 5,
      name: "email",
      type: "text",
      placeholder: "Adresa e-mail",
      label: "Adresa e-mail",
    },
    {
      id: 6,
      name: "companyMainContactLastName",
      type: "text",
      placeholder: "Nume familie reprezentant punct de lucru",
      label: "Nume familie reprezentant punct de lucru",
    },
    {
      id: 7,
      name: "companyMainContactFirstName",
      type: "text",
      placeholder: "Prenume reprezentant punct de lucru",
      label: "Prenume reprezentant punct de lucru",
    },
    {
      id: 8,
      name: "companyMainContactRole",
      type: "text",
      placeholder: "Funcție reprezentant punct de lucru",
      label: "Funcție reprezentant punct de lucru",
    },
    {
      id: 9,
      name: "countyUuid",
      type: "selectClass",
      placeholder: "Județ/sector",
      label: "Județ/sector",
    },
    {
      id: 10,
      name: "townUuid",
      type: "selectClass",
      placeholder: "Localitate",
      label: "Localitate",
    },
    {
      id: 11,
      name: "addressDetails1",
      type: "text",
      placeholder: "strada (bulevard, aleea, etc) si numarul",
      label: "Adresa 1",
    },
    {
      id: 12,
      name: "addressDetails2",
      type: "text",
      placeholder: "bloc, scara, etaj, apartment",
      label: "Adresa 2",
    },
    {
      id: 13,
      name: "postalCode",
      type: "text",
      placeholder: "Cod poștal",
      label: "Cod poștal",
    },
    {
      id: 14,
      name: "otherAddressDetails",
      type: "text",
      placeholder: "Alte detalii adresă",
      label: "Alte detalii adresă",
    },
    {
      id: 15,
      name: "rsvtiOperatorCompanyName",
      type: "text",
      placeholder: "Nume firmă operator RSVTI",
      label: "Nume firmă operator RSVTI",
    },
    {
      id: 16,
      name: "rsvtiOperatorCompanyPermitNumber",
      type: "text",
      placeholder: "Număr autorizaţie firmă operator RSVTI",
      label: "Număr autorizaţie firmă operator RSVTI",
    },
    {
      id: 17,
      name: "rsvtiOperatorCompanyIssuedDate",
      type: "date",
      placeholder: "Dată eliberare autorizaţie firmă operator RSVTI",
      label: "Dată eliberare autorizaţie firmă operator RSVTI",
    },
    {
      id: 18,
      name: "rsvtiOperatorCompanyExpirationDate",
      type: "date",
      placeholder: "Valabilitate autorizaţie firmă operator RSVTI",
      label: "Valabilitate autorizaţie firmă operator RSVTI",
    },
    {
      id: 19,
      name: "rsvtiOperatorLastName",
      type: "text",
      placeholder: "Nume familie operator RSVTI",
      label: "Nume familie operator RSVTI",
    },
    {
      id: 20,
      name: "rsvtiOperatorFirstName",
      type: "text",
      placeholder: "Prenume operator RSVTI",
      label: "Prenume operator RSVTI",
    },
    {
      id: 21,
      name: "rsvtiOperatorPermitNumber",
      type: "text",
      placeholder: "Număr autorizație operator RSVTI",
      label: "Număr autorizație operator RSVTI",
    },
    {
      id: 22,
      name: "rsvtiOperatorPermitIssuedDate",
      type: "date",
      placeholder: "Dată eliberare autorizație operator RSVTI",
      label: "Dată eliberare autorizație operator RSVTI",
    },
    {
      id: 23,
      name: "rsvtiOperatorPermitExpirationDate",
      type: "date",
      placeholder: "Valabilitate autorizație operator RSVTI",
      label: "Valabilitate autorizație operator RSVTI",
    },
    {
      id: 24,
      name: "rsvtiOperatorPhone",
      type: "text",
      placeholder: "Număr telefon mobil operator RSVTI",
      label: "Număr telefon mobil operator RSVTI",
    },
    {
      id: 25,
      name: "rsvtiOperatorEmail",
      type: "text",
      placeholder: "Adresă e-mail operator RSVTI",
      label: "Adresă e-mail operator RSVTI",
    },
    {
      id: 26,
      name: "companyBranchRSVTIBoardMainPersonLastName",
      type: "text",
      placeholder: "Nume familie responsabil",
      label: "Nume familie responsabil",
    },
    {
      id: 27,
      name: "companyBranchRSVTIBoardMainPersonFirstName",
      type: "text",
      placeholder: "Prenume responsabil",
      label: "Prenume responsabil",
    },
    {
      id: 28,
      name: "companyBranchRSVTIBoardMainPersonRole",
      type: "text",
      placeholder: "Funcție responsabil",
      label: "Funcție responsabil",
    },
    {
      id: 29,
      name: "companyBranchRSVTIBoardMainPersonPhone",
      type: "text",
      placeholder: "Număr telefon mobil persoană contact punct de lucru",
      label: "Număr telefon mobil persoană contact punct de lucru",
    },
    {
      id: 30,
      name: "companyBranchRSVTIBoardMainPersonEmail",
      type: "text",
      placeholder: "E-mail persoană contact punct de lucru",
      label: "E-mail persoană contact punct de lucru",
    },
    {
      id: 31,
      name: "companyBranchRSVTIBoardSecondPersonLastName",
      type: "text",
      placeholder: "Nume familie membru 2 comisie tehnică",
      label: "Nume familie  membru 2 comisie tehnică",
    },
    {
      id: 32,
      name: "companyBranchRSVTIBoardSecondPersonFirstName",
      type: "text",
      placeholder: "Prenume  membru 2 comisie tehnică",
      label: "Prenume  membru 2 comisie tehnică",
    },
    {
      id: 33,
      name: "companyBranchRSVTIBoardThirdPersonLastName",
      type: "text",
      placeholder: "Nume familie membru 3 comisie tehnică",
      label: "Nume familie membru 3 comisie tehnică",
    },
    {
      id: 34,
      name: "companyBranchRSVTIBoardThirdPersonFirstName",
      type: "text",
      placeholder: "Prenume membru 3 comisie tehnică",
      label: "Prenume membru 3 comisie tehnică",
    },
  ];

  const FIELD = {
    companyUuid: 0,
    fiscalCode: 1,
    code: 2,
    phone: 3,
    fax: 4,
    email: 5,
    companyMainContactLastName: 6,
    companyMainContactFirstName: 7,
    companyMainContactRole: 8,
    countyUuid: 9,
    townUuid: 10,
    addressDetails1: 11,
    addressDetails2: 12,
    postalCode: 13,
    otherAddressDetails: 14,
    rsvtiOperatorCompanyName: 15,
    rsvtiOperatorCompanyPermitNumber: 16,
    rsvtiOperatorCompanyIssuedDate: 17,
    rsvtiOperatorCompanyExpirationDate: 18,
    rsvtiOperatorLastName: 19,
    rsvtiOperatorFirstName: 20,
    rsvtiOperatorPermitNumber: 21,
    rsvtiOperatorPermitIssuedDate: 22,
    rsvtiOperatorPermitExpirationDate: 23,
    rsvtiOperatorPhone: 24,
    rsvtiOperatorEmail: 25,
    companyBranchRSVTIBoardMainPersonLastName: 26,
    companyBranchRSVTIBoardMainPersonFirstName: 27,
    companyBranchRSVTIBoardMainPersonRole: 28,
    companyBranchRSVTIBoardMainPersonPhone: 29,
    companyBranchRSVTIBoardMainPersonEmail: 30,
    companyBranchRSVTIBoardSecondPersonLastName: 31,
    companyBranchRSVTIBoardSecondPersonFirstName: 32,
    companyBranchRSVTIBoardThirdPersonLastName: 33,
    companyBranchRSVTIBoardThirdPersonFirstName: 34,
  };

  // load companies and counties
  useEffect(() => {
    axios
      .get(companyListURL, {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        setDdOptionsCompany(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
    axios
      .get(countyListURL, {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        setDdOptionsCounty(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeCounty = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value, townUuid: "" });
    setDdOptionsTown([]);
    axios
      .post(
        townListURL,
        {
          uuid: e.target.value,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setDdOptionsTown(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setProcessingDialog(true);
    if (!validateForm()) {
      setProcessingDialog(false);
      return;
    }
    axios
      .post(
        baseURL,
        {
          companyUuid: values.companyUuid,
          fiscalCode: values.fiscalCode,
          code: values.code,
          phone: values.phone,
          fax: values.fax,
          email: values.email,
          companyMainContactLastName: values.companyMainContactLastName,
          companyMainContactFirstName: values.companyMainContactFirstName,
          companyMainContactRole: values.companyMainContactRole,
          countyUuid: values.countyUuid,
          townUuid: values.townUuid,
          addressDetails1: values.addressDetails1,
          addressDetails2: values.addressDetails2,
          postalCode: values.postalCode,
          otherAddressDetails: values.otherAddressDetails,
          rsvtiOperatorCompanyName: values.rsvtiOperatorCompanyName,
          rsvtiOperatorCompanyPermitNumber: values.rsvtiOperatorCompanyPermitNumber,
          rsvtiOperatorCompanyIssuedDate: values.rsvtiOperatorCompanyIssuedDate,
          rsvtiOperatorCompanyExpirationDate: values.rsvtiOperatorCompanyExpirationDate,
          rsvtiOperatorLastName: values.rsvtiOperatorLastName,
          rsvtiOperatorFirstName: values.rsvtiOperatorFirstName,
          rsvtiOperatorPermitNumber: values.rsvtiOperatorPermitNumber,
          rsvtiOperatorPermitIssuedDate: values.rsvtiOperatorPermitIssuedDate,
          rsvtiOperatorPermitExpirationDate: values.rsvtiOperatorPermitExpirationDate,
          rsvtiOperatorPhone: values.rsvtiOperatorPhone,
          rsvtiOperatorEmail: values.rsvtiOperatorEmail,
          companyBranchRSVTIBoardMainPersonLastName: values.companyBranchRSVTIBoardMainPersonLastName,
          companyBranchRSVTIBoardMainPersonFirstName: values.companyBranchRSVTIBoardMainPersonFirstName,
          companyBranchRSVTIBoardMainPersonRole: values.companyBranchRSVTIBoardMainPersonRole,
          companyBranchRSVTIBoardMainPersonPhone: values.companyBranchRSVTIBoardMainPersonPhone,
          companyBranchRSVTIBoardMainPersonEmail: values.companyBranchRSVTIBoardMainPersonEmail,
          companyBranchRSVTIBoardSecondPersonLastName: values.companyBranchRSVTIBoardSecondPersonLastName,
          companyBranchRSVTIBoardSecondPersonFirstName: values.companyBranchRSVTIBoardSecondPersonFirstName,
          companyBranchRSVTIBoardThirdPersonLastName: values.companyBranchRSVTIBoardThirdPersonLastName,
          companyBranchRSVTIBoardThirdPersonFirstName: values.companyBranchRSVTIBoardThirdPersonFirstName,
          generateRsvtiDoc: values.generateRsvtiDoc,
          generateCommissionDoc: values.generateCommissionDoc,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((res) => {
        uploadAuthorisations(res.data);
        let file = document.querySelector(".fileAF");
        if (file !== null) {
          file.value = "";
        }
        file = document.querySelector(".fileAP");
        if (file !== null) {
          file.value = "";
        }
      })
      .catch((error) => {
        setProcessingDialog(false);
        setNotProcessedDialog(true);
      });
    // console.log("Submit");
  };

  const validateForm = () => {
    let result = true;
    let data = [];
    if (isEmptyString(values.companyUuid)) {
      data.push({ id: inputs[FIELD.companyUuid].id, value: inputs[FIELD.companyUuid].label + STRING_CONST.VALIDATION_IS_MANDATORY });
      result = false;
    }
    if (isEmptyString(values.code)) {
      data.push({ id: inputs[FIELD.code].id, value: inputs[FIELD.code].label + STRING_CONST.VALIDATION_IS_MANDATORY });
      result = false;
    } else if (!validateString(values.code, FIELD_SIZE.CompanyCodeMin, FIELD_SIZE.CompanyCodeMax)) {
      data.push({
        id: inputs[FIELD.code].id,
        value: inputs[FIELD.code].label + validateMinMaxText(FIELD_SIZE.CompanyCodeMin, FIELD_SIZE.CompanyCodeMax),
      });
      result = false;
    }
    if (!validateString(values.fiscalCode, FIELD_SIZE.Zero, FIELD_SIZE.CompanyFiscalCodeMax)) {
      data.push({
        id: inputs[FIELD.fiscalCode].id,
        value: inputs[FIELD.fiscalCode].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.CompanyFiscalCodeMax),
      });
      result = false;
    }
    if (!validateString(values.phone, FIELD_SIZE.Zero, FIELD_SIZE.PhoneMax)) {
      data.push({
        id: inputs[FIELD.phone].id,
        value: inputs[FIELD.phone].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.PhoneMax),
      });
      result = false;
    }
    if (!validateString(values.fax, FIELD_SIZE.Zero, FIELD_SIZE.FaxMax)) {
      data.push({
        id: inputs[FIELD.fax].id,
        value: inputs[FIELD.fax].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.FaxMax),
      });
      result = false;
    }
    if (!validateString(values.email, FIELD_SIZE.Zero, FIELD_SIZE.EmailMax)) {
      data.push({
        id: inputs[FIELD.email].id,
        value: inputs[FIELD.email].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.EmailMax),
      });
      result = false;
    }
    if (
      !isEmptyString(values.companyMainContactLastName) ||
      !isEmptyString(values.companyMainContactFirstName) ||
      !isEmptyString(values.companyMainContactRole)
    ) {
      if (isEmptyString(values.companyMainContactLastName)) {
        data.push({
          id: inputs[FIELD.companyMainContactLastName].id,
          value: inputs[FIELD.companyMainContactLastName].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_COMPANY_MAIN_PERSON,
        });
        result = false;
      }
      if (isEmptyString(values.companyMainContactFirstName)) {
        data.push({
          id: inputs[FIELD.companyMainContactFirstName].id,
          value: inputs[FIELD.companyMainContactFirstName].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_COMPANY_MAIN_PERSON,
        });
        result = false;
      }
      if (isEmptyString(values.companyMainContactRole)) {
        data.push({
          id: inputs[FIELD.companyMainContactRole].id,
          value: inputs[FIELD.companyMainContactRole].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_COMPANY_MAIN_PERSON,
        });
        result = false;
      }
    }
    if (!validateString(values.companyMainContactLastName, FIELD_SIZE.Zero, FIELD_SIZE.LastNameMax)) {
      data.push({
        id: inputs[FIELD.companyMainContactLastName].id,
        value: inputs[FIELD.companyMainContactLastName].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.LastNameMax),
      });
      result = false;
    }
    if (!validateString(values.companyMainContactFirstName, FIELD_SIZE.Zero, FIELD_SIZE.FirstNameMax)) {
      data.push({
        id: inputs[FIELD.companyMainContactFirstName].id,
        value: inputs[FIELD.companyMainContactFirstName].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.FirstNameMax),
      });
      result = false;
    }
    if (!validateString(values.companyMainContactRole, FIELD_SIZE.Zero, FIELD_SIZE.PersonRoleMax)) {
      data.push({
        id: inputs[FIELD.companyMainContactRole].id,
        value: inputs[FIELD.companyMainContactRole].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.PersonRoleMax),
      });
      result = false;
    }
    if (!isEmptyString(values.countyUuid) || !isEmptyString(values.townUuid) || !isEmptyString(values.addressDetails1)) {
      if (isEmptyString(values.countyUuid)) {
        data.push({
          id: inputs[FIELD.countyUuid].id,
          value: inputs[FIELD.countyUuid].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_ADDRESS,
        });
        result = false;
      }
      if (isEmptyString(values.townUuid)) {
        data.push({
          id: inputs[FIELD.townUuid].id,
          value: inputs[FIELD.townUuid].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_ADDRESS,
        });
        result = false;
      }
      if (isEmptyString(values.addressDetails1)) {
        data.push({
          id: inputs[FIELD.addressDetails1].id,
          value: inputs[FIELD.addressDetails1].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_ADDRESS,
        });
        result = false;
      }
    }
    if (!validateString(values.addressDetails1, FIELD_SIZE.Zero, FIELD_SIZE.AddressDetails1Max)) {
      data.push({
        id: inputs[FIELD.addressDetails1].id,
        value: inputs[FIELD.addressDetails1].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.AddressDetails1Max),
      });
      result = false;
    }
    if (!validateString(values.addressDetails2, FIELD_SIZE.Zero, FIELD_SIZE.AddressDetails2Max)) {
      data.push({
        id: inputs[FIELD.addressDetails2].id,
        value: inputs[FIELD.addressDetails2].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.AddressDetails2Max),
      });
      result = false;
    }
    if (!validateString(values.postalCode, FIELD_SIZE.Zero, FIELD_SIZE.PostalCodeMax)) {
      data.push({
        id: inputs[FIELD.postalCode].id,
        value: inputs[FIELD.postalCode].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.PostalCodeMax),
      });
      result = false;
    }
    if (!validateString(values.otherAddressDetails, FIELD_SIZE.Zero, FIELD_SIZE.OtherAddressDetailsMax)) {
      data.push({
        id: inputs[FIELD.otherAddressDetails].id,
        value: inputs[FIELD.otherAddressDetails].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.OtherAddressDetailsMax),
      });
      result = false;
    }
    if (
      !isEmptyString(values.rsvtiOperatorCompanyName) ||
      !isEmptyString(values.rsvtiOperatorCompanyPermitNumber) ||
      !isEmptyString(values.rsvtiOperatorCompanyIssuedDate) ||
      !isEmptyString(values.rsvtiOperatorCompanyExpirationDate)
    ) {
      if (isEmptyString(values.rsvtiOperatorCompanyName)) {
        data.push({
          id: inputs[FIELD.rsvtiOperatorCompanyName].id,
          value: inputs[FIELD.rsvtiOperatorCompanyName].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_RSVTI_OPERATOR_COMPANY,
        });
        result = false;
      }
      if (isEmptyString(values.rsvtiOperatorCompanyPermitNumber)) {
        data.push({
          id: inputs[FIELD.rsvtiOperatorCompanyPermitNumber].id,
          value: inputs[FIELD.rsvtiOperatorCompanyPermitNumber].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_RSVTI_OPERATOR_COMPANY,
        });
        result = false;
      }
      if (isEmptyString(values.rsvtiOperatorCompanyIssuedDate)) {
        data.push({
          id: inputs[FIELD.rsvtiOperatorCompanyIssuedDate].id,
          value: inputs[FIELD.rsvtiOperatorCompanyIssuedDate].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_RSVTI_OPERATOR_COMPANY,
        });
        result = false;
      }
      if (isEmptyString(values.rsvtiOperatorCompanyExpirationDate)) {
        data.push({
          id: inputs[FIELD.rsvtiOperatorCompanyExpirationDate].id,
          value: inputs[FIELD.rsvtiOperatorCompanyExpirationDate].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_RSVTI_OPERATOR_COMPANY,
        });
        result = false;
      }
    }
    if (!validateString(values.rsvtiOperatorCompanyName, FIELD_SIZE.Zero, FIELD_SIZE.RsvtiOperatorCompanyNameMax)) {
      data.push({
        id: inputs[FIELD.rsvtiOperatorCompanyName].id,
        value: inputs[FIELD.rsvtiOperatorCompanyName].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.RsvtiOperatorCompanyNameMax),
      });
      result = false;
    }
    if (!validateString(values.rsvtiOperatorCompanyPermitNumber, FIELD_SIZE.Zero, FIELD_SIZE.RsvtiOperatorCompanyPermitNumberMax)) {
      data.push({
        id: inputs[FIELD.rsvtiOperatorCompanyPermitNumber].id,
        value:
          inputs[FIELD.rsvtiOperatorCompanyPermitNumber].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.RsvtiOperatorCompanyPermitNumberMax),
      });
      result = false;
    }
    if (
      !isEmptyString(values.rsvtiOperatorLastName) ||
      !isEmptyString(values.rsvtiOperatorFirstName) ||
      !isEmptyString(values.rsvtiOperatorPermitNumber) ||
      !isEmptyString(values.rsvtiOperatorPermitIssuedDate) ||
      !isEmptyString(values.rsvtiOperatorPermitExpirationDate)
    ) {
      if (isEmptyString(values.rsvtiOperatorLastName)) {
        data.push({
          id: inputs[FIELD.rsvtiOperatorLastName].id,
          value: inputs[FIELD.rsvtiOperatorLastName].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_RSVTI_OPERATOR_PERSON,
        });
        result = false;
      }
      if (isEmptyString(values.rsvtiOperatorFirstName)) {
        data.push({
          id: inputs[FIELD.rsvtiOperatorFirstName].id,
          value: inputs[FIELD.rsvtiOperatorFirstName].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_RSVTI_OPERATOR_PERSON,
        });
        result = false;
      }
      if (isEmptyString(values.rsvtiOperatorPermitNumber)) {
        data.push({
          id: inputs[FIELD.rsvtiOperatorPermitNumber].id,
          value: inputs[FIELD.rsvtiOperatorPermitNumber].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_RSVTI_OPERATOR_PERSON,
        });
        result = false;
      }
      if (isEmptyString(values.rsvtiOperatorPermitIssuedDate)) {
        data.push({
          id: inputs[FIELD.rsvtiOperatorPermitIssuedDate].id,
          value: inputs[FIELD.rsvtiOperatorPermitIssuedDate].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_RSVTI_OPERATOR_PERSON,
        });
        result = false;
      }
      if (isEmptyString(values.rsvtiOperatorPermitExpirationDate)) {
        data.push({
          id: inputs[FIELD.rsvtiOperatorPermitExpirationDate].id,
          value: inputs[FIELD.rsvtiOperatorPermitExpirationDate].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_RSVTI_OPERATOR_PERSON,
        });
        result = false;
      }
    }
    if (!validateString(values.rsvtiOperatorLastName, FIELD_SIZE.Zero, FIELD_SIZE.LastNameMax)) {
      data.push({
        id: inputs[FIELD.rsvtiOperatorLastName].id,
        value: inputs[FIELD.rsvtiOperatorLastName].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.LastNameMax),
      });
      result = false;
    }
    if (!validateString(values.rsvtiOperatorFirstName, FIELD_SIZE.Zero, FIELD_SIZE.FirstNameMax)) {
      data.push({
        id: inputs[FIELD.rsvtiOperatorFirstName].id,
        value: inputs[FIELD.rsvtiOperatorFirstName].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.FirstNameMax),
      });
      result = false;
    }
    if (!validateString(values.rsvtiOperatorPermitNumber, FIELD_SIZE.Zero, FIELD_SIZE.RsvtiOperatorPermitNumberMax)) {
      data.push({
        id: inputs[FIELD.rsvtiOperatorPermitNumber].id,
        value: inputs[FIELD.rsvtiOperatorPermitNumber].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.RsvtiOperatorPermitNumberMax),
      });
      result = false;
    }
    if (!validateString(values.rsvtiOperatorPhone, FIELD_SIZE.Zero, FIELD_SIZE.PhoneMax)) {
      data.push({
        id: inputs[FIELD.rsvtiOperatorPhone].id,
        value: inputs[FIELD.rsvtiOperatorPhone].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.PhoneMax),
      });
      result = false;
    }
    if (!validateString(values.rsvtiOperatorEmail, FIELD_SIZE.Zero, FIELD_SIZE.EmailMax)) {
      data.push({
        id: inputs[FIELD.rsvtiOperatorEmail].id,
        value: inputs[FIELD.rsvtiOperatorEmail].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.EmailMax),
      });
      result = false;
    }
    if (
      !isEmptyString(values.companyBranchRSVTIBoardMainPersonLastName) ||
      !isEmptyString(values.companyBranchRSVTIBoardMainPersonFirstName) ||
      !isEmptyString(values.companyBranchRSVTIBoardMainPersonRole)
    ) {
      if (isEmptyString(values.companyBranchRSVTIBoardMainPersonLastName)) {
        data.push({
          id: inputs[FIELD.companyBranchRSVTIBoardMainPersonLastName].id,
          value: inputs[FIELD.companyBranchRSVTIBoardMainPersonLastName].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_SIGNER_PERSON,
        });
        result = false;
      }
      if (isEmptyString(values.companyBranchRSVTIBoardMainPersonFirstName)) {
        data.push({
          id: inputs[FIELD.companyBranchRSVTIBoardMainPersonFirstName].id,
          value: inputs[FIELD.companyBranchRSVTIBoardMainPersonFirstName].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_SIGNER_PERSON,
        });
        result = false;
      }
      if (isEmptyString(values.companyBranchRSVTIBoardMainPersonRole)) {
        data.push({
          id: inputs[FIELD.companyBranchRSVTIBoardMainPersonRole].id,
          value: inputs[FIELD.companyBranchRSVTIBoardMainPersonRole].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_SIGNER_PERSON,
        });
        result = false;
      }
    }
    if (!validateString(values.companyBranchRSVTIBoardMainPersonLastName, FIELD_SIZE.Zero, FIELD_SIZE.LastNameMax)) {
      data.push({
        id: inputs[FIELD.companyBranchRSVTIBoardMainPersonLastName].id,
        value: inputs[FIELD.companyBranchRSVTIBoardMainPersonLastName].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.LastNameMax),
      });
      result = false;
    }
    if (!validateString(values.companyBranchRSVTIBoardMainPersonFirstName, FIELD_SIZE.Zero, FIELD_SIZE.FirstNameMax)) {
      data.push({
        id: inputs[FIELD.companyBranchRSVTIBoardMainPersonFirstName].id,
        value: inputs[FIELD.companyBranchRSVTIBoardMainPersonFirstName].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.FirstNameMax),
      });
      result = false;
    }
    if (!validateString(values.companyBranchRSVTIBoardMainPersonRole, FIELD_SIZE.Zero, FIELD_SIZE.PersonRoleMax)) {
      data.push({
        id: inputs[FIELD.companyBranchRSVTIBoardMainPersonRole].id,
        value: inputs[FIELD.companyBranchRSVTIBoardMainPersonRole].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.PersonRoleMax),
      });
      result = false;
    }
    if (!validateString(values.companyBranchRSVTIBoardMainPersonPhone, FIELD_SIZE.Zero, FIELD_SIZE.PhoneMax)) {
      data.push({
        id: inputs[FIELD.companyBranchRSVTIBoardMainPersonPhone].id,
        value: inputs[FIELD.companyBranchRSVTIBoardMainPersonPhone].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.PhoneMax),
      });
      result = false;
    }
    if (!validateString(values.companyBranchRSVTIBoardMainPersonEmail, FIELD_SIZE.Zero, FIELD_SIZE.EmailMax)) {
      data.push({
        id: inputs[FIELD.companyBranchRSVTIBoardMainPersonEmail].id,
        value: inputs[FIELD.companyBranchRSVTIBoardMainPersonEmail].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.EmailMax),
      });
      result = false;
    }
    if (!isEmptyString(values.companyBranchRSVTIBoardSecondPersonLastName) || !isEmptyString(values.companyBranchRSVTIBoardSecondPersonFirstName)) {
      if (isEmptyString(values.companyBranchRSVTIBoardSecondPersonLastName)) {
        data.push({
          id: inputs[FIELD.companyBranchRSVTIBoardSecondPersonLastName].id,
          value: inputs[FIELD.companyBranchRSVTIBoardSecondPersonLastName].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_MEMBER_2,
        });
        result = false;
      }
      if (isEmptyString(values.companyBranchRSVTIBoardSecondPersonFirstName)) {
        data.push({
          id: inputs[FIELD.companyBranchRSVTIBoardSecondPersonFirstName].id,
          value: inputs[FIELD.companyBranchRSVTIBoardSecondPersonFirstName].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_MEMBER_2,
        });
        result = false;
      }
    }
    if (!validateString(values.companyBranchRSVTIBoardSecondPersonLastName, FIELD_SIZE.Zero, FIELD_SIZE.LastNameMax)) {
      data.push({
        id: inputs[FIELD.companyBranchRSVTIBoardSecondPersonLastName].id,
        value: inputs[FIELD.companyBranchRSVTIBoardSecondPersonLastName].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.LastNameMax),
      });
      result = false;
    }
    if (!validateString(values.companyBranchRSVTIBoardSecondPersonFirstName, FIELD_SIZE.Zero, FIELD_SIZE.FirstNameMax)) {
      data.push({
        id: inputs[FIELD.companyBranchRSVTIBoardSecondPersonFirstName].id,
        value: inputs[FIELD.companyBranchRSVTIBoardSecondPersonFirstName].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.FirstNameMax),
      });
      result = false;
    }
    if (!isEmptyString(values.companyBranchRSVTIBoardThirdPersonLastName) || !isEmptyString(values.companyBranchRSVTIBoardThirdPersonFirstName)) {
      if (isEmptyString(values.companyBranchRSVTIBoardThirdPersonLastName)) {
        data.push({
          id: inputs[FIELD.companyBranchRSVTIBoardThirdPersonLastName].id,
          value: inputs[FIELD.companyBranchRSVTIBoardThirdPersonLastName].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_MEMBER_3,
        });
        result = false;
      }
      if (isEmptyString(values.companyBranchRSVTIBoardThirdPersonFirstName)) {
        data.push({
          id: inputs[FIELD.companyBranchRSVTIBoardThirdPersonFirstName].id,
          value: inputs[FIELD.companyBranchRSVTIBoardThirdPersonFirstName].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_MEMBER_3,
        });
        result = false;
      }
    }
    if (!validateString(values.companyBranchRSVTIBoardThirdPersonLastName, FIELD_SIZE.Zero, FIELD_SIZE.LastNameMax)) {
      data.push({
        id: inputs[FIELD.companyBranchRSVTIBoardThirdPersonLastName].id,
        value: inputs[FIELD.companyBranchRSVTIBoardThirdPersonLastName].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.LastNameMax),
      });
      result = false;
    }
    if (!validateString(values.companyBranchRSVTIBoardThirdPersonFirstName, FIELD_SIZE.Zero, FIELD_SIZE.FirstNameMax)) {
      data.push({
        id: inputs[FIELD.companyBranchRSVTIBoardThirdPersonFirstName].id,
        value: inputs[FIELD.companyBranchRSVTIBoardThirdPersonFirstName].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.FirstNameMax),
      });
      result = false;
    }
    setValidationErrors(data);
    return result;
  };

  const uploadAuthorisations = (rsvtiOperatorUuid) => {
    if (fileRsvtiPersonA === null) {
      setProcessingDialog(false);
      setProcessedDialog(true);
      return;
    }
    const formData = new FormData();
    formData.append("rsvtiOperatorUuid", rsvtiOperatorUuid);
    formData.append("fileRsvtiPersonA", fileRsvtiPersonA);
    let URL = uploadCompanyAndRsvtiAuthorisationURL;
    if (fileCompanyA === null) {
      URL = uploadRsvtiAuthorisationURL;
    } else {
      formData.append("fileCompanyA", fileCompanyA);
    }
    axios
      .post(URL, formData, {
        "content-type": "multipart/form-data",
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((res) => {
        setProcessingDialog(false);
        setProcessedDialog(true);
      })
      .catch((error) => {
        setProcessingDialog(false);
        setNotProcessedDialog(true);
      });
    // console.log("Submit");
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onChangeCheckBox = (e) => {
    if (e.target.checked) {
      setValues({ ...values, [e.target.name]: e.target.value });
    } else {
      setValues({ ...values, [e.target.name]: "" });
    }
  };

  return (
    <div className="clientArea">
      <Sidebar />
      {/* Navigation Buttons */}
      <button class="buttonClassGreen">Adaugă punct de lucru</button>
      <div class="spaceDiv" />
      <Link to={"/companyBranch/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.CompanyBranchList)} alt="Listă puncte de lucru" title="Listă puncte de lucru" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img src={require("../../static/images/buttons/" + ICONS.CompanyBranchAddDisabled)} alt="Adaugă punct de lucru" title="Adaugă punct de lucru" />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/companyBranch/import"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.CompanyBranchImport)}
          alt="Importă firme/puncte de lucru"
          title="Importă firme/puncte de lucru"
        />
      </Link>
      <form class="formArea">
        {/* Header Company */}
        <div class="rowdouble">
          <Accordion multiple activeIndex={[0]}>
            <AccordionTab header="Detalii punct de lucru (doar dacă este înregistrat ca sediu secundar)">
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.companyUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.companyUuid].id}
                    name={inputs[FIELD.companyUuid].name}
                    label={inputs[FIELD.companyUuid].label}
                    value={values[inputs[FIELD.companyUuid].name]}
                    mandatory={inputs[FIELD.companyUuid].mandatory}
                    ddOptions={ddOptionsCompany}
                    onChange={onChange}
                  />
                </div>
                <div class="column">
                  <p>&nbsp;</p>
                </div>
              </div>

              {/* Fiscal Code */}
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.fiscalCode].id}>
                  <FormInput
                    key={inputs[FIELD.fiscalCode].id}
                    {...inputs[FIELD.fiscalCode]}
                    value={values[inputs[FIELD.fiscalCode].name]}
                    onChange={onChange}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.code].id}>
                  <FormInput key={inputs[FIELD.code].id} {...inputs[FIELD.code]} value={values[inputs[FIELD.code].name]} onChange={onChange} />
                </div>
              </div>

              {/* Phone and Fax */}
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.phone].id}>
                  <FormInput key={inputs[FIELD.phone].id} {...inputs[FIELD.phone]} value={values[inputs[FIELD.phone].name]} onChange={onChange} />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.fax].id}>
                  <FormInput key={inputs[FIELD.fax].id} {...inputs[FIELD.fax]} value={values[inputs[FIELD.fax].name]} onChange={onChange} />
                </div>
              </div>

              {/* Email */}
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.email].id}>
                  <FormInput key={inputs[FIELD.email].id} {...inputs[FIELD.email]} value={values[inputs[FIELD.email].name]} onChange={onChange} />
                </div>
                <div class="column">
                  <p>&nbsp;</p>
                </div>
              </div>
            </AccordionTab>
            <AccordionTab header="Detalii reprezentant punct de lucru">
              {/* Main Contact Last Name and Main Contact First Name */}
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.companyMainContactLastName].id}>
                  <FormInput
                    key={inputs[FIELD.companyMainContactLastName].id}
                    {...inputs[FIELD.companyMainContactLastName]}
                    value={values[inputs[FIELD.companyMainContactLastName].name]}
                    onChange={onChange}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.companyMainContactFirstName].id}>
                  <FormInput
                    key={inputs[FIELD.companyMainContactFirstName].id}
                    {...inputs[FIELD.companyMainContactFirstName]}
                    value={values[inputs[FIELD.companyMainContactFirstName].name]}
                    onChange={onChange}
                  />
                </div>
              </div>

              {/* Main Contact Role */}
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.companyMainContactRole].id}>
                  <FormInput
                    key={inputs[FIELD.companyMainContactRole].id}
                    {...inputs[FIELD.companyMainContactRole]}
                    value={values[inputs[FIELD.companyMainContactRole].name]}
                    onChange={onChange}
                  />
                </div>
                <div class="column">
                  <p>&nbsp;</p>
                </div>
              </div>
            </AccordionTab>
            <AccordionTab header="Detalii înregistrare adresă punct de lucru">
              {/* County and Town */}
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.countyUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.countyUuid].id}
                    name={inputs[FIELD.countyUuid].name}
                    label={inputs[FIELD.countyUuid].label}
                    value={values[inputs[FIELD.countyUuid].name]}
                    ddOptions={ddOptionsCounty}
                    onChange={onChangeCounty}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.townUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.townUuid].id}
                    name={inputs[FIELD.townUuid].name}
                    label={inputs[FIELD.townUuid].label}
                    value={values[inputs[FIELD.townUuid].name]}
                    ddOptions={ddOptionsTown}
                    onChange={onChange}
                  />
                </div>
              </div>

              {/* Address Details 1 and Address Details 2 */}
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.addressDetails1].id}>
                  <FormInput
                    key={inputs[FIELD.addressDetails1].id}
                    {...inputs[FIELD.addressDetails1]}
                    value={values[inputs[FIELD.addressDetails1].name]}
                    onChange={onChange}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.addressDetails2].id}>
                  <FormInput
                    key={inputs[FIELD.addressDetails2].id}
                    {...inputs[FIELD.addressDetails2]}
                    value={values[inputs[FIELD.addressDetails2].name]}
                    onChange={onChange}
                  />
                </div>
              </div>

              {/* Postal Code */}
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.postalCode].id}>
                  <FormInput
                    key={inputs[FIELD.postalCode].id}
                    {...inputs[FIELD.postalCode]}
                    value={values[inputs[FIELD.postalCode].name]}
                    onChange={onChange}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.otherAddressDetails].id}>
                  <FormInput
                    key={inputs[FIELD.otherAddressDetails].id}
                    {...inputs[FIELD.otherAddressDetails]}
                    value={values[inputs[FIELD.otherAddressDetails].name]}
                    onChange={onChange}
                  />
                </div>
              </div>
            </AccordionTab>
            <AccordionTab header="Detalii înregistrare operator RSVTI (punct de lucru)">
              {/* RSVTI Operator company name and permit number */}
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.rsvtiOperatorCompanyName].id}>
                  <FormInput
                    key={inputs[FIELD.rsvtiOperatorCompanyName].id}
                    {...inputs[FIELD.rsvtiOperatorCompanyName]}
                    value={values[inputs[FIELD.rsvtiOperatorCompanyName].name]}
                    onChange={onChange}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.rsvtiOperatorCompanyPermitNumber].id}>
                  <FormInput
                    key={inputs[FIELD.rsvtiOperatorCompanyPermitNumber].id}
                    {...inputs[FIELD.rsvtiOperatorCompanyPermitNumber]}
                    value={values[inputs[FIELD.rsvtiOperatorCompanyPermitNumber].name]}
                    onChange={onChange}
                  />
                </div>
              </div>

              {/* RSVTI Operator company permit issued date and expiration date */}
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.rsvtiOperatorCompanyIssuedDate].id}>
                  <FormInput
                    key={inputs[FIELD.rsvtiOperatorCompanyIssuedDate].id}
                    {...inputs[FIELD.rsvtiOperatorCompanyIssuedDate]}
                    value={values[inputs[FIELD.rsvtiOperatorCompanyIssuedDate].name]}
                    onChange={onChange}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.rsvtiOperatorCompanyExpirationDate].id}>
                  <FormInput
                    key={inputs[FIELD.rsvtiOperatorCompanyExpirationDate].id}
                    {...inputs[FIELD.rsvtiOperatorCompanyExpirationDate]}
                    value={values[inputs[FIELD.rsvtiOperatorCompanyExpirationDate].name]}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div class="row">
                <div class="column">
                  Adaugă poză/pdf autorizaţie firmă operator RSVTI:{" "}
                  <input type="file" class="fileAF" onChange={(e) => setFileCompanyA(e.target.files[0])} />
                </div>
                <div class="column">&nbsp;</div>
              </div>

              {/* RSVTI Operator Lastname and firstname */}
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.rsvtiOperatorLastName].id}>
                  <FormInput
                    key={inputs[FIELD.rsvtiOperatorLastName].id}
                    {...inputs[FIELD.rsvtiOperatorLastName]}
                    value={values[inputs[FIELD.rsvtiOperatorLastName].name]}
                    onChange={onChange}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.rsvtiOperatorFirstName].id}>
                  <FormInput
                    key={inputs[FIELD.rsvtiOperatorFirstName].id}
                    {...inputs[FIELD.rsvtiOperatorFirstName]}
                    value={values[inputs[FIELD.rsvtiOperatorFirstName].name]}
                    onChange={onChange}
                  />
                </div>
              </div>

              {/* RSVTI Operator Permit number */}
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.rsvtiOperatorPermitNumber].id}>
                  <FormInput
                    key={inputs[FIELD.rsvtiOperatorPermitNumber].id}
                    {...inputs[FIELD.rsvtiOperatorPermitNumber]}
                    value={values[inputs[FIELD.rsvtiOperatorPermitNumber].name]}
                    onChange={onChange}
                  />
                </div>
                <div class="column">
                  <p>&nbsp;</p>
                </div>
              </div>

              {/* RSVTI Operator Permit issued date and expiration date */}
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.rsvtiOperatorPermitIssuedDate].id}>
                  <FormInput
                    key={inputs[FIELD.rsvtiOperatorPermitIssuedDate].id}
                    {...inputs[FIELD.rsvtiOperatorPermitIssuedDate]}
                    value={values[inputs[FIELD.rsvtiOperatorPermitIssuedDate].name]}
                    onChange={onChange}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.rsvtiOperatorPermitExpirationDate].id}>
                  <FormInput
                    key={inputs[FIELD.rsvtiOperatorPermitExpirationDate].id}
                    {...inputs[FIELD.rsvtiOperatorPermitExpirationDate]}
                    value={values[inputs[FIELD.rsvtiOperatorPermitExpirationDate].name]}
                    onChange={onChange}
                  />
                </div>
              </div>

              {/* RSVTI Operator phone and email */}
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.rsvtiOperatorPhone].id}>
                  <FormInput
                    key={inputs[FIELD.rsvtiOperatorPhone].id}
                    {...inputs[FIELD.rsvtiOperatorPhone]}
                    value={values[inputs[FIELD.rsvtiOperatorPhone].name]}
                    onChange={onChange}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.rsvtiOperatorEmail].id}>
                  <FormInput
                    key={inputs[FIELD.rsvtiOperatorEmail].id}
                    {...inputs[FIELD.rsvtiOperatorEmail]}
                    value={values[inputs[FIELD.rsvtiOperatorEmail].name]}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div class="row">
                <div class="column">
                  Adaugă poză/pdf autorizaţie operator RSVTI:{" "}
                  <input type="file" class="fileAP" onChange={(e) => setFileRsvtiPersonA(e.target.files[0])} />
                </div>
                <div class="column">&nbsp;</div>
              </div>

              <div class="row">
                <div class="column">
                  <Checkbox
                    inputId="generateRsvtiDoc"
                    name="generateRsvtiDoc"
                    value="Yes"
                    onChange={onChangeCheckBox}
                    checked={values["generateRsvtiDoc"] === "Yes"}
                  />
                  <label htmlFor="generateRsvtiDoc" className="flex align-items-center">
                    &nbsp;&nbsp;Generează decizie numire operator RSVTI
                  </label>
                </div>
                <div class="column">&nbsp;</div>
              </div>
            </AccordionTab>
            <AccordionTab header="Detalii înregistrare persoană responsabilă semnare acte ISCIR punct de lucru">
              {/* RSVTI Board Main Member Last Name and RSVTI Board Main Member First Name */}
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.companyBranchRSVTIBoardMainPersonLastName].id}>
                  <FormInput
                    key={inputs[FIELD.companyBranchRSVTIBoardMainPersonLastName].id}
                    {...inputs[FIELD.companyBranchRSVTIBoardMainPersonLastName]}
                    value={values[inputs[FIELD.companyBranchRSVTIBoardMainPersonLastName].name]}
                    onChange={onChange}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.companyBranchRSVTIBoardMainPersonFirstName].id}>
                  <FormInput
                    key={inputs[FIELD.companyBranchRSVTIBoardMainPersonFirstName].id}
                    {...inputs[FIELD.companyBranchRSVTIBoardMainPersonFirstName]}
                    value={values[inputs[FIELD.companyBranchRSVTIBoardMainPersonFirstName].name]}
                    onChange={onChange}
                  />
                </div>
              </div>

              {/* RSVTI Board Main Member Role */}
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.companyBranchRSVTIBoardMainPersonRole].id}>
                  <FormInput
                    key={inputs[FIELD.companyBranchRSVTIBoardMainPersonRole].id}
                    {...inputs[FIELD.companyBranchRSVTIBoardMainPersonRole]}
                    value={values[inputs[FIELD.companyBranchRSVTIBoardMainPersonRole].name]}
                    onChange={onChange}
                  />
                </div>
                <div class="column">
                  <p>&nbsp;</p>
                </div>
              </div>

              {/* Company Contact Phone and Company Contact Email */}
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.companyBranchRSVTIBoardMainPersonPhone].id}>
                  <FormInput
                    key={inputs[FIELD.companyBranchRSVTIBoardMainPersonPhone].id}
                    {...inputs[FIELD.companyBranchRSVTIBoardMainPersonPhone]}
                    value={values[inputs[FIELD.companyBranchRSVTIBoardMainPersonPhone].name]}
                    onChange={onChange}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.companyBranchRSVTIBoardMainPersonEmail].id}>
                  <FormInput
                    key={inputs[FIELD.companyBranchRSVTIBoardMainPersonEmail].id}
                    {...inputs[FIELD.companyBranchRSVTIBoardMainPersonEmail]}
                    value={values[inputs[FIELD.companyBranchRSVTIBoardMainPersonEmail].name]}
                    onChange={onChange}
                  />
                </div>
              </div>
            </AccordionTab>
            <AccordionTab header="Detalii comisie tehnică punct de lucru">
              {/* RSVTI Board Second Member Last Name and RSVTI Board Second Member First Name */}
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.companyBranchRSVTIBoardSecondPersonLastName].id}>
                  <FormInput
                    key={inputs[FIELD.companyBranchRSVTIBoardSecondPersonLastName].id}
                    {...inputs[FIELD.companyBranchRSVTIBoardSecondPersonLastName]}
                    value={values[inputs[FIELD.companyBranchRSVTIBoardSecondPersonLastName].name]}
                    onChange={onChange}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.companyBranchRSVTIBoardSecondPersonFirstName].id}>
                  <FormInput
                    key={inputs[FIELD.companyBranchRSVTIBoardSecondPersonFirstName].id}
                    {...inputs[FIELD.companyBranchRSVTIBoardSecondPersonFirstName]}
                    value={values[inputs[FIELD.companyBranchRSVTIBoardSecondPersonFirstName].name]}
                    onChange={onChange}
                  />
                </div>
              </div>

              {/* RSVTI Board Third Member Last Name and RSVTI Board Third Member First Name */}
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.companyBranchRSVTIBoardThirdPersonLastName].id}>
                  <FormInput
                    key={inputs[FIELD.companyBranchRSVTIBoardThirdPersonLastName].id}
                    {...inputs[FIELD.companyBranchRSVTIBoardThirdPersonLastName]}
                    value={values[inputs[FIELD.companyBranchRSVTIBoardThirdPersonLastName].name]}
                    onChange={onChange}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.companyBranchRSVTIBoardThirdPersonFirstName].id}>
                  <FormInput
                    key={inputs[FIELD.companyBranchRSVTIBoardThirdPersonFirstName].id}
                    {...inputs[FIELD.companyBranchRSVTIBoardThirdPersonFirstName]}
                    value={values[inputs[FIELD.companyBranchRSVTIBoardThirdPersonFirstName].name]}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div class="row">
                <div class="column">
                  <Checkbox
                    inputId="generateCommissionDoc"
                    name="generateCommissionDoc"
                    value="Yes"
                    onChange={onChangeCheckBox}
                    checked={values["generateCommissionDoc"] === "Yes"}
                  />
                  <label htmlFor="generateCommissionDoc" className="flex align-items-center">
                    &nbsp;&nbsp;Generează decizie numire comisie ISCIR
                  </label>
                </div>
                <div class="column">&nbsp;</div>
              </div>
            </AccordionTab>
          </Accordion>
        </div>
        {validationErrors.length > 0 ? <ProcessFormErrors validationErrors={validationErrors} /> : null}{" "}
        {processingDialog !== true ? (
          <div class="rowdoublecentered">
            <div class="columnbutton">
              <img
                class="imgtoolbar"
                src={require("../../static/images/buttons/" + ICONS.CompanyBranchSave)}
                alt="Salvează punct de lucru"
                title="Salvează punct de lucru"
                onClick={handleSubmit}
              />
            </div>
          </div>
        ) : null}
      </form>
      <div class="spaceDiv" />
      <Link to={"/companyBranch/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.CompanyBranchList)} alt="Listă puncte de lucru" title="Listă puncte de lucru" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img src={require("../../static/images/buttons/" + ICONS.CompanyBranchAddDisabled)} alt="Adaugă punct de lucru" title="Adaugă punct de lucru" />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/companyBranch/import"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.CompanyBranchImport)}
          alt="Importă firme/puncte de lucru"
          title="Importă firme/puncte de lucru"
        />
      </Link>
      <div class="spaceDiv" />
      <DialogProcessingData isVisible={processingDialog} />
      <DialogDataProcessed isVisible={processedDialog} footer={dialogFooterContent("OK", () => setProcessedDialog(false))} />
      <DialogDataNotProcessed isVisible={notProcessedDialog} footer={dialogFooterContent("OK", () => setNotProcessedDialog(false))} />
      <DialogConfigurationNotProcessed
        isVisible={configNotProcessedDialog}
        footer={dialogFooterContent("OK", () => setConfigNotProcessedDialog(false))}
      />
    </div>
  );
}
