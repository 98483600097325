import "../../static/css/pageLayout.css";

import axios from "axios";
import { useState, useEffect } from "react";

import { BASE_URL } from "../../common/globals";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";
import GenerateButtonOperator from "../../components/GenerateButtonOperator";

export default function OperatorGenerateDocsStatus() {
  const sessionStatusURL = BASE_URL + "operatordocuments/get/status";

  const uuid = sessionStorage.getItem("operatorSessionUuid");

  const { auth } = useAuth();

  const [configs, setConfigs] = useState([]);

  useEffect(() => {
    axios
      .post(
        sessionStatusURL,
        {
          uuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setConfigs(response.data);
      })
      .catch((error) => {
        console.log(error);
        alert("Configuratia autorizarii nu a putut fi citita!");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="clientArea">
      <Sidebar />

      <button class="buttonClassGreen">Status documente deservent</button>
      <div class="spaceDiv" />

      <div class="rowdouble">
        <div class="column">
          <p class="pTitle">Generare documente reautorizare</p>
        </div>
      </div>

      {configs.map((config, index) => (
        <div key={index * 100}>
          <div class="rowdouble">
            <div class="column">
              Punct de lucru {index + 1}: {config.code} - {config.county} - {config.town} - {config.addressDetails1}
            </div>
          </div>
          <div class="rowdouble">
            <div class="column">
              <GenerateButtonOperator status={config.status} sessionUuid={uuid} companyUuid={config.uuid} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
