import "../../static/css/pageLayout.css";

import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { BASE_URL, STRING_CONST, dialogFooterContent, ICONS } from "../../common/globals";
import { isEmptyString } from "../../common/validations";
import DialogConfigurationNotProcessed from "../../components/DialogConfigurationNotProcessed";
import DialogProcessingDocuments from "../../components/DialogProcessingDocuments";
import DialogDataProcessed from "../../components/DialogDataProcessed";
import DialogDataNotProcessed from "../../components/DialogDataNotProcessed";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import ProcessFormErrors from "../../components/ProcessFormErrors";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function WorkerGenerateDocsInstructions() {
  const instructionsConfigURL = BASE_URL + "workerdocuments/get/instructions/configs";
  const instructionsConfigSaveURL = BASE_URL + "workerdocuments/save/instructions/configs";
  const typesURL = BASE_URL + "workerdocuments/get/session/type";

  const uuid = sessionStorage.getItem("workerSessionUuid");

  const navigate = useNavigate();

  const { auth } = useAuth();

  const [configs, setConfigs] = useState([]);

  const [values, setValues] = useState([]);

  const [types, setTypes] = useState([]);

  const [processingDialog, setProcessingDialog] = useState(null);
  const [processedDialog, setProcessedDialog] = useState(null);
  const [notProcessedDialog, setNotProcessedDialog] = useState(null);
  const [configNotProcessedDialog, setConfigNotProcessedDialog] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    axios
      .post(
        instructionsConfigURL,
        {
          uuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        let data = [];
        for (let i = 0; i < response.data.length; i++) {
          data = { ...data, ["person" + i]: "" };
          data = { ...data, ["date" + i]: "" };
        }
        setValues({ ...values, ...data });
        setConfigs(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
    axios
      .post(
        typesURL,
        {
          uuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setTypes(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let data = [];
    for (let i = 0; i < configs.length; i++) {
      if (configs[i].persons.length === 1) {
        data = { ...data, ["person" + i]: configs[i].persons[0].uuid };
      }
    }
    setValues({ ...values, ...data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configs]);

  const handleGenerate = async (e) => {
    e.preventDefault();
    setProcessingDialog(true);
    if (!validateForm()) {
      setProcessingDialog(false);
      return;
    }
    let data = [];
    for (let i = 0; i < configs.length; i++) {
      let newfield = { uuid: configs[i].uuid, iscirOwnerUuid: values["person" + i], instructionsIssuedDate: values["date" + i] };
      data = [...data, newfield];
    }
    axios
      .post(
        instructionsConfigSaveURL,
        {
          uuid: uuid,
          configs: data,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setProcessingDialog(false);
        //setProcessedDialog(true);
        navigate("/worker/generatedocsstatus");
      })
      .catch((error) => {
        setProcessingDialog(false);
        setNotProcessedDialog(true);
      });
  };

  const validateForm = () => {
    let result = true;
    let data = [];
    for (let i = 0; i < configs.length; i++) {
      if (isEmptyString(values["person" + i])) {
        data.push({
          id: i * 2,
          value: "Operator RSVTI (" + configs[i].code + ") " + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_WORKER_DOCUMENTS,
        });
        result = false;
      }
      if (isEmptyString(values["date" + i])) {
        data.push({
          id: i * 2 + 1,
          value: "Data emitere programa analitica instruire (" + configs[i].code + ") " + STRING_CONST.VALIDATION_IS_MANDATORY,
        });
        result = false;
      }
    }
    setValidationErrors(data);
    return result;
  };

  return (
    <div className="clientArea">
      <Sidebar />

      <button class="buttonClassGreen">Generare documente des. auxiliar</button>
      <div class="spaceDiv" />

      <div class="rowdouble">
        <div class="column">
          <p class="pTitle">
            Documentație instruire {types.workerType} - {types.equipmentClassification}
          </p>
        </div>
      </div>

      {configs.map((config, index) => (
        <div key={index * 100}>
          <div class="rowdouble">
            <div class="column">
              <p class="pTitle">
                Punct de lucru {index + 1}: {config.code} - {config.county} - {config.town} - {config.addressDetails1}
              </p>
            </div>
          </div>
          <div class="rowdouble">
            <div class="column" id={"internalLink" + index * 2}>
              <FormSelect
                key={index * 2}
                name={"person" + index}
                label="Operator RSVTI"
                value={values["person" + index]}
                ddOptions={config.persons}
                onChange={onChange}
              />
            </div>
          </div>
          <div class="rowdouble">
            <div class="column" id={"internalLink" + (index * 2 + 1)}>
              <FormInput
                key={index * 2 + 1}
                type="date"
                name={"date" + index}
                label="Alege data emitere programa analitica instruire"
                value={values["date" + index]}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      ))}
      {validationErrors.length > 0 ? <ProcessFormErrors validationErrors={validationErrors} /> : null}
      {processingDialog !== true ? (
        <div class="rowdoublecentered">
          <div class="columnbutton">
            <img
              class="imgtoolbar"
              src={require("../../static/images/buttons/" + ICONS.WorkerGenerateWordDocuments)}
              alt={"Generare documentație instruire " + types.workerType + " - " + types.equipmentClassification}
              title={"Generare documentație instruire " + types.workerType + " - " + types.equipmentClassification}
              onClick={handleGenerate}
            />
          </div>
        </div>
      ) : null}
      <DialogProcessingDocuments isVisible={processingDialog} />
      <DialogDataProcessed isVisible={processedDialog} footer={dialogFooterContent("OK", () => setProcessedDialog(false))} />
      <DialogDataNotProcessed isVisible={notProcessedDialog} footer={dialogFooterContent("OK", () => setNotProcessedDialog(false))} />
      <DialogConfigurationNotProcessed
        isVisible={configNotProcessedDialog}
        footer={dialogFooterContent("OK", () => setConfigNotProcessedDialog(false))}
      />
    </div>
  );
}
