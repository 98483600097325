import React from "react";
import { useNavigate } from "react-router-dom";

import { ICONS } from "../common/globals";

const GenerateButtonWorker = (props) => {
  const { status, sessionUuid, companyUuid } = props;

  const navigate = useNavigate();

  const handleGenerateTests = async (sessionUuid, companyUuid, e) => {
    e.preventDefault();
    sessionStorage.setItem("sessionUuid", sessionUuid);
    sessionStorage.setItem("companyUuid", companyUuid);
    navigate("/worker/generatedocstests");
  };

  const handleGenerateCertificates = async (sessionUuid, companyUuid, e) => {
    e.preventDefault();
    sessionStorage.setItem("sessionUuid", sessionUuid);
    sessionStorage.setItem("companyUuid", companyUuid);
    navigate("/worker/generatedocscertificates");
  };

  const handleViewDocuments = async (sessionUuid, e) => {
    e.preventDefault();
    sessionStorage.setItem("sessionUuid", sessionUuid);
    navigate("/worker/generatedocsview");
  };

  return (
    <>
      {status === "instructions" ? (
        <>
          <div class="rowdoublecentered">
            <div class="columnbutton">
              <img
                class="imgtoolbar"
                src={require("../static/images/buttons/" + ICONS.WorkerGenerateWordDocuments)}
                alt="Nu au fost generate documentele de testare. Continuă generarea documentelor."
                title="Nu au fost generate documentele de testare. Continuă generarea documentelor."
                onClick={(e) => handleGenerateTests(sessionUuid, companyUuid, e)}
              />
            </div>
            <div class="columnbutton">
              <img
                class="imgtoolbar"
                src={require("../static/images/buttons/" + ICONS.WorkerGenerateDocuments)}
                alt="Vezi documentele generate până acum"
                title="Vezi documentele generate până acum"
                onClick={(e) => handleViewDocuments(sessionUuid, e)}
              />
            </div>
          </div>
        </>
      ) : null}
      {status === "tests" ? (
        <>
          <div class="rowdoublecentered">
            <div class="columnbutton">
              <img
                class="imgtoolbar"
                src={require("../static/images/buttons/" + ICONS.WorkerGenerateWordDocuments)}
                alt="Nu au fost generate documentele de certificare. Continuă generarea documentelor."
                title="Nu au fost generate documentele de certificare. Continuă generarea documentelor."
                onClick={(e) => handleGenerateCertificates(sessionUuid, companyUuid, e)}
              />
            </div>
            <div class="columnbutton">
              <img
                class="imgtoolbar"
                src={require("../static/images/buttons/" + ICONS.WorkerGenerateDocuments)}
                alt="Vezi documentele generate până acum"
                title="Vezi documentele generate până acum"
                onClick={(e) => handleViewDocuments(sessionUuid, e)}
              />
            </div>
          </div>
        </>
      ) : null}
      {status === "certificates" ? (
        <div class="rowdoublecentered">
          <div class="columnbutton">
            <img
              class="imgtoolbar"
              src={require("../static/images/buttons/" + ICONS.WorkerGenerateDocuments)}
              alt="Documentele au fost generate - vezi documentele"
              title="Documentele au fost generate - vezi documentele"
              onClick={(e) => handleViewDocuments(sessionUuid, e)}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default GenerateButtonWorker;
